import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import { AxiosError, AxiosResponse } from 'axios';

import { FeaturedList } from 'types/Featured';

import { useAppDispatch } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { AxiosClient, GetAxiosError } from 'utils/axios';

function FeaturedListEdit() {
    const { id } = useParams();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, control, setValue } = useForm();
    
    const [featuredList, setFeaturedList] = useState<FeaturedList|null>(null);
    const [statusText, setStatusText] = useState<any>('');
   
    useEffect(() => { 
        if(!id) { 
            navigate('/layout/featured'); 
            return 
        };

        dispatch(SetLoading(true));
        AxiosClient.get(`/featured/${id}`).then((res: AxiosResponse) => {
            let featuredList: FeaturedList = res.data;
            
            setFeaturedList(featuredList);

            setValue('title', featuredList.title);
            setValue('autoplay', featuredList.autoplay);

            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
            navigate('/layout/featured'); 
        });
    }, [id]);

    if(!id || !featuredList) return null;

    const onSubmit = async (data: any) => {
        dispatch(SetLoading(true));

        setStatusText(`${t('Shared.Text.Updating')}...`);
        try {
            await AxiosClient.patch(`/featured/lists/${featuredList.id}`, {
                title: data.title,
                autoplay: data.autoplay
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        navigate('/layout/featured');
    }

    const onClickDelete = async () => {
        let result = window.confirm(t('Shared.Text.DeleteConfirm') || '');
        if(result !== true) return;

        dispatch(SetLoading(true));
        setStatusText(`${t('Shared.Text.Deleting')}...`);

        try {
            await AxiosClient.delete(`/featured/lists/${featuredList.id}`);
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        navigate('/layout/featured');
    }

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[720px]">
                    <div className="p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <h5 className='mb-5'>{ t('Shared.Fields.FeaturedListInformation') }</h5>
                        <form role="form" onSubmit={handleSubmit(onSubmit)}>
                            <div className='grid grid-cols-1 gap-3 mb-3'>
                                <div className='col-span-1'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Title') } <span className='text-red-600'>*</span></label>
                                    <div>
                                        <input type="text" className="input input-textbox" placeholder="Title" { ...register("title", { required: true, minLength: 1, maxLength: 128 }) }/>
                                    </div>
                                </div>
                                <div className='col-span-1'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Autoplay') }</label>
                                    <div className='relative flex items-center cursor-pointer'>
                                        <Controller
                                            name="autoplay"
                                            control={control}
                                            defaultValue={true}
                                            render={({ field }) => (
                                                <>
                                                    <input type="checkbox" className="input input-checkbox sr-only peer" onChange={(value) => field.onChange(value)} checked={field.value}/>
                                                    <div onClick={() => { field.onChange(!field.value) }} className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row justify-between items-center'>
                                <p className='mb-0'>{ statusText }</p>
                                <div>
                                    <button type="button" onClick={onClickDelete} className="btn btn-red btn-keep-size block mr-2"><i className="fa-solid fa-trash mr-2"></i>{ t('Shared.Actions.Delete') }</button>
                                    <button type="submit" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-pen-nib mr-2"></i>{ t('Shared.Actions.Update') }</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(FeaturedListEdit);
