import { AppState } from 'store';
import React from 'react';
import { useAppSelector } from 'store/hooks';

function LoadingOverlay() {
    const loading = useAppSelector((state: AppState) => state.layout.loading);
    if(!loading) return null;

    return (
        <div className="loading-overlay text-white">
            <div className='h-full relative'>
                <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(LoadingOverlay);