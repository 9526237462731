import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export function usePagination(stateOnly: boolean = false, defaultPageSize: number = 10): [number, number, number, any, any] {
    const [searchParams, setSearchParams] = useSearchParams();

    const [statePage, setStatePage] = useState<number>(1);
    const [statePageSize, setStatePageSize] = useState<number>(10);

    let page: number = (stateOnly ? statePage : Number(searchParams.get('page'))) || 1;
    let pageSize: number = (stateOnly ? statePageSize : Number(searchParams.get('pageSize'))) || 10;

    if(page < 1) page = 1;
    if(pageSize < 0) pageSize = 0;

    let start = (page - 1) * pageSize;
    let limit = pageSize;

    const GoToPage = (page: number) => {
        if(stateOnly) {
            setStatePage(page);
        } else {
            searchParams.set('page', page.toString());
            setSearchParams(searchParams.toString());
        }
    }

    const ChangePageSize = (size: number) => {
        // State page size
        if(stateOnly) {
            setStatePageSize(size);
            return;
        }

        // Url page size
        if(size == defaultPageSize) { searchParams.delete('pageSize') }
        else { searchParams.set('pageSize', size.toString()) }

        setSearchParams(searchParams);
    }

    return [start, limit, page, GoToPage, ChangePageSize];
}