
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "types/User";

export interface UserState {
  loggedIn: boolean;
  user: User | null;
}

const initialState: UserState = {
  loggedIn: false,
  user: null
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SetLogin(state, action: PayloadAction<User>) {
      state.loggedIn = true;
      state.user = action.payload;
    },
    SetLogout(state, action: PayloadAction<void>) {
      if(!state.loggedIn) return;

      state.loggedIn = false;
      state.user = initialState.user;
    }
  }
});

export const { 
  SetLogin, SetLogout
} = UserSlice.actions;

export default UserSlice.reducer;