import React from 'react';
import { useTranslation } from 'react-i18next';
import { Category } from 'types/Category';

import { EPageSectionTargetType, PageSection } from 'types/Page';

interface Props {
    key: number;
    data: PageSection;
    onClickEditSection: any;
}

function Section(props: Props) {
    const { t } = useTranslation();
    const { data: section } = props;

    var renderTargetID = null;

    switch(section.targetType) {
        case EPageSectionTargetType.FixedShowItemList: renderTargetID = <>{ section.targetEntities?.map((e) => `(#${e.id}) ${e.title || e.originalTitle || ''}`).join(', ') }</>; break;
        case EPageSectionTargetType.CustomMultiCatList: 
            renderTargetID = <>(#{ section.targetIds.join(', ') }) { section.targetEntities && (section.targetEntities as Category[]).map((c: Category) => `${c.title} (${c.id})`).join(', ') }</>; break;
            break;
        // Default
        default: renderTargetID = <>(#{ section.targetId }) { section.targetEntity?.title }</>; break;
    }

    return (
        <div className="mb-5 p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className='mb-5 flex flex-row justify-between items-center'>
                <h5 className='mb-0'>{ !section.hidden && <span className='badge badge-green'>{ t('Shared.Fields.Public') }</span> }{ t('Shared.Fields.Section') }: { section.title } <small className='text-sm'>({ t('Shared.Fields.Order') }: { section.sortOrder })</small></h5>
                <button onClick={props.onClickEditSection.bind(null, section)} className='btn btn-aurora btn-sm'><i className="fa-solid fa-pen-to-square mr-2"></i>{ t('Shared.Actions.Edit') }</button>
            </div>
            <h6>{ t('Shared.Fields.AssignmentConfig') }</h6>
            <div className='grid grid-cols-2 gap-3'>
                <div className="py-3 md:px-6 px-3 bg-white border-0 border-transparent border-solid ring-2 ring-gray-100 shadow-soft-xl rounded-sm bg-clip-border leading-normal">
                    <span className='text-sm uppercase tracking-widest'>{ t('Shared.Fields.TargetType') }</span>
                    <p className='text-slate-600 font-bold mb-2 leading-none'>{ t(`Shared.PageSectionTypes.${section.targetType}`) }</p>
                </div>
                <div className="py-3 md:px-6 px-3 bg-white border-0 border-transparent border-solid ring-2 ring-gray-100 shadow-soft-xl rounded-sm bg-clip-border leading-normal">
                    <span className='text-sm uppercase tracking-widest'>{ t('Shared.Fields.TargetID') }</span>
                    <p className='text-slate-600 font-bold mb-2 leading-none'>{ renderTargetID }</p>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Section);