
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IModalLogDetailPayload {
  show: boolean;
  content: any;
}

export interface LogPageState {
  modal: {
    logDetail: {
      show: boolean;
      content: any;
    }
  }
}

const initialState: LogPageState = {
  modal: {
    logDetail: {
      show: false,
      content: null
    }
  }
};

export const LogPageSlice = createSlice({
  name: "pageLog",
  initialState,
  reducers: {
    ShowModalLogDetail(state, action: PayloadAction<IModalLogDetailPayload>) {
      state.modal.logDetail.show = action.payload.show;
      state.modal.logDetail.content = action.payload.content;
    },
  }
});

export const { 
  ShowModalLogDetail
} = LogPageSlice.actions;

export default LogPageSlice.reducer;