import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { AxiosError, AxiosResponse } from 'axios';

import { EFeaturedItemCoverType, EFeaturedItemTargetType } from 'types/Featured';
import { ShowItem } from 'types/ShowItem';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';
import { ListingForceReload, ShowModalEditItem } from 'store/featured.page.slice';

import { StyleCompact } from 'utils/modal';
import { AxiosClient, GetAxiosError } from 'utils/axios';
import { OptimizePhotoUrl } from 'utils/file';
import { EPhotoVariants } from 'types/File';
import { NormalizeYoutubeUrl } from 'utils/regex';
import ContentLanguageSelector from 'components/ContentLanguageSelector';
import { LanguageMeta } from 'types/Shared';

function EditSectionModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { register, handleSubmit, control, watch, setValue, reset } = useForm();

    const show = useAppSelector((state) => state.featuredPage.modal.editItem.show)
    const item = useAppSelector((state) => state.featuredPage.modal.editItem.item);

    const targetTypeOptions: any[] = []; for(let item in EFeaturedItemTargetType) targetTypeOptions.push({ value: item, label: t(`Shared.FeaturedItemTargetTypes.${item}`) });
    const coverTypeOptions: any[] = []; for(let item in EFeaturedItemCoverType) coverTypeOptions.push({ value: item, label: t(`Shared.FeaturedItemCoverTypes.${item}`) });

    const targetType = watch('targetType', item?.targetType || targetTypeOptions[0].value);
    const coverType = watch('coverType', item?.coverType || coverTypeOptions[0].value);

    const refShowItem = useRef<any>(null);
    const refFileCoverImage = useRef<HTMLInputElement>(null);
    const [coverImage, setCoverImage] = useState<string|ArrayBuffer|null>(null);
    const [uploadedCoverImageUID, setUploadedCoverImageUID] = useState<string|null>(null);
    
    const [statusText, setStatusText] = useState<any>('');
    const [selectedShowItem, setSelectedShowItem] = useState<any>([]);

    const onLoadShowItem = debounce((inputValue: string, callback: (options: any[]) => void) => {
        AxiosClient.get('/show-items/series-movie', { params: inputValue.length > 0 ? { q: inputValue, sort: 'sortOrder:asc,id:asc', paginationLimit: 5 } : { sort: 'sortOrder:asc,id:asc', paginationLimit: 5 } }).then((res: AxiosResponse) => {
            let options = res.data.data?.map((r: ShowItem) => ({ label: r.originalTitle, value: r.id }));
            
            if(item && targetType == EFeaturedItemTargetType.ShowItem) {
                let findShowItem = options.find((c: any) => c.value == item?.targetId);
                if(findShowItem) {
                    callback(options);
                    setSelectedShowItem(findShowItem);
                } else {
                    var option = null;
                    if(item.targetEntity) {
                        option = { label: item.targetEntity.originalTitle, value: item.targetEntity.id };
                        options.unshift(option);
                    }
                    
                    callback(options);
                    if(option) setSelectedShowItem(option);
                }
            } else {
                callback(options);
            }
        }).catch((err: AxiosError) => {
            setStatusText(<span className='text-red-600'>{GetAxiosError(err)}</span>);
            callback([]);
        });
    }, 500);
    
    const onChangeShowItem = async (options: any) => { setSelectedShowItem(options); }

    useEffect(() => {
        if(!item) return;

        setStatusText('');
        setCoverImage(null);
        setUploadedCoverImageUID(null);
      
        setValue('targetType', item.targetType);
        setValue('coverType', item.coverType);
        setValue('sortOrder', item.sortOrder);

        switch(item.coverType) {
            case EFeaturedItemCoverType.StaticImage: 

                if(item.coverData.staticImage) {
                    // @ts-ignore
                    setUploadedCoverImageUID(item.coverData.staticImage.uid);
                    setCoverImage(OptimizePhotoUrl(item.coverData.staticImage, EPhotoVariants.Optimized));
                }
                break;
            case EFeaturedItemCoverType.YoutubeVideo:
                setValue('youtubeUrl', item.coverData?.url); 
                break;
            default: break;
        }
    }, [item]);

    const onClickSelectCoverImage = (e: any) => { refFileCoverImage.current?.click(); }
    const onFileCoverImageChange = (e: any) => {
        let reader = new FileReader();

        reader.onload = function() { setCoverImage(reader.result); };
        reader.readAsDataURL(e.target.files[0]);
    }

    const onCloseModal = () => {
        dispatch(ShowModalEditItem({ show: false }));
        reset();
    }

    const onClickClose = (e: any) => {
        e.preventDefault();
        onCloseModal();
    }

    const onSubmit = async (data: any) => {
        if(!show || !item) return;

        var targetId = null;
        switch(targetType) {
            case EFeaturedItemTargetType.ShowItem: {
                targetId = typeof selectedShowItem == 'object' ? selectedShowItem.value : null;
                break;
            }
            default: return;
        }

        if(!targetId) return;

        dispatch(SetLoading(true));

        var coverData = {};
        switch(coverType) {
            case EFeaturedItemCoverType.StaticImage: 
                var coverImageUId = uploadedCoverImageUID;
                if(!coverImageUId) {
                    let formData = new FormData();

                    // @ts-ignore
                    formData.append('file', refFileCoverImage.current?.files[0]);
        
                    try {
                        setStatusText(`${t('Shared.Text.Uploading')}...`);
                        let uploadedImage = await AxiosClient.post('/files/private-upload', formData, {
                            headers: { 'Content-Type': 'multipart/form-data' },
                            params: { type: 'Image' }
                        });
        
                        setUploadedCoverImageUID(uploadedImage.data.uid);
                        coverImageUId = uploadedImage.data.uid;
                    } catch(e) {
                        setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
                        dispatch(SetLoading(false));
                        return;
                    }
                }
    
                coverData = { uid: coverImageUId }; 
                break;
            case EFeaturedItemCoverType.YoutubeVideo: 
                let youtubeUrl = NormalizeYoutubeUrl(data.youtubeUrl || '');
                if(!youtubeUrl) {
                    setStatusText(<span className='text-red-600'>{ t('Shared.Errors.InvalidYoutubeUrl') }</span>);
                    dispatch(SetLoading(false));
                    return;   
                }

                coverData = { url: youtubeUrl }
                break;
            // EFeaturedItemCoverType.Default
            default: break;
        }

        dispatch(SetLoading(true));
        setStatusText('');

        try {
            await AxiosClient.patch(`/featured/items/${item.id}`, {
                targetType: data.targetType,
                targetId: targetId,
                coverType,
                coverData,
                sortOrder: data.sortOrder
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReload());
        
        onCloseModal();
    }
     
    if(!item) return null;

    const onClickDelete = async () => {
        let result = window.confirm(t('Shared.Text.DeleteConfirm') || '');
        if(result !== true) return;

        dispatch(SetLoading(true));
        setStatusText(`${t('Shared.Text.Deleting')}...`);

        try {
            await AxiosClient.delete(`/featured/items/${item.id}`);
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReload());

        onCloseModal();
    }
    
    return (
        <Modal isOpen={show} onRequestClose={onCloseModal} style={StyleCompact} contentLabel="Modal: EditFeaturedItem">
            <div className="px-6 py-3">
                <div className='flex justify-between items-center mb-5'>
                    <h5 className='mb-0'>{ t('Pages.Layout.Featured.FeaturedListEditItems.Modals.EditFeaturedItem.Title') }</h5>
                    <span onClick={onClickClose} className='text-2xl hover:cursor-pointer'><h3><i className="far fa-times-circle"></i></h3></span>
                </div>
                <form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-2 gap-3 mb-3'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.TargetType') } <span className='text-red-600'>*</span></label>
                            <div>
                                <Controller
                                    name="targetType"
                                    defaultValue={targetTypeOptions[0].value}
                                    control={control}
                                    render={({ field }) => (
                                        <Select isClearable={false} className='input input-select2' options={targetTypeOptions} value={targetTypeOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                    )}
                                />
                            </div>
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.TargetID') } </label>
                            <div>
                                { targetType == EFeaturedItemTargetType.ShowItem && 
                                    <AsyncSelect ref={refShowItem} className='input input-select2' cacheOptions loadOptions={onLoadShowItem} defaultOptions value={selectedShowItem} onChange={onChangeShowItem}/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mb-3'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.CoverType') } <span className='text-red-600'>*</span></label>
                            <div>
                            <Controller
                                name="coverType"
                                defaultValue={coverTypeOptions[0].value}
                                control={control}
                                render={({ field }) => (
                                    <Select isClearable={false} styles={{ menu: (base) => ({ ...base, top: -50 }) }} className='input input-select2' options={coverTypeOptions} value={coverTypeOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                )}
                            />
                            </div>
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.SortOrder') } </label>
                            <div>
                                <input type="number" className="input input-textbox" placeholder="Sort Order" { ...register("sortOrder", { min: 0 }) } defaultValue={0}/>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mb-10'>
                        { coverType == EFeaturedItemCoverType.YoutubeVideo && <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.YoutubeUrl') }</label>
                            <div>
                                <input type="text" className="input input-textbox" placeholder="Youtube url" { ...register("youtubeUrl", { required: true, minLength: 1, maxLength: 250 }) }/>
                            </div>
                        </div> }
                        { coverType == EFeaturedItemCoverType.StaticImage && <div className='col-span-2 md:col-span-1'>
                            <div onClick={onClickSelectCoverImage} className={`image-select image-select-landscape ${coverImage ? '' : 'image-select-none'} shadow-lg hover:cursor-pointer`}>
                                { !coverImage && <div className='text-center'>
                                    <p className='text-5xl mb-2'><i className="fa-solid fa-image-landscape"></i></p>
                                    <p>{ t('Shared.Fields.Photo')} <span className='text-red-600'>*</span></p>
                                </div> }
                                { coverImage && <img src={coverImage.toString()}/> }
                            </div>
                            <input ref={refFileCoverImage} onChange={onFileCoverImageChange} type="file" accept='image/png, image/jpeg' className="hidden"/>
                        </div> }
                    </div>
                    <div className='flex flex-col md:flex-row justify-between items-center mb-3'>
                        <p className='mb-0'>{ statusText }</p>
                        <div>
                            <button type="button" onClick={onClickDelete} className="btn btn-red btn-keep-size block mr-2"><i className="fa-solid fa-trash mr-2"></i>{ t('Shared.Actions.Delete') }</button>
                            <button type="submit" id="btn-update" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-pen-nib mr-2"></i>{ t('Shared.Actions.Update') }</button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default React.memo(EditSectionModal);