import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { LayoutSlice } from './layout.slice';
import { UserSlice } from './user.slice';

import { PageAndSectionPageSlice } from './page-and-section.page.slice';
import { FeaturedPageSlice } from './featured.page.slice';

import { ShowPageSlice } from './show.page.slice';
import { LogPageSlice } from './log.page.slice';
import { RecycleBinPageSlice } from './recycleBin.page.slice';

import { CommentPageSlice } from './comment.page.slice';

export const store = configureStore({
  reducer: {
    [LayoutSlice.name]: LayoutSlice.reducer,
    [UserSlice.name]: UserSlice.reducer,

    [PageAndSectionPageSlice.name]: PageAndSectionPageSlice.reducer,
    [FeaturedPageSlice.name]: FeaturedPageSlice.reducer,

    [ShowPageSlice.name]: ShowPageSlice.reducer,
    [LogPageSlice.name]: LogPageSlice.reducer,
    [RecycleBinPageSlice.name]: RecycleBinPageSlice.reducer,

    [CommentPageSlice.name]: CommentPageSlice.reducer
  },
  devTools: true
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
