import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';
import { ListingForceReload, ShowModalCommentDetail } from 'store/comment.page.slice';

import { StyleCompact } from 'utils/modal';
import { AxiosClient, GetAxiosError } from 'utils/axios';

import { ECommentStatus } from 'types/Comment';

function CreateEpisodeModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const show = useAppSelector((state) => state.pageComment.modal.commentDetail.show)
    const comment = useAppSelector((state) => state.pageComment.modal.commentDetail.comment);

    const { register, handleSubmit, control, watch, setValue } = useForm();

    const statusOptions: any[] = []; for(let item in ECommentStatus) {
        if(!isNaN(Number(item))) statusOptions.push({ value: item, label: t(`Shared.CommentStatus.${item}`) });
    }
    const status = watch('status', statusOptions[0].value);
    const [statusText, setStatusText] = useState<any>('');

    useEffect(() => {
        if(!comment) return;

        setValue('status', comment.status);
    }, [comment]);

    const onCloseModal = () => {
        dispatch(ShowModalCommentDetail({ show: false }));
    }

    const onClickClose = (e: any) => {
        e.preventDefault();
        onCloseModal();
    }

    const onSubmit = async (data: any) => {
        if(!show) return;

        dispatch(SetLoading(true));
        setStatusText('');

        try {
            await AxiosClient.patch(`/comments/${comment?.id}/update-status`, { status });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReload());
        
        onCloseModal();
    }
     
    if(!comment) return null;
    
    return (
        <Modal isOpen={show} onRequestClose={onCloseModal} style={StyleCompact} contentLabel="Modal: CommentDetail">
            <div className="px-6 py-3">
                <div className='flex justify-between items-center mb-5'>
                    <h5 className='mb-0'>{ t('Pages.Community.Comment.Listing.Modals.CommentDetail.Title') }</h5>
                    <span onClick={onClickClose} className='text-2xl hover:cursor-pointer'><h3><i className="far fa-times-circle"></i></h3></span>
                </div>
                <form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-3'>
                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Content') }</label>
                        <div>
                            <textarea rows={5} className="input input-textbox" defaultValue={comment.content || ''} disabled={true}/>
                        </div>
                    </div>
                    <div className='mb-10'>
                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.UpdateStatus') }</label>
                        <div className='grid grid-cols-2 gap-3'>
                            <div className='col-span-1'>
                                <Controller
                                    name="status"
                                    defaultValue={statusOptions[0].value}
                                    control={control}
                                    render={({ field }) => (
                                        <Select isClearable={false} styles={{ menu: (base) => ({ ...base, top: -50 }) }} className='input input-select2' options={statusOptions} value={statusOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                    )}
                                />
                            </div>
                            <div className='col-span-1'>
                                <button type="submit" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-pen-nib mr-2"></i>{ t('Shared.Actions.Update') }</button>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between items-center mb-3'>
                        <p className='mb-0'>{ statusText }</p>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default React.memo(CreateEpisodeModal);