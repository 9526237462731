import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";

import { useAppDispatch } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { AxiosClient, GetAxiosError } from 'utils/axios';
import ContentLanguageSelector from 'components/ContentLanguageSelector';
import { LanguageMeta } from 'types/Shared';

function CategoryAdd() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, setValue, getValues } = useForm();
    const [statusText, setStatusText] = useState<any>('')
    const [langMeta, setLangMeta] = useState<LanguageMeta>({ editing: [], reset: 0 });

    const onSubmit = async (data: any) => {
        if(langMeta.editing.length > 0) {
            setLangMeta({ ...langMeta, reset: langMeta.reset + 1 });
            setTimeout(() => { (document.querySelector('#btn-create') as HTMLButtonElement).click(); }, 1);
            return;
        }

        dispatch(SetLoading(true));
        setStatusText(`${t('Shared.Text.Creating')}...`);
        try {
            await AxiosClient.post('/categories', {
                title: data.title,
                sortOrder: data.sortOrder,
                langMeta
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        navigate('/content/categories');
    }

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[720px]">
                    <div className="p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <h5 className='mb-5'>{ t('Shared.Fields.CategoryInformation') }</h5>
                        <form role="form" onSubmit={handleSubmit(onSubmit)}>
                            <div className='grid grid-cols-2 gap-3 mb-5'>
                                <div className='col-span-2 md:col-span-1'>
                                    <div className='mb-2 flex justify-content-start items-center'>
                                        <label className="ml-1 font-bold text-xs text-slate-700 mr-3">{ t('Shared.Fields.Title') } <span className='text-red-600'>*</span></label>
                                        <ContentLanguageSelector defaultLang={'eng'} field='title' langMeta={langMeta} setLangMeta={setLangMeta} setValue={(value: string) => { setValue('title', value) }} getValue={getValues}/>
                                    </div>
                                    <div className="mb-0 md:mb-4">
                                        <input type="text" className="input input-textbox" placeholder="Title" { ...register("title", { required: true, minLength: 1, maxLength: 128 }) }/>
                                    </div>
                                </div>
                                <div className='col-span-2 md:col-span-1'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.SortOrder') }</label>
                                    <div className="mb-0 :mb-4">
                                        <input type="number" className="input input-textbox" placeholder="Sort Order" { ...register("sortOrder", { min: 0 }) } defaultValue={0}/>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row justify-between items-center'>
                                <p className='mb-0'>{ statusText }</p>
                                <button type="submit" id="btn-create" className="btn btn-aurora block"><i className="fa-solid fa-circle-plus mr-2"></i>{ t('Shared.Actions.Create') }</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(CategoryAdd);
