
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Comment } from "types/Comment";

export interface IShowModalCommentDetailPayload {
  show: boolean;
  comment?: Comment;
}

export interface CommentPageState {
  listing: {
    forceReload: number;
  },
  modal: {
    commentDetail: {
      show: boolean;
      comment: Comment | null;
    }
  }
}

const initialState: CommentPageState = {
  listing: {
    forceReload: 0
  },
  modal: {
    commentDetail: {
      show: false,
      comment: null
    }
  }
};

export const CommentPageSlice = createSlice({
  name: "pageComment",
  initialState,
  reducers: {
    ListingForceReload(state) {
      state.listing.forceReload++;
    },
    ShowModalCommentDetail(state, action: PayloadAction<IShowModalCommentDetailPayload>) {
      state.modal.commentDetail.show = action.payload.show;
      state.modal.commentDetail.comment = action.payload.comment || null;
    }
  }
});

export const { 
  ListingForceReload, ShowModalCommentDetail
} = CommentPageSlice.actions;

export default CommentPageSlice.reducer;