export const Regex = {
    Email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    Youtube: /^((?:https?:)?\/\/)?((?:www|m)\.)?(youtu.*be.*)\/(watch\?v=|embed\/|shorts|)(.*?((?=[&#?])|$))/
}

const regexEmail = new RegExp(Regex.Email, "gi");
const regexYoutube = new RegExp(Regex.Youtube, "gi");

export function IsEmail(text: string): boolean {
    if(typeof text !== 'string') return false;
    
    regexEmail.lastIndex = 0;
    return regexEmail.test(text);
}

export function NormalizeYoutubeUrl(text: string): string | null {
    regexYoutube.lastIndex = 0; // Reset the regex before continuing
 
    let regexResult = regexYoutube.exec(text);
    if (!regexResult || regexResult.length != 7)
        return null;
 
    return `https://www.youtube.com/watch?v=${regexResult[5]}`;
}