import { FilePhoto } from "./File";
import { LanguageMeta } from "./Shared";
import { ShowItem } from "./ShowItem";

export enum EFeaturedItemTargetType {
    ShowItem = 'ShowItem'
}

export enum EFeaturedItemCoverType {
    Default = 'Default',
    StaticImage = 'StaticImage',
    YoutubeVideo = 'YoutubeVideo'
}

export interface IFeaturedItemCoverData {
    url?: string;
    uid?: string;
    staticImage?: FilePhoto;
}

export interface FeaturedList {
    id: number;
    title: string;
    autoplay: boolean;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    itemCount?: number;
}

export interface FeaturedItem {
    id: number;
    targetType: EFeaturedItemTargetType;
    targetId: number;
    title: string;
    description: string | null;
    coverType: EFeaturedItemCoverType;
    coverData: IFeaturedItemCoverData;
    sortOrder: number;
    createdAt: Date;
    updatedAt: Date;
    targetEntity?: ShowItem;
    langMeta: LanguageMeta;
}