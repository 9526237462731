
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Log } from "types/Log";

interface IModalItemDetailPayload {
  show: boolean;
  content: any;
  log: Log | null;
}

export interface RecycleBinPageState {
  listing: {
    forceReload: number;
  },
  modal: {
    itemDetail: {
      show: boolean;
      content: any;
      log: Log | null;
    }
  }
}

const initialState: RecycleBinPageState = {
  listing: {
    forceReload: 0
  },
  modal: {
    itemDetail: {
      show: false,
      content: null,
      log: null
    }
  }
};

export const RecycleBinPageSlice = createSlice({
  name: "pageRecycleBin",
  initialState,
  reducers: {
    ShowModalItemDetail(state, action: PayloadAction<IModalItemDetailPayload>) {
      state.modal.itemDetail.show = action.payload.show;
      state.modal.itemDetail.content = action.payload.content;
      state.modal.itemDetail.log = action.payload.log;
    },
    ListingForceReload(state) {
      state.listing.forceReload++;
    }
  }
});

export const { 
  ListingForceReload,
  ShowModalItemDetail
} = RecycleBinPageSlice.actions;

export default RecycleBinPageSlice.reducer;