import React, { useEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { AppState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetInitState } from 'store/layout.slice';
import { SetLogin } from 'store/user.slice';

import Container from 'pages/Container';
import Initializing from 'pages/Initializing';

import LoadingOverlay from 'components/LoadingOverlay'
import { AxiosClient } from 'utils/axios';
import { User, UserRole } from 'types/User';

function App() {
  const initState = useAppSelector((state: AppState) => state.layout.initState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(initState !== 0) return;

    AxiosClient.get('/users/me').then((res: AxiosResponse) => {
      let user: User = res.data;
      if(user.roles.map((r: UserRole) => r.roleCode).indexOf('Administrator') !== -1) {
        dispatch(SetLogin(res.data));
        dispatch(SetInitState(1));
      } else {
        AxiosClient.post('/auth/logout').then(() => {}).catch(() => { });
        dispatch(SetInitState(-1));
      }
    }).catch((err: AxiosError) => {
      dispatch(SetInitState(-1));
    })
  }, [initState]);

  if(initState < 1) return <Initializing initState={initState}/>

  return (
    <>
      <Container/>
      <LoadingOverlay/>
    </>
  );
}

export default React.memo(App);
