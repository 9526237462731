import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { AxiosError, AxiosResponse } from 'axios';
import ReactDatePicker from 'react-datepicker';

import { ECustomShowItemListSortBy, EPageSectionTargetType, IPageSectionTargetConfig, PageSection } from 'types/Page';
import { Category } from 'types/Category';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';
import { ListingForceReload, ShowModalEditSection } from 'store/page-and-section.page.slice';

import { StyleCompact } from 'utils/modal';
import { AxiosClient, GetAxiosError } from 'utils/axios';
import { FeaturedList } from 'types/Featured';
import { ShowItem } from 'types/ShowItem';
import ContentLanguageSelector from 'components/ContentLanguageSelector';
import { LanguageMeta } from 'types/Shared';
import { supportedLangs } from 'utils/validator';

function EditSectionModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { register, handleSubmit, control, watch, setValue, getValues, reset } = useForm();
    const hidden = watch('hidden', false);

    const refCategory = useRef<any>(null);
    const refFeaturedList = useRef<any>(null);
    const refShowItem = useRef<any>(null);
    const refAudioLangs = useRef<any>(null);
    const refSubtitleLangs = useRef<any>(null);
    const refAcceptTypes = useRef<any>(null);

    const show = useAppSelector((state) => state.pageAndSectionPage.modal.editSection.show)
    const section: PageSection | null = useAppSelector((state) => state.pageAndSectionPage.modal.editSection.section);

    const targetTypeOptions: any[] = []; for(let item in EPageSectionTargetType) targetTypeOptions.push({ value: item, label: t(`Shared.PageSectionTypes.${item}`) });
    const targetType = watch('targetType', section ? section.targetType : targetTypeOptions[1].value);

    // @ts-ignore
    const sortByOptions: any[] = []; for(let item in ECustomShowItemListSortBy) sortByOptions.push({ value: ECustomShowItemListSortBy[item], label: t(`Pages.Layout.PageAndSection.Shared.CustomShowItemListSortByTypes.${item}`) });
    const sortBy = watch('sortBy', sortByOptions[0].value);
    
    const [statusText, setStatusText] = useState<any>('');
    const [selectedCategory, setSelectedCategory] = useState<any>([]);
    const [selectedFeaturedList, setSelectedFeaturedList] = useState<any>([]);
    const [selectedShowItems, setSelectedShowItems] = useState<any>([]);
    const [selectedAudioLangs, setSelectedAudioLangs] = useState<any>([]);
    const [selectedSubtitleLangs, setSelectedSubtitleLangs] = useState<any>([]);
    const [showItemFirstLoad, setShowItemFirstLoad] = useState<number>(0);
    const [selectedAcceptTypes, setSelectedAcceptTypes] = useState<any>([]);
    const [categoryFirstLoad, setCategoryFirstLoad] = useState<number>(0);
    const [langMeta, setLangMeta] = useState<LanguageMeta>({ editing: [], reset: 0 });

    const onLoadCategories = debounce((inputValue: string, callback: (options: any[]) => void) => {
        AxiosClient.get('/categories', { params: inputValue.length > 0 ? { q: inputValue, sort: 'sortOrder:asc,id:asc', paginationLimit: 3 } : { sort: 'sortOrder:asc,id:asc', paginationLimit: 3 } }).then((res: AxiosResponse) => {
            let options = res.data.data?.map((r: Category) => ({ label: `(#${r.id}) ${r.title}`, value: r.id }));

            if(section && [/*EPageSectionTargetType.CategoryByUpdate, */EPageSectionTargetType.CustomShowItemList, EPageSectionTargetType.CustomMultiCatList].indexOf(targetType) !== -1 ) {
                if(targetType == EPageSectionTargetType.CustomShowItemList) {
                    let findCat = options.find((c: any) => c.value == section?.targetId);
                    if(findCat) {
                        callback(options);
                        setSelectedCategory(findCat);
                    } else {
                        AxiosClient.get(`/categories/${section.targetId}`).then((res: AxiosResponse) => {
                            options.unshift({ label: `(#${res.data.id}) ${res.data.title}`, value: res.data.id });
                            callback(options);
                            setSelectedCategory(options.find((o: any) => o.value == section.targetId));
                        }).catch((err: AxiosError) => {
                            callback(options);
                        });
                    }
                } else if(targetType == EPageSectionTargetType.CustomMultiCatList) {
                    section.targetEntities?.forEach((c: Category) => {
                        if(!options.find((o: any) => o.value == c.id))
                            options.push({ label: `(#${c.id}) ${c.title}`, value: c.id })
                    })

                    callback(options);
                    
                    // @ts-ignore
                    if(categoryFirstLoad != section?.id && section.targetEntities?.length > 0) {
                        let selected = (section.targetEntities as Category[])?.map((c: Category) => ({ label: `(#${c.id}) ${c.title}`, value: c.id })) || [];
                        setSelectedCategory(selected);
                        setCategoryFirstLoad(section.id);
                    }
                }
            } else {
                callback(options);
            }
        }).catch((err: AxiosError) => {
            setStatusText(<span className='text-red-600'>{GetAxiosError(err)}</span>);
            callback([]);
        });
    }, 500);

    const onLoadFeaturedLists = debounce((inputValue: string, callback: (options: any[]) => void) => {
        AxiosClient.get('/featured', { params: inputValue.length > 0 ? { q: inputValue, sort: 'id:desc', paginationLimit: 3 } : { sort: 'id:desc', paginationLimit: 3 } }).then((res: AxiosResponse) => {
            let options = res.data.data?.map((r: FeaturedList) => ({ label: `(#${r.id}) ${r.title}`, value: r.id }));

            if(section && targetType == EPageSectionTargetType.FeaturedList) {
                let findFeaturedList = options.find((c: any) => c.value == section?.targetId);
                if(findFeaturedList) {
                    callback(options);
                    setSelectedFeaturedList(findFeaturedList);
                } else {
                    AxiosClient.get(`/featured/${section.targetId}`).then((res: AxiosResponse) => {
                        options.unshift({ label: `(#${res.data.id}) ${res.data.title}`, value: res.data.id });
                        callback(options);
                        setSelectedFeaturedList(options.find((o: any) => o.value == section.targetId));
                    }).catch((err: AxiosError) => {
                        callback(options);
                    });
                }
            } else {
                callback(options);
            }
        }).catch((err: AxiosError) => {
            setStatusText(<span className='text-red-600'>{GetAxiosError(err)}</span>);
            callback([]);
        });
    }, 500);

    const onLoadShowItems = debounce((inputValue: string, callback: (options: any[]) => void) => {
        AxiosClient.get('/show-items/series-movie', { params: inputValue.length > 0 ? { q: inputValue, sort: 'id:desc', paginationLimit: 3 } : { sort: 'id:desc', paginationLimit: 3 } }).then((res: AxiosResponse) => {
            let options = res.data.data?.map((i: ShowItem) => ({ label: `(#${i.id}) ${i.originalTitle}`, value: i.id }));
            
            if(section && targetType == EPageSectionTargetType.FixedShowItemList) {
                section.targetEntities?.forEach((s: ShowItem) => {
                    if(!options.find((o: any) => o.value == s.id))
                        options.push({ label: `(#${s.id}) ${s.originalTitle}`, value: s.id })
                })
        
                callback(options);
                
                // @ts-ignore
                if(showItemFirstLoad != section?.id && section.targetEntities?.length > 0) {
                    let selectedShowItems = (section.targetEntities as ShowItem[])?.map((s: ShowItem) => ({ label: `(#${s.id}) ${s.originalTitle}`, value: s.id })) || [];
                    setSelectedShowItems(selectedShowItems);
                    setShowItemFirstLoad(section.id);
                }
            } else {
                callback(options);
            }
        }).catch((err: AxiosError) => {
            setStatusText(<span className='text-red-600'>{GetAxiosError(err)}</span>);
            callback([]);
        });
    }, 500);

    const onChangeCategory = async (options: any) => { setSelectedCategory(options); }
    const onChangeFeaturedList = async (options: any) => { setSelectedFeaturedList(options); }
    const onChangeShowItems = async (options: any) => { setSelectedShowItems(options); }
    const onChangeAudioLangs = async (options: any) => { setSelectedAudioLangs(options); }
    const onChangeSubtitleLangs = async (options: any) => { setSelectedSubtitleLangs(options); }
    const onChangeAcceptTypes = async (options: any) => { setSelectedAcceptTypes(options); }

    useEffect(() => {
        if(!section) return;

        setValue('title', section.title);
        setValue('sortOrder', section.sortOrder);
        setValue('targetType', section.targetType);
        setValue('hidden', !section.hidden ? 0 : (section.hiddenUntil ? 2 : 1));
        if(section.hidden && section.hiddenUntil) setValue('hiddenUntil', new Date(section.hiddenUntil));
        if(section.langMeta) setLangMeta({ ...JSON.parse(JSON.stringify(section.langMeta)), reset: -1, editing: [] })
        
        if(section.targetType == EPageSectionTargetType.CustomShowItemList || section.targetType == EPageSectionTargetType.CustomMultiCatList) {
            let targetConfig = section.targetConfig;

            setSelectedAudioLangs(targetConfig.audioLangs.map((l: string) => ({ label: t(`Shared.Langs.${l}`), value: l })));
            setSelectedSubtitleLangs(targetConfig.subtitleLangs.map((l: string) => ({ label: t(`Shared.Langs.${l}`), value: l })));
            setSelectedAcceptTypes(targetConfig.acceptTypes.map((l: string) => ({ label: t(`Shared.ShowItemTypes.${l}`), value: l })));
            setValue('sortBy', targetConfig.sort);
        }

        setShowItemFirstLoad(0);
        setSelectedShowItems([]);
    }, [section?.id]);

    const onCloseModal = () => {
        setShowItemFirstLoad(0);
        setCategoryFirstLoad(0);

        dispatch(ShowModalEditSection({ show: false }));
        reset();
    }

    const onClickClose = (e: any) => {
        e.preventDefault();
        onCloseModal();
    }

    const onSubmit = async (data: any) => {
        if(!show || !section) return;
       
        var targetId = null;
        var targetIds: number[] = [];
        var targetConfig: IPageSectionTargetConfig = {
            acceptTypes: [],
            audioLangs: [],
            subtitleLangs: [],
            sort: 'publishedAt:desc'
        };

        switch(targetType) {
            /*
            case EPageSectionTargetType.CategoryByUpdate: {
                let selectedCats = refCategory.current?.getValue() || [];
                targetId = selectedCats.length > 0 ? selectedCats[0].value : null;
                
                break;
            }*/
            case EPageSectionTargetType.FeaturedList: {
                let selectedLists = refFeaturedList.current?.getValue() || [];
                targetId = selectedLists.length > 0 ? selectedLists[0].value : null;
                
                break;
            }
            case EPageSectionTargetType.FixedShowItemList: {
                targetIds = refShowItem.current?.getValue().map((i: any) => i.value) || [];
                
                break;
            }
            case EPageSectionTargetType.CustomShowItemList: {
                let selectedCats = refCategory.current?.getValue() || [];
                targetId = selectedCats.length > 0 ? selectedCats[0].value : null;

                targetConfig.acceptTypes = selectedAcceptTypes.map((t: any) => t.value);
                targetConfig.audioLangs = selectedAudioLangs.map((l: any) => l.value);
                targetConfig.subtitleLangs = selectedSubtitleLangs.map((l: any) => l.value);
                targetConfig.sort = data.sortBy;

                break;
            }
            case EPageSectionTargetType.CustomMultiCatList: {
                let selectedCats = refCategory.current?.getValue() || [];
                targetIds = selectedCats.length > 0 ? selectedCats.map((i: any) => i.value) : [];

                targetConfig.acceptTypes = selectedAcceptTypes.map((t: any) => t.value);
                targetConfig.audioLangs = selectedAudioLangs.map((l: any) => l.value);
                targetConfig.subtitleLangs = selectedSubtitleLangs.map((l: any) => l.value);
                targetConfig.sort = data.sortBy;

                break;
            }
            default: return;
        }

        if(!targetId && targetIds.length <= 0) return;

        if(langMeta.editing.length > 0) {
            setLangMeta({ ...langMeta, reset: langMeta.reset + 1 });
            setTimeout(() => { (document.querySelector('#btn-update') as HTMLButtonElement).click(); }, 1);
            return;
        }

        dispatch(SetLoading(true));
        setStatusText('');

        try {
            var realHidden = false;
            var hiddenUntil = null;

            switch(hidden) {
                case 0: realHidden = false; break;
                case 1: realHidden = true; hiddenUntil = null; break;
                case 2: {
                    realHidden = true; 
                    hiddenUntil = data.hiddenUntil;

                    if(!hiddenUntil) {
                        setStatusText(<span className='text-red-600'>{ t('Shared.Errors.InvalidHiddenUntil') }</span>);
                        dispatch(SetLoading(false));
                        return;   
                    }

                    break;
                }
                default: break;
            }

            await AxiosClient.patch(`/pages/sections/${section.id}`, {
                title: data.title,
                sortOrder: data.sortOrder,
                targetType: data.targetType,
                targetId,
                targetIds,
                targetConfig,
                hidden: realHidden,
                hiddenUntil,
                langMeta
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReload());
        
        onCloseModal();
    }
     
    if(!section) return null;

    const hiddenOptions = [
        { value: 0, label: t('Shared.Fields.Visible') },
        { value: 1, label: t('Shared.Fields.Hidden')  },
        { value: 2, label: t('Shared.Fields.HiddenUntil')  },
    ]
    
    const onClickDelete = async () => {
        let result = window.confirm(t('Shared.Text.DeleteConfirm') || '');
        if(result !== true) return;

        dispatch(SetLoading(true));
        setStatusText(`${t('Shared.Text.Deleting')}...`);

        try {
            await AxiosClient.delete(`/pages/sections/${section.id}`);
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReload());

        onCloseModal();
    }
    
    return (
        <Modal isOpen={show} onRequestClose={onCloseModal} style={StyleCompact} contentLabel="Modal: EditSection">
            <div className="px-6 py-3">
                <div className='flex justify-between items-center mb-5'>
                    <h5 className='mb-0'>{ t('Pages.Layout.PageAndSection.PageEditSections.Modals.EditSection.Title') }</h5>
                    <span onClick={onClickClose} className='text-2xl hover:cursor-pointer'><h3><i className="far fa-times-circle"></i></h3></span>
                </div>
                <form role="form" onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-2 gap-3 mb-3'>
                        <div className='col-span-2 md:col-span-1'>
                            <div className='mb-2 flex justify-content-start items-center'>
                                <label className="ml-1 font-bold text-xs text-slate-700 mr-3">{ t('Shared.Fields.Title') } <span className='text-red-600'>*</span></label>
                                <ContentLanguageSelector defaultLang={'eng'} field='title' langMeta={langMeta} setLangMeta={setLangMeta} setValue={(value: string) => { setValue('title', value) }} getValue={getValues}/>
                            </div>
                            <div>
                                <input type="text" className="input input-textbox" placeholder="Title" { ...register("title", { required: true, minLength: 1, maxLength: 128  }) }/>
                            </div>
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.SortOrder') } </label>
                            <div>
                                <input type="number" className="input input-textbox" placeholder="Sort Order" { ...register("sortOrder", { min: 0 }) } defaultValue={0}/>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mb-3'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.TargetType') } <span className='text-red-600'>*</span></label>
                            <div>
                                <Controller
                                    name="targetType"
                                    defaultValue={targetTypeOptions[1].value}
                                    control={control}
                                    render={({ field }) => (
                                        <Select isClearable={false} className='input input-select2' options={targetTypeOptions} value={targetTypeOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                    )}
                                />
                            </div>
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.TargetID') } </label>
                            <div>
                                { [/*EPageSectionTargetType.CategoryByUpdate, */EPageSectionTargetType.CustomShowItemList].indexOf(targetType) !== -1 && 
                                    <AsyncSelect ref={refCategory} className='input input-select2' cacheOptions loadOptions={onLoadCategories} defaultOptions value={selectedCategory} onChange={onChangeCategory}/>
                                }
                                { targetType == EPageSectionTargetType.FeaturedList && 
                                    <AsyncSelect ref={refFeaturedList} className='input input-select2' cacheOptions loadOptions={onLoadFeaturedLists} defaultOptions value={selectedFeaturedList} onChange={onChangeFeaturedList}/>
                                }
                                { targetType == EPageSectionTargetType.FixedShowItemList && 
                                    <AsyncSelect isMulti ref={refShowItem} className='input input-select2' cacheOptions loadOptions={onLoadShowItems} defaultOptions value={selectedShowItems} onChange={onChangeShowItems}/>
                                }
                                { EPageSectionTargetType.CustomMultiCatList == targetType && 
                                    <AsyncSelect isMulti ref={refCategory} className='input input-select2' cacheOptions loadOptions={onLoadCategories} defaultOptions value={selectedCategory} onChange={onChangeCategory}/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mb-3'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Visibility') } <span className='text-red-600'>*</span></label>
                            <div>
                                <Controller
                                    name="hidden"
                                    defaultValue={hiddenOptions[0].value}
                                    control={control}
                                    render={({ field }) => (
                                        <Select styles={{ menu: (base) => ({ ...base, top: -50 }) }} isClearable={false} className='input input-select2' options={hiddenOptions} value={hiddenOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                    )}
                                />
                            </div>
                        </div>
                        { hidden == 2 && <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.HiddenUntil') } <span className='text-red-600'>*</span></label>
                            <div>
                            <Controller
                                name="hiddenUntil"
                                control={control}
                                render={({ field }) => (
                                    <ReactDatePicker
                                        popperPlacement='auto'
                                        className='input input-textbox'
                                        placeholderText='Select date'
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                    />
                                )}
                            />
                            </div>
                        </div> }
                    </div>
                    { (targetType == EPageSectionTargetType.CustomShowItemList || targetType == EPageSectionTargetType.CustomMultiCatList) &&
                    <>
                        <hr className="h-px mb-3 mt-5 bg-gray-500 border-0"/>
                        <div className='grid grid-cols-2 gap-3 mb-3'>
                            <div className='col-span-2 md:col-span-1'>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.AudioLangs') }</label>
                                <div>
                                    <Select styles={{ menu: (base) => ({ ...base, top: -50 }) }} isMulti ref={refAudioLangs} className='input input-select2' options={supportedLangs.map((l: string) => ({ label: t(`Shared.Langs.${l}`), value: l }))} value={selectedAudioLangs} onChange={onChangeAudioLangs}/>
                                </div>
                            </div>
                            <div className='col-span-2 md:col-span-1'>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.SubtitleLangs') }</label>
                                <div>
                                    <Select styles={{ menu: (base) => ({ ...base, top: -50 }) }} isMulti ref={refSubtitleLangs} className='input input-select2' options={supportedLangs.map((l: string) => ({ label: t(`Shared.Langs.${l}`), value: l }))} value={selectedSubtitleLangs} onChange={onChangeSubtitleLangs}/>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-3 mb-3'>
                            <div className='col-span-2 md:col-span-1'>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.AcceptTypes') }</label>
                                <div>
                                    <Select styles={{ menu: (base) => ({ ...base, top: -50 }) }} isMulti ref={refAcceptTypes} className='input input-select2' options={[{ label: t(`Shared.ShowItemTypes.Series`), value: 'Series' }, { label: t(`Shared.ShowItemTypes.Movie`), value: 'Movie' } ]} value={selectedAcceptTypes} onChange={onChangeAcceptTypes}/>
                                </div>
                            </div>
                            <div className='col-span-2 md:col-span-1'>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Sort') }</label>
                                <div>
                                    <Controller
                                        name="sortBy"
                                        defaultValue={sortByOptions[0].value}
                                        control={control}
                                        render={({ field }) => (
                                            <Select isClearable={false} styles={{ menu: (base) => ({ ...base, top: -50 }) }}  className='input input-select2' options={sortByOptions} value={sortByOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                    }
                    <div className='flex flex-col md:flex-row justify-between items-center mb-3 mt-7'>
                        <p className='mb-0'>{ statusText }</p>
                        <div>
                            <button type="button" onClick={onClickDelete} className="btn btn-red btn-keep-size block mr-2"><i className="fa-solid fa-trash mr-2"></i>{ t('Shared.Actions.Delete') }</button>
                            <button id="btn-update" type="submit" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-pen-nib mr-2"></i>{ t('Shared.Actions.Update') }</button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default React.memo(EditSectionModal);