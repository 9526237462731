import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';
import { ListingForceReloadSeasons, ShowModalEditEpisode } from 'store/show.page.slice';

import { StyleCompact } from 'utils/modal';
import { AxiosClient, GetAxiosError } from 'utils/axios';
import { OptimizePhotoUrl } from 'utils/file';
import { EPhotoVariants } from 'types/File';
import { EShowItemTrackProvider } from 'types/ShowItem';
import axios from 'axios';

function EditEpisodeModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { register, handleSubmit, control, watch, setValue, getValues, reset } = useForm();
    const hidden = watch('hidden', false);

    const show = useAppSelector((state) => state.pageShow.modal.editEpisode.show)
    const episode = useAppSelector((state) => state.pageShow.modal.editEpisode.episode);
    const seasonId = useAppSelector((state) => state.pageShow.modal.editEpisode.seasonId);
    
    const refFileCoverImage = useRef<HTMLInputElement>(null);
    const [coverImage, setCoverImage] = useState<string|ArrayBuffer|null>(null);
    const [uploadedCoverImageUID, setUploadedCoverImageUID] = useState<string|null>(null);
    const [statusText, setStatusText] = useState<any>('');

    useEffect(() => {
        if(!episode) return;

        setValue('originalTitle', episode.originalTitle);
        setValue('sortOrder', episode.sortOrder);
        setValue('duration', episode.duration)

        setValue('hidden', !episode.hidden ? 0 : (episode.hiddenUntil ? 2 : 1));
        if(episode.hidden && episode.hiddenUntil) setValue('hiddenUntil', new Date(episode.hiddenUntil));

        if(episode.thumbnail) {
            // @ts-ignore
            setUploadedCoverImageUID(episode.thumbnail.uid);
            setCoverImage(OptimizePhotoUrl(episode.thumbnail, EPhotoVariants.Medium));
        } else {
            setUploadedCoverImageUID(null);
            setCoverImage(null);
        }
    }, [episode]);

    const onCloseModal = () => {
        dispatch(ShowModalEditEpisode({ show: false }));
        reset();
    }

    const onClickClose = (e: any) => {
        e.preventDefault();
        onCloseModal();
    }

    const onClickSelectCoverImage = (e: any) => { refFileCoverImage.current?.click(); }
    const onFileCoverImageChange = (e: any) => {
        let reader = new FileReader();

        reader.onload = function() { 
            setCoverImage(reader.result); 
            setUploadedCoverImageUID(null);
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    const onClickImport = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        
        var fileCode = getValues('fileCode')
        if(!fileCode) return;

        dispatch(SetLoading(true));
        setStatusText(`${t('Shared.Text.Fetching')}...`);

        try {
            let { data } = await AxiosClient.get('/show-items/fetch-provider', { 
                params: { 
                    provider: EShowItemTrackProvider.DoodStream,
                    fileCode
                } 
            });

            setValue('duration', data.duration);
            //setValue('originalTitle', data.title);
    
            if(data.thumbnailUrl) {
                axios.get(data.thumbnailUrl, { responseType: 'blob' }).then(response => {
                    let blob = response.data;
                    let reader = new FileReader();

                    reader.onload = function(e) { 
                        setCoverImage(reader.result); 
                        setUploadedCoverImageUID(null);
                    };
                    reader.readAsDataURL(blob);

                    let file = new File([blob], "image.jpg",{ type:"image/jpeg", lastModified:new Date().getTime()});
                    let container = new DataTransfer();
                    container.items.add(file);

                    if(refFileCoverImage.current) refFileCoverImage.current.files = container.files;
                }).catch(() => {})
            }

        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        setStatusText('');
        dispatch(SetLoading(false));
    }

    const onSubmit = async (data: any) => {
        if(!show || !episode || !refFileCoverImage.current) return;

        dispatch(SetLoading(true));
        setStatusText('');

        var photoUID = uploadedCoverImageUID;
        if(coverImage && !uploadedCoverImageUID) {
            let formData = new FormData();
            // @ts-ignore
            formData.append('file', refFileCoverImage.current?.files[0]);

            try {
                setStatusText(`${t('Shared.Text.Uploading')}...`);
                let uploadedImage = await AxiosClient.post('/files/private-upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    params: { type: 'Image' }
                });

                setUploadedCoverImageUID(uploadedImage.data.uid);
                photoUID = uploadedImage.data.uid;
            } catch(e) {
                setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
                dispatch(SetLoading(false));
                return;
            }
        } else {
            dispatch(SetLoading(true));
        }

        try {
            var realHidden = false;
            var hiddenUntil = null;

            switch(hidden) {
                case 0: realHidden = false; break;
                case 1: realHidden = true; hiddenUntil = null; break;
                case 2: {
                    realHidden = true; 
                    hiddenUntil = data.hiddenUntil;

                    if(!hiddenUntil) {
                        setStatusText(<span className='text-red-600'>{ t('Shared.Errors.InvalidHiddenUntil') }</span>);
                        dispatch(SetLoading(false));
                        return;   
                    }

                    break;
                }
                default: break;
            }

            await AxiosClient.patch(`/show-items/episodes/${episode.id}`, {
                originalTitle: data.originalTitle,
                sortOrder: data.sortOrder,
                duration: data.duration,
                thumbnailFileUId: photoUID,
                hidden: realHidden,
                hiddenUntil
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReloadSeasons(seasonId));
        
        onCloseModal();
    }
     
    if(!episode) return null;
    
    const hiddenOptions = [
        { value: 0, label: t('Shared.Fields.Visible') },
        { value: 1, label: t('Shared.Fields.Hidden')  },
        { value: 2, label: t('Shared.Fields.HiddenUntil')  },
    ]

    const onClickDelete = async () => {
        let result = window.confirm(t('Shared.Text.DeleteConfirm') || '');
        if(result !== true) return;

        dispatch(SetLoading(true));
        setStatusText(`${t('Shared.Text.Deleting')}...`);

        try {
            await AxiosClient.delete(`/show-items/episodes/${episode.id}`);
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReloadSeasons(seasonId));

        onCloseModal();
    }

    return (
        <Modal isOpen={show} onRequestClose={onCloseModal} style={StyleCompact} contentLabel="Modal: EditEpisode">
            <div className="px-6 py-3">
                <div className='flex justify-between items-center mb-5'>
                    <h5 className='mb-0'>{ t('Pages.Content.Show.EditSeasonEpisode.Modals.EditEpisode.Title') }</h5>
                    <span onClick={onClickClose} className='text-2xl hover:cursor-pointer'><h3><i className="far fa-times-circle"></i></h3></span>
                </div>
                <form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-2 gap-3 mb-3'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Episode') } <span className='text-red-600'>*</span></label>
                            <div>
                                <input type="text" className="input input-textbox" placeholder={ t('Shared.Fields.Episode') || '' } { ...register("originalTitle", { required: true, minLength: 1, maxLength: 128 }) }/>
                            </div>
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.SortOrder') } <span className='text-red-600'>*</span></label>
                            <div>
                                <input type="number" className="input input-textbox" placeholder="Sort Order" { ...register("sortOrder", { required: true }) } defaultValue={0}/>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mb-3'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Thumbnail') }</label>
                            <div onClick={onClickSelectCoverImage} className={`image-select image-select-landscape ${coverImage ? '' : 'image-select-none'} shadow-lg hover:cursor-pointer`}>
                                { !coverImage && <div className='text-center'>
                                    <p className='text-5xl mb-2'><i className="fa-solid fa-image-landscape"></i></p>
                                    <p>{ t('Shared.Fields.Thumbnail')}</p>
                                </div> }
                                { coverImage && <img src={coverImage.toString()}/> }
                            </div>
                            <input ref={refFileCoverImage} onChange={onFileCoverImageChange} type="file" accept='image/png, image/jpeg' className="hidden"/>
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Duration') }</label>
                            <div>
                                <input type="number" className="input input-textbox" placeholder="Second" { ...register("duration", { required: true }) } defaultValue={0}/>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mb-3'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Visibility') } <span className='text-red-600'>*</span></label>
                            <div>
                                <Controller
                                    name="hidden"
                                    defaultValue={hiddenOptions[0].value}
                                    control={control}
                                    render={({ field }) => (
                                        <Select styles={{ menu: (base) => ({ ...base, top: -50 }) }} isClearable={false} className='input input-select2' options={hiddenOptions} value={hiddenOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                    )}
                                />
                            </div>
                        </div>
                        { hidden == 2 && <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.HiddenUntil') } <span className='text-red-600'>*</span></label>
                            <div>
                            <Controller
                                name="hiddenUntil"
                                control={control}
                                render={({ field }) => (
                                    <ReactDatePicker
                                        popperPlacement='auto'
                                        className='input input-textbox'
                                        placeholderText='Select date'
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                    />
                                )}
                            />
                            </div>
                        </div> }
                    </div>
                    <hr className='h-px my-3 bg-gray-500 border-0'/>
                    <div className='mb-10'>
                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.DoodStreamFileCode') }</label>
                        <div className='grid grid-cols-12 gap-3'>
                            <input type="text" className="input input-textbox col-span-8 md:col-span-9" placeholder="Code" { ...register("fileCode", { maxLength: 255 }) }/>
                            <button onClick={onClickImport} className="btn btn-aurora block col-span-4 md:col-span-3"><i className="fa-solid fa-file-import mr-2"></i>{ t('Shared.Actions.Import') }</button>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between items-center mb-3'>
                        <p className='mb-0'>{ statusText }</p>
                        <div>
                            <button type="button" onClick={onClickDelete} className="btn btn-red btn-keep-size block mr-2"><i className="fa-solid fa-trash mr-2"></i>{ t('Shared.Actions.Delete') }</button>
                            <button type="submit" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-pen-nib mr-2"></i>{ t('Shared.Actions.Update') }</button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default React.memo(EditEpisodeModal);