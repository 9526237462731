
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface PageState {
  initState: number;
  loading: boolean;
}

const initialState: PageState = {
  initState: 0,
  loading: false
};

export const LayoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    SetInitState(state, action: PayloadAction<number>) {
      state.initState = action.payload;
    },
    SetLoading(state, action) {
      state.loading = action.payload;
    },
  }
});

export const { 
  SetInitState, SetLoading
} = LayoutSlice.actions;

export default LayoutSlice.reducer;