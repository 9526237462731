import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';
import { ListingForceReload, ShowModalEditSeason } from 'store/show.page.slice';

import { StyleCompact } from 'utils/modal';
import { AxiosClient, GetAxiosError } from 'utils/axios';

function EditSeasonModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { register, handleSubmit, control, watch, setValue, reset } = useForm();
    const hidden = watch('hidden', false);

    const show = useAppSelector((state) => state.pageShow.modal.editSeason.show)
    const season = useAppSelector((state) => state.pageShow.modal.editSeason.season);
    
    const [statusText, setStatusText] = useState<any>('');

    useEffect(() => {
        if(!season) return;

        setValue('originalTitle', season.originalTitle);
        setValue('sortOrder', season.sortOrder);

        setValue('hidden', !season.hidden ? 0 : (season.hiddenUntil ? 2 : 1));
        if(season.hidden && season.hiddenUntil) setValue('hiddenUntil', new Date(season.hiddenUntil));

    }, [season]);

    const onCloseModal = () => {
        dispatch(ShowModalEditSeason({ show: false }));
        reset();
    }

    const onClickClose = (e: any) => {
        e.preventDefault();
        onCloseModal();
    }

    const onSubmit = async (data: any) => {
        if(!show || !season) return;

        dispatch(SetLoading(true));
        setStatusText('');

        try {
            var realHidden = false;
            var hiddenUntil = null;

            switch(hidden) {
                case 0: realHidden = false; break;
                case 1: realHidden = true; hiddenUntil = null; break;
                case 2: {
                    realHidden = true; 
                    hiddenUntil = data.hiddenUntil;

                    if(!hiddenUntil) {
                        setStatusText(<span className='text-red-600'>{ t('Shared.Errors.InvalidHiddenUntil') }</span>);
                        dispatch(SetLoading(false));
                        return;   
                    }

                    break;
                }
                default: break;
            }

            await AxiosClient.patch(`/show-items/seasons/${season.id}`, {
                originalTitle: data.originalTitle,
                sortOrder: data.sortOrder,
                hidden: realHidden,
                hiddenUntil
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReload());
        
        onCloseModal();
    }
     
    if(!season) return null;

    const onClickDelete = async () => {
        let result = window.confirm(t('Shared.Text.DeleteConfirm') || '');
        if(result !== true) return;

        dispatch(SetLoading(true));
        setStatusText(`${t('Shared.Text.Deleting')}...`);

        try {
            await AxiosClient.delete(`/show-items/seasons/${season.id}`);
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReload());

        onCloseModal();
    }
    
    const hiddenOptions = [
        { value: 0, label: t('Shared.Fields.Visible') },
        { value: 1, label: t('Shared.Fields.Hidden')  },
        { value: 2, label: t('Shared.Fields.HiddenUntil')  },
    ]

    return (
        <Modal isOpen={show} onRequestClose={onCloseModal} style={StyleCompact} contentLabel="Modal: EditSeason">
            <div className="px-6 py-3">
                <div className='flex justify-between items-center mb-5'>
                    <h5 className='mb-0'>{ t('Pages.Content.Show.EditSeasonEpisode.Modals.EditSeason.Title') }</h5>
                    <span onClick={onClickClose} className='text-2xl hover:cursor-pointer'><h3><i className="far fa-times-circle"></i></h3></span>
                </div>
                <form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-2 gap-3 mb-3'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Season') } <span className='text-red-600'>*</span></label>
                            <div>
                                <input type="text" className="input input-textbox" placeholder={ t('Shared.Fields.Season') || '' }{ ...register("originalTitle", { required: true, minLength: 1, maxLength: 128 }) }/>
                            </div>
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.SortOrder') } <span className='text-red-600'>*</span></label>
                            <div>
                                <input type="number" className="input input-textbox" placeholder="Sort Order" { ...register("sortOrder", { required: true }) } defaultValue={0}/>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mb-10'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Visibility') } <span className='text-red-600'>*</span></label>
                            <div>
                                <Controller
                                    name="hidden"
                                    defaultValue={hiddenOptions[0].value}
                                    control={control}
                                    render={({ field }) => (
                                        <Select styles={{ menu: (base) => ({ ...base, top: -50 }) }} isClearable={false} className='input input-select2' options={hiddenOptions} value={hiddenOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                    )}
                                />
                            </div>
                        </div>
                        { hidden == 2 && <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.HiddenUntil') } <span className='text-red-600'>*</span></label>
                            <div>
                            <Controller
                                name="hiddenUntil"
                                control={control}
                                render={({ field }) => (
                                    <ReactDatePicker
                                        popperPlacement='auto'
                                        className='input input-textbox'
                                        placeholderText='Select date'
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                    />
                                )}
                            />
                            </div>
                        </div> }
                    </div>
                    <div className='flex flex-col md:flex-row justify-between items-center mb-3'>
                        <p className='mb-0'>{ statusText }</p>
                        <div>
                            <button type="button" onClick={onClickDelete} className="btn btn-red btn-keep-size block mr-2"><i className="fa-solid fa-trash mr-2"></i>{ t('Shared.Actions.Delete') }</button>
                            <button type="submit" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-pen-nib mr-2"></i>{ t('Shared.Actions.Update') }</button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default React.memo(EditSeasonModal);