import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import { SetLoading } from 'store/layout.slice';
import { useAppDispatch } from 'store/hooks';
import { usePagination } from 'hooks/page';

import Pagination from 'components/Pagination';
import { FeaturedList } from 'types/Featured';

import { AxiosClient } from 'utils/axios';

function FeaturedListListing() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [pageStart, pageLimit, pageNumber, GoToPage, ChangePageSize] = usePagination();

    const [items, setItems] = useState<FeaturedList[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);

    useEffect(() => {
        dispatch(SetLoading(true));
        AxiosClient.get('/featured', { 
            params: {
                withItemCount: true,
                sort: "id:asc",
                paginationStart: pageStart,
                paginationLimit: pageLimit
            }
        }).then((res: AxiosResponse) => {
            setItems(res.data.data);
            setTotalItems(res.data.meta.pagination.total);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
        })
    }, [pageNumber]);

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6 mx-auto">
            <div className="flex flex-wrap">
                <div className="flex-none w-full max-w-full">
                    <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <div className='flex flex-row justify-between items-center p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent'>
                            <h6 className='mb-0'>{ t('Shared.Fields.FeaturedLists') }</h6>
                            <Link to="/layout/featured/add" className='btn btn-aurora'><i className="fa-solid fa-circle-plus mr-2"></i>{ t('Shared.Actions.Add') }</Link>
                        </div>
                        <div className="flex-auto px-0 pt-0 pb-2">
                            <div className="p-0 overflow-x-auto">
                                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                                    <thead className="align-bottom">
                                        <tr>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.ID') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.FeaturedList') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.ItemCount') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Autoplay') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Created') }</th>
                                            <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items.length > 0 ? 
                                            items.map((l: FeaturedList) => {
                                                return (
                                                    <tr key={l.id}>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 font-semibold leading-tight text-xs text-center">{ l.id }</p>
                                                        </td>
                                                        <td className="px-6 py-2 pl-0 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <div className="flex px-2 py-1">
                                                                <div className="flex flex-col justify-center">
                                                                    <h6 className="mb-0 leading-normal text-sm">{ l.title }</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 font-semibold leading-tight text-xs text-center">{ l.itemCount }</p>
                                                        </td>
                                                        <td className="px-6 py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <span className="mb-0 font-semibold leading-tight text-xs text-center">{ l.autoplay ? <span className='text-green-600'><i className="fa-solid fa-badge-check"></i></span> : '-'  }</span>
                                                        </td>
                                                        <td className="px-6 py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <span className="mb-0 font-semibold leading-tight text-xs text-slate-400 text-center">{ l.createdAt ? new Date(l.createdAt).toLocaleString() : '-'  }</span>
                                                        </td>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <Link to={`/layout/featured/edit-items/${l.id}`} className="font-semibold leading-tight text-slate-400 mr-4"><i className="fa-solid fa-list"></i></Link>
                                                            <Link to={`/layout/featured/${l.id}`} className="font-semibold leading-tight text-slate-400"><i className="fa-solid fa-pen-to-square"></i></Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        :
                                        <>
                                            <tr>
                                                <td colSpan={6}><p className='text-center mt-5'>{ t('Shared.Text.ThereAreNoItems') }</p></td>
                                            </tr>
                                        </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Pagination page={pageNumber} size={pageLimit} total={totalItems} onGoToPage={GoToPage} onChangePageSize={ChangePageSize}/>
                </div>
            </div>
        </div>
    );
}

export default React.memo(FeaturedListListing);
