
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FeaturedItem } from "types/Featured";

export interface IShowModalCreateItemPayload {
  show: boolean;
  listId?: number;
}

export interface IShowModalEditItemPayload {
  show: boolean;
  item?: FeaturedItem;
}

export interface FeaturedPageState {
  listing: {
    forceReload: number;
  },
  modal: {
    createItem: {
      show: boolean;
      listId: number;
    },
    editItem: {
      show: boolean;
      item: FeaturedItem | null;
    }
  }
}

const initialState: FeaturedPageState = {
  listing: {
    forceReload: 0
  },
  modal: {
    createItem: {
      show: false,
      listId: 0
    },
    editItem: {
      show: false,
      item: null
    }
  }
};

export const FeaturedPageSlice = createSlice({
  name: "featuredPage",
  initialState,
  reducers: {
    ListingForceReload(state) {
      state.listing.forceReload++;
    },
    ShowModalCreateItem(state, action: PayloadAction<IShowModalCreateItemPayload>) {
      state.modal.createItem.show = action.payload.show;
      state.modal.createItem.listId = action.payload.listId || 0;
    },
    ShowModalEditItem(state, action: PayloadAction<IShowModalEditItemPayload>) {
      state.modal.editItem.show = action.payload.show;
      state.modal.editItem.item = action.payload.item || null;
    }
  }
});

export const { 
  ListingForceReload, ShowModalCreateItem, ShowModalEditItem
} = FeaturedPageSlice.actions;

export default FeaturedPageSlice.reducer;