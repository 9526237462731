import { Category } from "./Category";
import { FilePhoto } from "./File";
import { Person } from "./Person";
import { LanguageMeta } from "./Shared";

export enum EShowItemType {
    Series = 'Series',
    Season = 'Season',
    Episode = 'Episode',
    Movie = 'Movie'
}

export enum EShowItemSubType {
    SingleSeasonSeries = 'SingleSeasonSeries',
    MultiSeasonSeries = 'MultiSeasonSeries'
}

export enum EShowItemVideoQualityType {
    BDRip = 'BDRip',
    WEBDL = 'WEB-DL',
    WEBRip = 'WEBRip'
}

export enum ECoverType {
    StaticImage = "StaticImage",
    YoutubeVideo = "YoutubeVideo"
}

export enum EShowItemTrackProvider {
    DoodStream = 'DoodStream'
}

export enum EShowItemTrackContentType {
    All = 'All',
    Free = 'Free',
    Premium = 'Premium'
}

export interface ICoverData {
    url?: string;
    uid?: string;
    staticImage?: object;
}

export interface ShowItem {
    id: number;
    type: EShowItemType;
    subType: EShowItemSubType | null;
    originalTitle: string;
    slug: string;
    description: string | null;
    coverType: ECoverType;
    coverData: ICoverData;
    releaseYear: number | null;
    endYear: number | null;
    audioLangs: string[];
    subtitleLangs: string[];
    people: Person[];
    sortOrder: number;
    hidden: boolean;
    hiddenUntil: Date | null;
    createdAt: Date;
    updatedAt: Date;
    thumbnail: FilePhoto | null;
    categories: Category[];
    parent: ShowItem | null;
    children: ShowItem[];
    videoQualityType: EShowItemVideoQualityType | null;
    imdbId: string | null;
    langMeta: LanguageMeta;
    trailerUrl: string | null;
    premium: boolean;
    duration: number;
}

export interface ShowItemTrack {
    id: number;
    showItem: ShowItem;
    title: string;
    provider: EShowItemTrackProvider;
    contentType: EShowItemTrackContentType;
    url: string;
    sortOrder: number;
    createdAt: Date;
    updatedAt: Date;
}