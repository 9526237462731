import React, { useEffect, useState } from 'react';
import { LanguageMeta } from 'types/Shared';
import { supportedLangs } from 'utils/validator';

interface Props {
    defaultLang: string | null;
    field: string;
    langMeta: LanguageMeta;
    setLangMeta: React.Dispatch<React.SetStateAction<LanguageMeta>>;
    setValue: any;
    getValue: any;
}

function ContentLanguageSelector(props: Props) {
    const [selected, setSelected] = useState<string|null>(props.defaultLang);
    const [originalValue, setOriginalValue] = useState<string>('');
    const [reset, setReset] = useState<number>(0);

    var langMeta: LanguageMeta = props.langMeta;
    
    useEffect(() => {
        if(langMeta.reset <= reset) return;
        setReset(langMeta.reset);

        if(props.defaultLang) onSelectLanguage(props.defaultLang)
        else if(selected) onSelectLanguage(selected);
    }, [langMeta.reset]);

    const onSelectLanguage = (lang: string) => {
        // @ts-ignore
        if(!langMeta[lang]) langMeta[lang] = {};

        if(lang != selected) {
            if(selected == null) {
                setOriginalValue(props.getValue(props.field) || '');
                if(!props.defaultLang || (props.defaultLang && props.defaultLang != lang)) {
                    langMeta.editing.push(props.field);
                }
            } else {
                // @ts-ignore
                if(!langMeta[selected]) langMeta[selected] = {};
                // @ts-ignore
                langMeta[selected][props.field] = props.getValue(props.field);
                if(props.defaultLang != lang && langMeta.editing.indexOf(props.field) == -1) langMeta.editing.push(props.field);
                else if(props.defaultLang == lang) langMeta.editing = langMeta.editing.filter((e) => e != props.field);
            }

            // @ts-ignore
            var text: string = langMeta[lang] ? langMeta[lang][props.field] : '';

            props.setValue(text);
            props.setLangMeta({ ...langMeta });

            setSelected(lang)
        } else {
            if(selected) {
                // @ts-ignore
                langMeta[selected][props.field] = props.getValue(props.field);
            }

            if(!props.defaultLang) {
                setSelected(null);
                props.setValue(originalValue || '');
                langMeta.editing = langMeta.editing.filter((e) => e != props.field);
            } else if(props.defaultLang == lang) {
                langMeta.editing = langMeta.editing.filter((e) => e != props.field);
            }

            let newLangMeta = { ...langMeta };
            props.setLangMeta(newLangMeta);
        }
    }

    return (
        <div className='flex flex-row justify-content-start items-center h-4'>
            { supportedLangs.map((l: string) => {
                // @ts-ignore
                let fieldText: string = langMeta[l] ? langMeta[l][props.field] : '';
                let opacity: string = (selected == l || fieldText?.length > 0) ? 'opacity-100' : 'opacity-50';
                return <img key={l} onClick={onSelectLanguage.bind(null, l)} className={`h-full ${opacity} hover:cursor-pointer mr-2 drop-shadow-lg transition duration-150 ease-in-out ${selected == l ? '-translate-y-1' : 'active:-translate-y-1'}`} src={`/img/flags/${l}.jpg`}/>
            }) }
        </div>
    );
}

export default React.memo(ContentLanguageSelector);