import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

import CompactFeaturedItem from './components/FeaturedItem';
import { FeaturedItem, FeaturedList } from 'types/Featured';

import CreateItemModal from './modals/CreateItem.modal';
import EditItemModal from './modals/EditItem.modal';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';
import { ShowModalCreateItem, ShowModalEditItem } from 'store/featured.page.slice';

import { AxiosClient } from 'utils/axios';
import { DragCard } from 'components/DragCard';

function FeaturedListEditItem() {
    const { id } = useParams();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const forceReload = useAppSelector((state) => state.featuredPage.listing.forceReload);

    const [list, setList] = useState<FeaturedList|null>(null);
    const [items, setItems] = useState<FeaturedItem[]>([]);

    const [sortingMode, setSortingMode] = useState<boolean>(false);
    const [sortingItems, setSortingItems] = useState<FeaturedItem[]>([]);

    useEffect(() => { 
        if(!id) { 
            navigate('/layout/featured'); 
            return 
        };

        dispatch(SetLoading(true));
        AxiosClient.get(`/featured/${id}`).then((res: AxiosResponse) => {
            let list: FeaturedList = res.data;

            setList(list);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
            navigate('/layout/featured'); 
        });

    }, [id]);

    useEffect(() => { 
        if(!list) return 

        dispatch(SetLoading(true));
        AxiosClient.get(`/featured/${id}/items`, { params: { paginationLimit: 100, sort: 'sortOrder:asc,id:asc', withEntity: true } }).then((res: AxiosResponse) => {
            let items: FeaturedItem[] = res.data.data;

            setItems(items);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
            navigate('/layout/featured'); 
        });
    }, [list, forceReload]);

    const onClickAddItem = () => {
        dispatch(ShowModalCreateItem({ show: true, listId: list?.id }));
    }

    const onClickEditItem = useCallback((item: FeaturedItem) => {
       dispatch(ShowModalEditItem({ show: true, item: item }));
    }, [list]);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setSortingItems((prevItems: FeaturedItem[]) => 
            update(prevItems, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevItems[dragIndex] as FeaturedItem],
                ],
            }),
        )
    }, [])

    const onClickSaveSorts = async () => {
        if(!sortingMode || sortingItems.length <= 0) return;

        dispatch(SetLoading(true));

        try {
            await AxiosClient.patch(`/featured/items/sort-orders`, {
                orders: sortingItems.map((i, index) => ({ id: i.id, sortOrder: index + 1}))
            });

            setItems(sortingItems);
            setSortingMode(false);

            dispatch(SetLoading(false));
        } catch(e) {
            dispatch(SetLoading(false));
        }
    }
    
    if(!id || !list) return null;

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[750px]">
                    <div className='mb-4 flex justify-between items-center'>
                        <div className="flex items-center">
                            <input id="checkbox-sorting-mode" type="checkbox" checked={sortingMode} onChange={(event) => { setSortingItems(items); setSortingMode(event.target.checked) }} className="input input-checkbox"/>
                            <label htmlFor="checkbox-sorting-mode" className="ml-2 text-sm font-medium text-gray-900">{ t('Shared.Fields.SortingMode') }</label>
                        </div>
                        { sortingMode && <button onClick={onClickSaveSorts} className='btn btn-aurora btn-sm'><i className="fa-solid fa-floppy-disk mr-1"></i>{ t('Shared.Actions.Save') }</button>}
                    </div>
                    { sortingMode ? 
                    <DndProvider backend={HTML5Backend}>
                        { sortingItems.length > 0 ?
                            sortingItems.map((i: FeaturedItem, index: number) =>  <DragCard key={i.id} index={index} id={i.id} text={`${t(`Shared.FeaturedItemTargetTypes.${i.targetType}`)} / (#${i.targetId}) ${t(`Shared.ShowItemTypes.${i.targetEntity?.type}`)}: ${i.targetEntity?.originalTitle}`} moveCard={moveCard}/>)
                            :
                            <p className='mb-5 text-center'>{ t('Shared.Text.ThereAreNoItems') }</p>
                        }
                    </DndProvider>
                    :
                    <>
                        { items.length > 0 ?
                            items.map((i: FeaturedItem) => <CompactFeaturedItem key={i.id} data={i} onClickEditItem={onClickEditItem}/>)
                            :
                            <p className='mb-5 text-center'>{ t('Shared.Text.ThereAreNoItems') }</p>
                        }
                        <div className='text-center'>
                            <button onClick={onClickAddItem} className='btn btn-aurora'><i className="fa-solid fa-circle-plus mr-2"></i>{ t('Shared.Actions.AddFeaturedItem') }</button>
                        </div>
                    </>
                    }
                </div>
            </div>
            <CreateItemModal/>
            <EditItemModal/>
        </div>
    );
}

export default React.memo(FeaturedListEditItem);
