import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ListingForceReload, ShowModalItemDetail } from 'store/recycleBin.page.slice';

import { StyleLarge } from 'utils/modal';
import { IsJsonString } from 'utils/string';
import { SetLoading } from 'store/layout.slice';
import { AxiosClient, GetAxiosError } from 'utils/axios';

function ItemDetailModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const show = useAppSelector((state) => state.pageRecycleBin.modal.itemDetail.show)
    const content = useAppSelector((state) => state.pageRecycleBin.modal.itemDetail.content);
    const log = useAppSelector((state) => state.pageRecycleBin.modal.itemDetail.log);

    const [statusText, setStatusText] = useState<any>('');

    const onCloseModal = () => {
        dispatch(ShowModalItemDetail({ show: false, content: null, log: null }));
    }

    const onClickClose = (e: any) => {
        e.preventDefault();
        onCloseModal();
    }

    const onClickRestore = async (e: any) => {
        if(!log) return;

        let result = window.confirm(t('Shared.Text.RestoreConfirm') || '');
        if(result !== true) return;

        dispatch(SetLoading(true));
        setStatusText('');

        try {
            await AxiosClient.post(`/logs/recycle-bin/restore/${log.id}`);
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReload());
        onCloseModal();
    }
     
    if(!content) return null;
    let newContent = IsJsonString(content) ? <pre>{JSON.stringify(JSON.parse(content), null, 2)}</pre> : content;
    
    return (
        <Modal isOpen={show} onRequestClose={onCloseModal} style={StyleLarge} contentLabel="Modal: LogDetail">
            <div className="md:px-5 md:py-4 px-3 py-4">
                <div className='flex justify-between items-center mb-5'>
                    <p className="text-left text-2xl mb-0"><i className="fa-solid fa-scroll"></i> { t('Shared.Fields.Detail') }</p>
                    <span onClick={onClickClose} className='text-2xl hover:cursor-pointer'><h3><i className="far fa-times-circle"></i></h3></span>
                </div>
                <p className='break-all max-h-[50vh] overflow-y-auto'>{ newContent }</p>
                { (log && log.restorable && !log.restored) && <div className='flex flex-col md:flex-row justify-between items-center'>
                    <p className='mb-0'>{ statusText }</p>
                    <button onClick={onClickRestore} className="btn btn-aurora block">{ t('Shared.Actions.Restore') }</button>
                </div>
                }
            </div>
        </Modal>
    )
}

export default React.memo(ItemDetailModal);