import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { AppRoutes } from 'RoutesConfig';
import { useAppSelector } from 'store/hooks';
import { MakeTextLang } from 'utils/string';
import { OptimizePhotoUrl } from 'utils/file';
import { EPhotoVariants } from 'types/File';


function Topbar() {
    const { t } = useTranslation();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();


    const user = useAppSelector((state) => state.user.user)

    var pathName = location.pathname || 'unknown-path';
    pathName = pathName.replaceAll(/(?:[A-Za-z0-9\-\_]*\-)?\d+/gi, ":id");

    // @ts-ignore
    const route = AppRoutes.hasOwnProperty(pathName) ? AppRoutes[pathName] : null; 

    useEffect(() => {
        window.addEventListener("scroll", onPageScroll, { passive: true });
        window.addEventListener("click", onWindowClick, { passive: true });
  
        return () => { 
            window.removeEventListener("scroll", onPageScroll); 
            window.removeEventListener("click", onWindowClick);
        }
    }, []);

    var renderBack = <></>;

    if(route && route.back) {
        switch(route.back.to) {
            case "$back": renderBack = <Link to={searchParams.get('back') || '/content/shows'} className='btn btn-aurora btn-sm md:ml-6 mr-3 order-1 md:order-2'><i className="fa-solid fa-circle-left"></i> { t('Shared.Actions.Back') }</Link>; break;
            default: renderBack = <Link to={route.back.to} className='btn btn-aurora btn-sm md:ml-6 mr-3 order-1 md:order-2'><i className="fa-solid fa-circle-left"></i> { t('Shared.Actions.Back') }</Link>;
        }
    }

    return (
        <nav id='main-topbar' className="relative flex flex-wrap items-center justify-between px-0 py-2 mx-3 md:mx-6 transition-all shadow-none duration-250 ease-soft-in rounded-2xl lg:flex-nowrap lg:justify-start">
            <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
                <div className='flex flex-row justify-content-start items-center'>
                    { route && <>
                    <nav className='order-2 md:order-1'>
                        <ol className="flex flex-wrap pt-1 bg-transparent rounded-lg">
                            <li className="leading-normal text-sm">
                                <Link to="/" className="opacity-50 text-slate-700">{ t('App.Title') }</Link>
                            </li>
                            { (route.prePaths && route.prePaths.length > 0) &&
                                route.prePaths.map((p: any, i: number) => <li key={i} className="text-sm pl-2 capitalize leading-normal text-slate-700 before:float-left before:pr-2 before:text-gray-600 before:content-['/']" aria-current="page">{ MakeTextLang(t, p) }</li>)
                            }                        
                        </ol>    
                        <h6 className="mb-0 font-bold capitalize">{ MakeTextLang(t, route.title) }</h6>
                    </nav>
                    { renderBack }
                    </>}
                </div>
                <div className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
                    <div className="flex items-center md:ml-auto">
                        { user &&
                        <div className='bg-manija-5 rounded-md hidden xl:flex items-center justify-start px-3 py-2'>
                            <button type="button" className="rounded-full border border-2 mr-2" id="user-menu-button" aria-expanded="false">
                                <img className="w-6 h-6 rounded-full" src={OptimizePhotoUrl(user.avatar, EPhotoVariants.Small)} alt="user photo"/>
                            </button>
                            <p className='mb-0 text-slate-700'>{ user.nickname }</p>
                        </div>
                        }
                    </div>
                    <ul className="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full">
                        <li className="flex items-center pl-4 xl:hidden">
                            <a id="sidenav-trigger" onClick={onClickSidebarTrigger} data-force="auto" href="#" className="block p-0 transition-all ease-nav-brand text-sm text-slate-500">
                                <div className="w-4.5 overflow-hidden">
                                <i className="ease-soft mb-0.75 relative block h-0.5 rounded-sm bg-slate-500 transition-all"></i>
                                <i className="ease-soft mb-0.75 relative block h-0.5 rounded-sm bg-slate-500 transition-all"></i>
                                <i className="ease-soft relative block h-0.5 rounded-sm bg-slate-500 transition-all"></i>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

const onClickSidebarTrigger = (e: any) => {
    e.preventDefault();

    var sidebar = document.querySelector("#main-sidebar");
    var sidebarCloseButton = document.querySelector("#btn-close-sidebar");
    var sidebarTrigger: HTMLAnchorElement | null = document.querySelector("#sidenav-trigger");

    if(!sidebar || !sidebarCloseButton || !sidebarTrigger) return;

    var burger = sidebarTrigger.firstElementChild;
    var topBread = burger?.firstElementChild;
    var bottomBread = burger?.lastElementChild;

    var force: string = sidebarTrigger.dataset.force || "auto";

    switch(force) {
        case "open":
            sidebarCloseButton.classList.remove("hidden");
            sidebar.classList.add("translate-x-0");
            sidebar.classList.add("shadow-soft-xl");
        
            if(topBread) topBread.classList.add("translate-x-[5px]");
            if(bottomBread) bottomBread.classList.add("translate-x-[5px]");
            break;
        case "close":
            sidebarCloseButton.classList.add("hidden");
            sidebar.classList.remove("translate-x-0");
            sidebar.classList.remove("shadow-soft-xl");
        
            if(topBread) topBread.classList.remove("translate-x-[5px]");
            if(bottomBread) bottomBread.classList.remove("translate-x-[5px]");
            break;
        default:
            sidebarCloseButton.classList.toggle("hidden");
            sidebar.classList.toggle("translate-x-0");
            sidebar.classList.toggle("shadow-soft-xl");
        
            if(topBread) topBread.classList.toggle("translate-x-[5px]");
            if(bottomBread) bottomBread.classList.toggle("translate-x-[5px]");
    }

    if(sidebarTrigger.dataset.force != "auto") sidebarTrigger.dataset.force = "auto";
}

const onPageScroll = () => {
    var topbar = document.querySelector("#main-topbar");
    if(!topbar) return;

    if (window.scrollY >= 5) {
        topbar.classList.add("sticky", "top-[1%]", "backdrop-saturate-[200%]", "backdrop-blur-[30px]", "bg-[hsla(0,0%,100%,0.8)]", "shadow-blur", "z-110");
    } else {
        topbar.classList.remove("sticky", "top-[1%]", "backdrop-saturate-[200%]", "backdrop-blur-[30px]", "bg-[hsla(0,0%,100%,0.8)]", "shadow-blur", "z-110");
    }
}

const onWindowClick = (e: any) => {
    var sidebav = document.querySelector("#main-sidebar");
    var sidebavTrigger = document.querySelector("#sidenav-trigger");
    
    if(!sidebav || !sidebavTrigger) return;

    if (!sidebav.contains(e.target) && !sidebavTrigger.contains(e.target)) {
        if (sidebav.classList.contains("translate-x-0")) {
            // @ts-ignore
            sidebavTrigger.click();
        }
    }
}

export default React.memo(Topbar);