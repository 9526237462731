import { AxiosError, AxiosResponse } from 'axios';
import Pagination from 'components/Pagination';
import { usePagination } from 'hooks/page';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ShowModalCreateEpisode, ShowModalEditEpisode } from 'store/show.page.slice';

import { ShowItem } from 'types/ShowItem';
import { AxiosClient } from 'utils/axios';

interface Props {
    key: number;
    data: ShowItem;
    onClickEditSeason: any;
}

function Season(props: Props) {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { data: season } = props;
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageStart, pageLimit, pageNumber, GoToPage, ChangePageSize] = usePagination(true);
    const [episodes, setEpisodes] = useState<ShowItem[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const forceReload = useAppSelector((state) => state.pageShow.listing.forceReloadSeasons.hasOwnProperty(season.id) ? state.pageShow.listing.forceReloadSeasons[season.id] : 0);

    const isOpen = (searchParams.get('expand') || -1) == season.id ? true : false;
    
    useEffect(() => {
        if(!isOpen) return; 

        setLoading(true)
        AxiosClient.get(`/show-items/seasons/${season.id}/episodes`, { 
            params: {
                sort: "id:desc",
                paginationStart: pageStart,
                paginationLimit: pageLimit
            }
        }).then((res: AxiosResponse) => {
            setEpisodes(res.data.data);
            setTotalItems(res.data.meta.pagination.total);
            setLoading(false);
        }).catch((err: AxiosError) => {
            setLoading(false);
        })
    }, [season.id, forceReload, pageNumber, pageLimit, isOpen]);

    const onClickAddEpisode = useCallback((season: ShowItem) => {
        dispatch(ShowModalCreateEpisode({ show: true, seasonId: season.id }));
    }, [season.id]);
    
    const onClickEditEpisode = useCallback((episode: ShowItem) => {
        dispatch(ShowModalEditEpisode({ show: true, episode, seasonId: season.id }));
    }, [season.id]);
    
    const onClickExpand = () => {
        if(isOpen) {
            searchParams.delete('expand');
            setSearchParams(searchParams);
            return;
        }

        searchParams.set('expand', `${season.id}`);
        setSearchParams(searchParams);
    }

    return (
        <div className="mb-5 p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className='flex flex-row justify-between items-center'>
                <h5 className='mb-0'><span onClick={onClickExpand} className='mr-2 hover:cursor-pointer'><i className={`fa-solid ${isOpen ? 'fa-circle-caret-down' : 'fa-circle-caret-up'}`}></i></span>{ !season.hidden && <span className='badge badge-green'>{ t('Shared.Fields.Public') }</span> }{ t('Shared.Fields.Season') }: { season.originalTitle } <small className='text-sm'>({ t('Shared.Fields.Order') }: { season.sortOrder })</small></h5>
                <button onClick={props.onClickEditSeason.bind(null, season)} className='btn btn-aurora btn-sm'><i className="fa-solid fa-pen-to-square mr-2"></i>{ t('Shared.Actions.Edit') }</button>
            </div>
            { isOpen && <>
                <div className='mt-5 flex flex-row items-center justify-start'>
                    <h6>{ t('Shared.Fields.EpisodeList') } ({ totalItems })</h6>
                    { loading && <div className='scale-50'><div className="lds-roller scale-75 translate-x-6 -translate-y-10" style={{ height: 0, width: 0 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div> }
                </div>
                <div className="p-0 overflow-x-auto mb-3">
                    <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                        <thead className="align-bottom">
                            <tr>
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.ID') }</th>
                                <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Title') }</th>
                                { /* <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Tracks') }</th> */}
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Order') }</th>
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Visibility') }</th>
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Created') }</th>
                                <th className="px-6 py-3 font-semibold capitalize align-middle border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap"><button onClick={onClickAddEpisode.bind(null, season)} className='btn btn-aurora btn-sm'><i className="fa-solid fa-circle-plus mr-2"></i>{ t('Shared.Actions.AddEpisode') }</button></th>
                            </tr>
                        </thead>
                        <tbody>
                            { episodes.length > 0 ? 
                                episodes.map((s: ShowItem) => {
                                    return (
                                        <tr key={s.id}>
                                            <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <p className="mb-0 font-semibold leading-tight text-xs text-center">{ s.id }</p>
                                            </td>
                                            <td className="px-6 py-2 pl-0 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <h6 className="mb-0 leading-normal text-sm">{ s.originalTitle }</h6>
                                            </td>
                                            { /* <td className="px-6 py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <span className="mb-0 font-semibold leading-tight text-xs text-slate-400 text-center">0</span>
                                            </td>  */}
                                            <td className="px-6 py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <span className="mb-0 font-semibold leading-tight text-xs text-slate-400 text-center">{ s.sortOrder }</span>
                                            </td>
                                            <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <p className="mb-0 font-semibold leading-tight text-xs text-center">{ !s.hidden ? <span className='text-green-600'><i className="fa-solid fa-badge-check"></i></span> :  <span className='text-red-600'><i className="fa-sharp fa-solid fa-circle-xmark"></i></span> }</p>
                                            </td>
                                            <td className="px-6 py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <span className="mb-0 font-semibold leading-tight text-xs text-slate-400 text-center">{ s.createdAt ? new Date(s.createdAt).toLocaleString() : '-'  }</span>
                                            </td>
                                            <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <Link to={`/content/shows/tracks/${s.id}?back=${location.pathname}${location.search}`} className="font-semibold leading-tight text-slate-400 mr-4"><i className="fa-regular fa-compact-disc"></i></Link>
                                                <button onClick={onClickEditEpisode.bind(null, s)} className="font-semibold leading-tight text-slate-400"><i className="fa-solid fa-pen-to-square"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <>
                                <tr>
                                    <td colSpan={6}><p className='text-center mt-5'>{ t('Shared.Text.ThereAreNoItems') }</p></td>
                                </tr>
                            </>
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination page={pageNumber} size={pageLimit} total={totalItems} onGoToPage={!loading ? GoToPage : null} onChangePageSize={!loading ? ChangePageSize : null}/>
            </> }
        </div>
    )
}

export default React.memo(Season);