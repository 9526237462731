import { EPhotoVariants, FilePhoto, EPhotoFallbackType } from "types/File";
import placeholderPortraitSquare from '../assets/img/placeholders/portrait_square.jpg';

export function OptimizePhotoUrl(filePhoto: FilePhoto | null | undefined, priorityType: EPhotoVariants, fallbackType: EPhotoFallbackType = EPhotoFallbackType.SquarePortrait): string {
    if(!filePhoto) {
        switch(fallbackType) {
            // SquarePortrait
            default: return placeholderPortraitSquare;
        }
    }

    var variantTypes = Object.values(EPhotoVariants);
    var startIndex: number = variantTypes.findIndex((t: string) => t == priorityType);

    if(startIndex != -1) 
        for(let i = startIndex; i < variantTypes.length; i++) {
            if(filePhoto.variants[variantTypes[i]])
                // @ts-ignore
                return MakePhotoUrl(filePhoto.variants[variantTypes[i]].fileName);
        }

    return MakePhotoUrl(filePhoto.fileName);
}

export function MakePhotoUrl(fileName: string): string {
    return `${process.env.REACT_APP_API_URL}/public/photos/${fileName}`;
}
