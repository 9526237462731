import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'
import { EDragTypes } from 'types/Shared'

export interface Props {
    id: any
    text: string
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
    index: number
    id: string
    type: string
}

export function DragCard({ id, text, index, moveCard }: Props) {
    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: EDragTypes.Card,
        collect(monitor) {
            return { handlerId: monitor.getHandlerId() }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current)
                return

            const dragIndex = item.index
            const hoverIndex = index
      
            if (dragIndex === hoverIndex) // Don't replace items with themselves
                return

            const hoverBoundingRect = ref.current?.getBoundingClientRect() // Determine rectangle on screen
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2 // Get vertical middle
            const clientOffset = monitor.getClientOffset() // Determine mouse position
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top // Get pixels to the top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) // Dragging downwards
                return
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) // Dragging upwards
                return

            moveCard(dragIndex, hoverIndex) // Time to actually perform the action

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: EDragTypes.Card,
        item: () => { return { id, index } },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
        <div ref={ref} style={{ opacity }} className="mb-3 px-4 py-3 hover:cursor-pointer active:cursor-pointer bg-white border-0 border-transparent border-solid shadow-soft-xl bg-clip-border" data-handler-id={handlerId}>
            <i className="fa-solid fa-bars mr-3"></i> <span className='font-bold'>{text}</span>
        </div>
    )
}
