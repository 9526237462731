import React, { useEffect } from 'react';
import { redirect } from 'react-router-dom';
import { AppState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetInitState } from 'store/layout.slice';
import { AxiosClient } from 'utils/axios';

interface Props {
    initState: number;
}

function Initializing(props: Props) {
    const initState = useAppSelector((state: AppState) => state.layout.initState);
    const dispatch = useAppDispatch();

    let statusText: string | any;

    const onClickLogin = () => {
        AxiosClient.post('/auth/login/local', { identifier: "noirefield@gmail.com", password: "noirefield123@" }).then(({ data }) => { 
            dispatch(SetInitState(0));
        }).catch((err) => {console.log(err)});
    }

    switch(props.initState) {
        case -1: statusText = <span>You are unauthorized<br/><button onClick={onClickLogin} className='btn btn-aurora'>Login</button></span>; break;
        case 0: statusText = 'Authenticating...'; break;
        default: statusText = 'Unknown status';
    }

    return (
        <div className='fixed top-0 left-0 w-full h-full'>
            <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <div className='flex flex-col justify-center items-center'>
                    { props.initState >= 0 && <div className="lds-roller lds-roller-black mb-3"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> }
                    <p className='mb-0'>{ statusText }</p>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Initializing);
