import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';

import { useAppDispatch } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { AxiosClient, GetAxiosError } from 'utils/axios';

function FeaturedListAdd() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, control } = useForm();
    const [statusText, setStatusText] = useState<any>('');
   
    const onSubmit = async (data: any) => {
        dispatch(SetLoading(true));

        setStatusText(`${t('Shared.Text.Creating')}...`);
        try {
            await AxiosClient.post('/featured/lists', {
                title: data.title,
                autoplay: data.autoplay
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        navigate('/layout/featured');
    }

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[720px]">
                    <div className="p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <h5 className='mb-5'>{ t('Shared.Fields.FeaturedListInformation') }</h5>
                        <form role="form" onSubmit={handleSubmit(onSubmit)}>
                            <div className='grid grid-cols-1 gap-3 mb-3'>
                                <div className='col-span-1'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Title') } <span className='text-red-600'>*</span></label>
                                    <div>
                                        <input type="text" className="input input-textbox" placeholder="Title" { ...register("title", { required: true, minLength: 1, maxLength: 128 }) }/>
                                    </div>
                                </div>
                                <div className='col-span-1'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Autoplay') }</label>
                                    <div className='relative flex items-center cursor-pointer'>
                                        <Controller
                                            name="autoplay"
                                            control={control}
                                            defaultValue={true}
                                            render={({ field }) => (
                                                <>
                                                    <input type="checkbox" className="input input-checkbox sr-only peer" onChange={(value) => field.onChange(value)} checked={field.value}/>
                                                    <div onClick={() => { field.onChange(!field.value) }} className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row justify-between items-center'>
                                <p className='mb-0'>{ statusText }</p>
                                <button type="submit" className="btn btn-aurora block"><i className="fa-solid fa-circle-plus mr-2"></i>{ t('Shared.Actions.Create') }</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(FeaturedListAdd);
