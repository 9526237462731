
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Page, PageSection } from "types/Page";

export interface IShowModalCreateSectionPayload {
  show: boolean;
  pageId?: number;
}

export interface IShowModalEditSectionPayload {
  show: boolean;
  section?: PageSection;
}

export interface PageAndSectionPageState {
  listing: {
    forceReload: number;
  },
  modal: {
    createSection: {
      show: boolean;
      pageId: number;
    },
    editSection: {
      show: boolean;
      section: PageSection | null;
    }
  }
}

const initialState: PageAndSectionPageState = {
  listing: {
    forceReload: 0
  },
  modal: {
    createSection: {
      show: false,
      pageId: 0
    },
    editSection: {
      show: false,
      section: null
    }
  }
};

export const PageAndSectionPageSlice = createSlice({
  name: "pageAndSectionPage",
  initialState,
  reducers: {
    ListingForceReload(state) {
      state.listing.forceReload++;
    },
    ShowModalCreateSection(state, action: PayloadAction<IShowModalCreateSectionPayload>) {
      state.modal.createSection.show = action.payload.show;
      state.modal.createSection.pageId = action.payload.pageId || 0;
    },
    ShowModalEditSection(state, action: PayloadAction<IShowModalEditSectionPayload>) {
      state.modal.editSection.show = action.payload.show;
      state.modal.editSection.section = action.payload.section || null;
    }
  }
});

export const { 
  ListingForceReload, ShowModalCreateSection, ShowModalEditSection
} = PageAndSectionPageSlice.actions;

export default PageAndSectionPageSlice.reducer;