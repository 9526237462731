import axios, { AxiosError, AxiosResponse } from 'axios';
import i18n from "i18next";
import Cookie from 'js-cookie';

import { store } from 'store';
import { SetInitState } from 'store/layout.slice';
import { SetLogin, SetLogout } from 'store/user.slice';
import { User, UserRole } from 'types/User';

export const AxiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
})

AxiosClient.interceptors.response.use((response: AxiosResponse) => {
    // Success? Let it pass
    return response;
}, async (err: AxiosError) => { // Aight, hold on
    var shouldLogout: boolean = false;

    // @ts-ignore
    if(!err.config?.url?.startsWith('/auth/') && err.response?.data?.detail?.unauthorized) { // Missing token or token not found
        // Is there 'rft_expire_in' cookie?
        let rft_expire_in = Cookie.get('rft_expire_in');
        try {
            if(!rft_expire_in) throw new Error();
            let expireTime = new Date(Number(rft_expire_in));

            if(/*store.getState().user.loggedIn && */expireTime.getTime() > new Date().getTime()) { // Still have time left
                try {
                    await AxiosClient.post('/auth/refresh-access-token');
                    let res = await AxiosClient.get('/users/me');
                    let user: User = res.data;

                    if(user.roles.map((r: UserRole) => r.roleCode).indexOf('Administrator') !== -1) {
                        store.dispatch(SetLogin(res.data));
                    } else {
                        throw new Error();
                    }

                    if(err.config) return AxiosClient.request(err.config); // Retry the request after getting new access token
                } catch(err2: any) {
                    shouldLogout = true;
                }
            }
        } catch(e) {
            shouldLogout = true;
        }
    }

    if(shouldLogout) {
        AxiosClient.post('/auth/logout').then(() => {}).catch(() => { });

        store.dispatch(SetLogout());
        store.dispatch(SetInitState(0));
    }

    return Promise.reject(err);
});

export function GetAxiosError(err: AxiosError | any): string {
    if(err.response?.data) {
        if(err.response.data.langCode)
            if(typeof err.response.data.langCode == 'string')
                return i18n.t(err.response.data.langCode);
        if(err.response.data.message) {
            if(Array.isArray(err.response.data.message)) return err.response.data.message[0];
            else return err.response.data.message;
        }
    }

    if(err.message) return err.message;
    return 'Unknown error';
}