import { TFunction } from "i18next";

export enum EIMDBType {
    Name = "Name",
    Title = "Title"
}

export function GenerateLabelCode(label: string): string {
    return label.replace(/\W+/gi, "");
}

export function MakeTextLang(t: TFunction, text: string): string {
    if(t && typeof text == 'string' && text.startsWith('Lang:'))
        return t(text.slice(5));

    return text;
}

export function GetIDFromSlugID(text: string | undefined): number | null {
    if(typeof text != 'string') return null;

    let regexp = new RegExp(/\-(\d+)$/, "gi");
    let matches = regexp.exec(text);

    if(matches?.length != 2) return null;
    return Number(matches[1]);
}

export function IsJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function GenerateIMDBLinkFromID(id: string, type: EIMDBType) {
    switch(type) {
        case EIMDBType.Name: return `https://www.imdb.com/name/${id}`;
        case EIMDBType.Title: return `https://www.imdb.com/title/${id}`;
        default: return '';
    }
}