import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';

import Pagination from 'components/Pagination';
import LogDetailModal from './Modals/LogDetail.modal';

import { usePagination } from 'hooks/page';
import { useAppDispatch } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { Log } from 'types/Log';
import { User } from 'types/User';
import { AxiosClient } from 'utils/axios';
import { MakeTextLang } from 'utils/string';
import { ShowModalLogDetail } from 'store/log.page.slice';

function LogListing() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [pageStart, pageLimit, pageNumber, GoToPage, ChangePageSize] = usePagination();
    const [searchParams, setSearchParams] = useSearchParams();

    const refAuthorSelect = useRef<any>();
    const refTypeSelect = useRef<any>();

    const [items, setItems] = useState<Log[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);

    const typeFilterOptions: any[] = [
        { value: 'All', label: MakeTextLang(t, 'Lang:Pages.System.Log.Common.TypeFilterOptions.All') },
        { value: 'Admin', label: MakeTextLang(t, 'Lang:Pages.System.Log.Common.TypeFilterOptions.Admin') },
        { value: 'User', label: MakeTextLang(t, 'Lang:Pages.System.Log.Common.TypeFilterOptions.User') },
        { value: 'System', label: MakeTextLang(t, 'Lang:Pages.System.Log.Common.TypeFilterOptions.System') }
    ]

    const onLoadUsers = (inputValue: string, callback: (options: any[]) => void) => {
        AxiosClient.get('/users', { params: inputValue.length > 0 ? { q: inputValue, sort: 'id:desc' } : { sort: 'id:asc' } }).then((res: AxiosResponse) => {
            let authors = res.data.data?.map((u: User) => ({ label: u.nickname, value: u.id }));
            authors.unshift({ value: 'All', label: MakeTextLang(t, 'Lang:Pages.System.Log.Common.AuthorFilterOptions.All') });

            callback(authors);
        }).catch((err: AxiosError) => {
            callback([]);
        });
    };

    useEffect(() => {
        dispatch(SetLoading(true));

        let filterType = searchParams.get('type') ?? undefined;
        let filterAuthorId = searchParams.get('authorId') ?? undefined;

        let logParams = {
            type: filterType,
            authorId: filterAuthorId,
            sort: "createdAt:desc",
            paginationStart: pageStart,
            paginationLimit: pageLimit,
        };
        
        AxiosClient.get('/logs', { 
            params: logParams
        }).then((res: AxiosResponse) => {
            setItems(res.data.data);
            setTotalItems(res.data.meta.pagination.total);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
        })
    }, [dispatch, pageLimit, pageStart, pageNumber, searchParams]);

    const onClickFilter = () => {
        if(!refAuthorSelect?.current || !refTypeSelect?.current)
            return;

        let type = refTypeSelect.current.getValue();
        let author = refAuthorSelect.current.getValue();

        if(type[0].value !== "All") searchParams.set('type', type[0].value);
        else searchParams.delete("type");
        if(author[0].value !== "All") searchParams.set('authorId', author[0].value);
        else searchParams.delete("authorId");
        
        setSearchParams(searchParams.toString());
    }

    const onViewDetail = (log: Log) => {
        dispatch(SetLoading(true));

        AxiosClient.get(`/logs/${log.id}`).then((res: AxiosResponse) => {
            dispatch(ShowModalLogDetail({ show: true, content: res.data.detail }));
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
        })
    }

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6 mx-auto">
            <div className="flex flex-wrap">
                <div className="flex-none w-full max-w-full">
                    <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <div className='flex flex-col md:flex-row justify-between items-center p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent'>
                            <h6 className='mb-3 md:mb-0'>{ t('Shared.Fields.Logs') }</h6>
                            <div className='flex flex-col md:flex-row'>
                                <div className='flex flex-row justify-center items-center'>
                                    <AsyncSelect ref={refAuthorSelect} className='mb-2 md:mb-0 mr-2 input input-select2 md:min-w-[200px] max-w-[50%]' placeholder={t('Shared.Fields.Author')} cacheOptions loadOptions={onLoadUsers} defaultOptions defaultValue={{ value: 'All', label: MakeTextLang(t, 'Lang:Pages.System.Log.Common.AuthorFilterOptions.All') }}/>
                                    <Select ref={refTypeSelect} className='mb-2 md:mb-0 mr-2 input input-select2 md:min-w-[200px] max-w-[50%]' options={typeFilterOptions} defaultValue={typeFilterOptions[0]}/>
                                </div>
                                <button onClick={onClickFilter} className='mb-2 md:mb-0 btn btn-aurora'><i className="fa-solid fa-filter"></i> { t('Shared.Actions.ApplyFilters') }</button>
                            </div>
                        </div>
                        <div className="flex-auto px-0 pt-0 pb-2">
                            <div className="p-0 overflow-x-auto">
                                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                                    <thead className="align-bottom">
                                        <tr>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Created') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Type') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Author') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Module') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Action') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Identifier') }</th>
                                            <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items.length > 0 ? 
                                            items.map((l: Log) => 
                                                <tr key={l.id}>
                                                    <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <span className="font-semibold leading-tight text-xs text-slate-400">{ l.createdAt ? new Date(l.createdAt).toLocaleString() : '-'  }</span>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs">{ l.type }</p>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        { l.author ? <h6 className="mb-0 leading-normal text-xs">(#{l.author.id}) { l.author.nickname }</h6> : '-' }
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs">{ l.module }</p>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs">{ l.action }</p>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs max-w-2xl overflow-hidden">{ l.identifier }</p>
                                                    </td>
                                                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <span onClick={onViewDetail.bind(null, l)} className="font-semibold leading-tight text-slate-400 hover:text-slate-300 hover:cursor-pointer"><i className="fa-solid fa-circle-info"></i></span>
                                                    </td>
                                                </tr>
                                            )
                                        :
                                        <>
                                            <tr>
                                                <td colSpan={7}><p className='text-center mt-5'>{ t('Shared.Text.ThereAreNoItems') }</p></td>
                                            </tr>
                                        </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Pagination page={pageNumber} size={pageLimit} total={totalItems} onGoToPage={GoToPage} onChangePageSize={ChangePageSize}/>
                </div>
            </div>
            <LogDetailModal/>
        </div>
    );
}

export default React.memo(LogListing);
