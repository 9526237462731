import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { usePagination } from 'hooks/page';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';
import { ShowModalCommentDetail } from 'store/comment.page.slice';

import Pagination from 'components/Pagination';
import CommentDetailModal from './modals/CommentDetail.modal';

import { EPhotoVariants } from 'types/File';
import { Comment, ECommentStatus, ECommentTargetType } from 'types/Comment';

import { AxiosClient } from 'utils/axios';
import { OptimizePhotoUrl } from 'utils/file';

function CommentListing() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const refSearchText = useRef<any>(null);
    const refStatusSelect = useRef<any>(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const [pageStart, pageLimit, pageNumber, GoToPage, ChangePageSize] = usePagination();

    const [items, setItems] = useState<Comment[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [pendingCount, setPendingCount] = useState<number>(0);

    const forceReload = useAppSelector((state) => state.pageComment.listing.forceReload);

    const statusOptions: any[] = []; 

    statusOptions.push({ value: -1, label: t(`Shared.CommentStatus.-1`) });
    for(let item in ECommentStatus) { if(!isNaN(Number(item))) statusOptions.push({ value: item, label: t(`Shared.CommentStatus.${item}`) }); }

    useEffect(() => {
        dispatch(SetLoading(true));

        let filterStatus = searchParams.get('status') ?? undefined;

        AxiosClient.get('/comments', { 
            params: {
                ...(searchParams.has('q') ? { q: searchParams.get('q') } : {}),
                status: filterStatus,
                withEntity: true,
                sort: "id:desc",
                paginationStart: pageStart,
                paginationLimit: pageLimit
            }
        }).then((res: AxiosResponse) => {
            setItems(res.data.data);
            setTotalItems(res.data.meta.pagination.total);
            setPendingCount(res.data.meta?.listing?.pendingCount || 0)
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
        })
    }, [pageNumber, searchParams, forceReload]);

    const onClickFilter = () => {
        if(!refSearchText?.current || !refStatusSelect?.current) return;

        let status = refStatusSelect.current.getValue();

        if(status[0].value !== -1) searchParams.set('status', status[0].value);
        else searchParams.delete("status");

        let searchText = refSearchText.current.value || '';

        if(searchText.length > 0) searchParams.set('q', searchText);
        else searchParams.delete("q");
        
        setSearchParams(searchParams.toString());
    }

    const onClickViewDetail = (comment: Comment) => {
        dispatch(ShowModalCommentDetail({ show: true, comment }));
    }

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6 mx-auto">
            <div className="flex flex-wrap">
                <div className="flex-none w-full max-w-full">
                    <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <div className='flex flex-col md:flex-row justify-start items-start md:justify-between md:items-center p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent'>
                            <h6 className='mb-4 md:mb-0'>{ t('Shared.Fields.Comments') } { pendingCount > 0 && <span className='badge badge-yellow ml-2'>{ t('Shared.Fields.Pending') }: { pendingCount }</span> }</h6>
                            <div className='flex flex-col md:flex-row md:justify-start justify-center md:items-center w-full md:w-2/5'>
                                <div className='flex flex-row justify-center items-center mb-2 md:mb-0 mr-2 flex-2'>
                                    <input ref={refSearchText} type="text" className="input input-textbox mr-2" placeholder={ t('Shared.Text.SearchByContent') || ''}/>
                                    <Select ref={refStatusSelect} className='input input-select2 min-w-[50%] md:min-w-[160px] max-w-[50%]' options={statusOptions} defaultValue={statusOptions[0]}/>
                                </div>
                                <button onClick={onClickFilter} className='mb-0 md:mb-0 btn btn-aurora btn-keep-size flex-1'><i className="fa-solid fa-filter"></i> { t('Shared.Actions.ApplyFilters') }</button>
                            </div>
                        </div>
                        <div className="flex-auto px-0 pt-0 pb-2">
                            <div className="p-0 overflow-x-auto">
                                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                                    <thead className="align-bottom">
                                        <tr>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.ID') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Comment') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Author') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Target') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Status') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Created') }</th>
                                            <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items.length > 0 ? 
                                            items.map((c: Comment) => {
                                                let targetTitle: string = '-';
                                                let renderStatus = <>-</>;

                                                switch(c.targetType) {
                                                    case ECommentTargetType.ShowItem: targetTitle = c.targetEntity?.originalTitle || ''; break;
                                                    default: break;
                                                }

                                                switch(c.status) {
                                                    case ECommentStatus.Hidden: renderStatus = <span className='badge badge-red'>{ t(`Shared.CommentStatus.${c.status}`) }</span>; break;
                                                    case ECommentStatus.Pending: renderStatus = <span className='badge badge-yellow'>{ t(`Shared.CommentStatus.${c.status}`) }</span>; break;
                                                    case ECommentStatus.Visible: renderStatus = <span className='badge badge-green'>{ t(`Shared.CommentStatus.${c.status}`) }</span>; break;
                                                    default: renderStatus = <>?</>; break;
                                                }

                                                return (
                                                    <tr key={c.id}>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 font-semibold leading-tight text-xs text-center">{ c.id }</p>
                                                        </td>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <div className="w-full max-w-[600px] overflow-auto break-normal py-1">
                                                                <p className="mb-0 font-semibold leading-tight text-xs py-3">{ c.content }</p>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-2 pl-0 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        { c.author ? 
                                                            <div className="flex px-2 py-1">
                                                                <div><img src={OptimizePhotoUrl(c.author.avatar, EPhotoVariants.Small)} className="inline-flex items-center justify-center mr-4 text-white transition-all duration-200 ease-soft-in-out text-sm h-9 w-9 object-cover rounded-xl" alt="User Picture" /></div>
                                                                <div className="flex flex-col justify-center">
                                                                    <h6 className="mb-0 leading-normal text-sm">{ c.author.nickname }</h6>
                                                                    <p className="mb-0 leading-tight text-xs text-slate-400">({ t('Shared.Fields.ID')}: { c.author.id })</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <p className="mb-0 font-semibold leading-tight text-xs">-</p>
                                                        }
                                                        </td>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 leading-tight text-xs text-slate-400">{ t(`Shared.CommentTargetTypes.${c.targetType}`) }</p>
                                                            <p className="mb-0 font-semibold leading-tight text-xs">{ targetTitle }</p>
                                                        </td>
                                                        <td className="px-6 py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <span className="mb-0 font-semibold leading-tight text-xs text-center">{ renderStatus }</span>
                                                        </td>
                                                        <td className="px-6 py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <span className="mb-0 font-semibold leading-tight text-xs text-center">{ c.createdAt ? new Date(c.createdAt).toLocaleString() : '-'  }</span>
                                                        </td>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <button onClick={onClickViewDetail.bind(null, c)} className="font-semibold leading-tight text-slate-400"><i className="fa-solid fa-circle-info"></i></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        :
                                        <>
                                            <tr>
                                                <td colSpan={6}><p className='text-center mt-5'>{ t('Shared.Text.ThereAreNoItems') }</p></td>
                                            </tr>
                                        </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Pagination page={pageNumber} size={pageLimit} total={totalItems} onGoToPage={GoToPage} onChangePageSize={ChangePageSize}/>
                    <CommentDetailModal/>
                </div>
            </div>
        </div>
    );
}

export default React.memo(CommentListing);
