import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

import { EShowItemTrackContentType, EShowItemTrackProvider } from 'types/ShowItem';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';
import { ListingForceReloadTracks, ShowModalCreateTrack } from 'store/show.page.slice';

import { StyleCompact } from 'utils/modal';
import { AxiosClient, GetAxiosError } from 'utils/axios';

function CreateTrackModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { register, handleSubmit, control, watch, reset } = useForm();

    const providerOptions: any[] = []; for(let item in EShowItemTrackProvider) providerOptions.push({ value: item, label: t(`Shared.ShowItemTrackProviders.${item}`) });
    const contentTypeOptions: any[] = []; for(let item in EShowItemTrackContentType) contentTypeOptions.push({ value: item, label: t(`Shared.ShowItemTrackContentTypes.${item}`) });

    const provider = watch('provider', providerOptions[0].value);
    const contentType = watch('contentType', contentTypeOptions[0].value);

    const show = useAppSelector((state) => state.pageShow.modal.createTrack.show)
    const showItemId = useAppSelector((state) => state.pageShow.modal.createTrack.showItemId);
    
    const [statusText, setStatusText] = useState<any>('');

    const onCloseModal = () => {
        dispatch(ShowModalCreateTrack({ show: false }));
        reset()
    }

    const onClickClose = (e: any) => {
        e.preventDefault();
        onCloseModal();
    }

    const onSubmit = async (data: any) => {
        if(!show || !showItemId) return;

        dispatch(SetLoading(true));
        setStatusText('');

        try {
            await AxiosClient.post(`/show-items/tracks`, {
                showItemId: showItemId,
                provider: provider,
                url: data.url,
                contentType: contentType,
                title: data.title,
                sortOrder: data.sortOrder
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        dispatch(ListingForceReloadTracks());
        
        onCloseModal();
    }
     
    if(!showItemId) return null;
    
    return (
        <Modal isOpen={show} onRequestClose={onCloseModal} style={StyleCompact} contentLabel="Modal: CreateShowItemTrack">
            <div className="px-6 py-3">
                <div className='flex justify-between items-center mb-5'>
                    <h5 className='mb-0'>{ t('Pages.Content.Show.ListTrack.Modals.CreateTrack.Title') }</h5>
                    <span onClick={onClickClose} className='text-2xl hover:cursor-pointer'><h3><i className="far fa-times-circle"></i></h3></span>
                </div>
                <form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-3'>
                        <div className='mb-2 flex justify-content-start items-center'>
                            <label className="ml-1 font-bold text-xs text-slate-700 mr-3">{ t('Shared.Fields.Title') } <span className='text-red-600'>*</span></label>
                        </div>
                        <div>
                            <input type="text" className="input input-textbox" placeholder=''  { ...register("title", { required: true, minLength: 1, maxLength: 128  }) }/>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mb-3'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Provider') }</label>
                            <div>
                                <Controller
                                    name="provider"
                                    defaultValue={providerOptions[0].value}
                                    control={control}
                                    render={({ field }) => (
                                        <Select isClearable={false} className='input input-select2' options={providerOptions} value={providerOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                    )}
                                />
                            </div>
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Url') } <span className='text-red-600'>*</span></label>
                            <div>
                                <input type="text" className="input input-textbox" placeholder={'http://'}  { ...register("url", { required: true, minLength: 1, maxLength: 255  }) }/>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mb-10'>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.ContentType') }</label>
                            <div>
                            <Controller
                                name="contentType"
                                defaultValue={contentTypeOptions[0].value}
                                control={control}
                                render={({ field }) => (
                                    <Select isClearable={false} styles={{ menu: (base) => ({ ...base, top: -50 }) }} className='input input-select2' options={contentTypeOptions} value={contentTypeOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                )}
                            />
                            </div>
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.SortOrder') } </label>
                            <div>
                                <input type="number" className="input input-textbox" placeholder="Sort Order" { ...register("sortOrder", { min: 0 }) } defaultValue={0}/>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between items-center mb-3'>
                        <p className='mb-0'>{ statusText }</p>
                        <button type="submit" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-circle-plus mr-2"></i>{ t('Shared.Actions.Create') }</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default React.memo(CreateTrackModal);