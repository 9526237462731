export interface FilePhoto extends FilePhotoAttributes {
    uid?: string;
    variants: {
        optimized?: FilePhotoAttributes;
        small?: FilePhotoAttributes;
        medium?: FilePhotoAttributes;
        large?: FilePhotoAttributes;
        verylarge?: FilePhotoAttributes;
    }
}

export interface FilePhotoAttributes {
    fileName: string;
    width: number;
    height: number;
    size: number;
}

export enum EPhotoVariants { // ORDER MATTERS! (Small -> Large)
    Small = "small",
    Medium = "medium",
    Large = "large",
    VeryLarge = "verylarge",
    Optimized = "optimized"
}

export enum EPhotoFallbackType {
    SquarePortrait = "SquarePortrait"
}