import React from 'react';
import { Link, Location, NavLink, useLocation } from "react-router-dom";

import Logo from 'assets/img/aurora.png';
import { useTranslation } from 'react-i18next';

function Sidebar() {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <aside id="main-sidebar" className="max-w-62.5 ease-nav-brand z-990 fixed inset-y-0 my-4 block w-full -translate-x-full flex-wrap items-center justify-between overflow-y-auto rounded-2xl border-0 bg-white p-0 antialiased shadow-none transition-transform duration-200 xl:left-0 xl:translate-x-0 xl:bg-transparent">
            <div className="h-19.5">
                <i onClick={OnClickToggleSidebar} id="btn-close-sidebar" className="absolute top-0 right-0 hidden p-4 opacity-50 cursor-pointer fas fa-times text-slate-400 xl:hidden"></i>
                <Link to="/" className="block px-8 py-6 m-0 text-sm whitespace-nowrap text-slate-700">
                    <img src={Logo} className="inline h-full max-w-full transition-all duration-200 ease-nav-brand max-h-8" alt="Main Logo" />
                </Link>
            </div>

            <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />

            <div className="items-center block w-auto max-h-screen overflow-auto h-sidenav grow basis-full">
                <ul className="flex flex-col pl-0 mb-0">
                    { GenerateLink(location, '/home', t('Pages.Home.Title'), <i className="fa-solid fa-house"></i>) }

                    <li className="w-full mt-4"><h6 className="pl-6 ml-2 font-bold leading-tight uppercase text-xs opacity-60">{ t("Pages.Layout.Title") }</h6></li>
                    { GenerateLink(location, '/layout/pages',  t('Pages.Layout.PageAndSection.Title'), <i className="fa-solid fa-layer-group"></i>) }
                    { GenerateLink(location, '/layout/featured', t('Pages.Layout.Featured.Title'), <i className="fa-regular fa-gallery-thumbnails"></i>) }
                  
                    <li className="w-full mt-4"><h6 className="pl-6 ml-2 font-bold leading-tight uppercase text-xs opacity-60">{ t("Pages.Content.Title") }</h6></li>
                    { GenerateLink(location, '/content/people', t('Pages.Content.People.Title'), <i className="fa-sharp fa-solid fa-user-group-crown"></i>) }
                    { GenerateLink(location, '/content/categories', t('Pages.Content.Category.Title'), <i className="fa-solid fa-folder-open"></i>) }
                    { GenerateLink(location, '/content/shows', 'Shows', <i className="fa-solid fa-film"></i>) }

                    <li className="w-full mt-4"><h6 className="pl-6 ml-2 font-bold leading-tight uppercase text-xs opacity-60">{ t("Pages.Community.Title") }</h6></li>
                    { GenerateLink(location, '/community/users', t('Pages.Community.User.Title'), <i className="fa-solid fa-user-group"></i>) }
                    { GenerateLink(location, '/community/comments', t('Pages.Community.Comment.Title'), <i className="fa-sharp fa-solid fa-comments"></i>) }

                    <li className="w-full mt-4"><h6 className="pl-6 ml-2 font-bold leading-tight uppercase text-xs opacity-60">{ t("Pages.System.Title") }</h6></li>
                    { GenerateLink(location, '/system/logs', t('Pages.System.Log.Title'), <i className="fa-sharp fa-regular fa-book"></i>) }
                    { GenerateLink(location, '/system/recycle-bin', t('Pages.System.RecycleBin.Title'), <i className="fa-solid fa-recycle"></i>) }
                </ul>
            </div>

            <div className="mx-4">
                {/* 
                <p className="invisible hidden text-gray-800 text-red-500 text-red-600 after:bg-gradient-to-tl after:from-gray-900 after:to-slate-800 after:from-blue-600 after:to-cyan-400 after:from-red-500 after:to-yellow-400 after:from-green-600 after:to-lime-400 after:from-red-600 after:to-rose-400 after:from-slate-600 after:to-slate-300 text-lime-500 text-cyan-500 text-slate-400 text-fuchsia-500"></p>
                <div className="after:opacity-65 after:bg-gradient-to-tl after:from-slate-600 after:to-slate-300 relative flex min-w-0 flex-col items-center break-words rounded-2xl border-0 border-solid border-blue-900 bg-white bg-clip-border shadow-none after:absolute after:top-0 after:bottom-0 after:left-0 after:z-10 after:block after:h-full after:w-full after:rounded-2xl after:content-['']" sidenav-card="true">
                    <div className="mb-7.5 absolute h-full w-full rounded-2xl bg-cover bg-center" style={{ backgroundImage: "url('/aurora/img/curved-images/white-curved.jpeg')"}}></div>
                    <div className="relative z-20 flex-auto w-full p-4 text-left text-white">
                    <div className="flex items-center justify-center w-8 h-8 mb-4 text-center bg-white bg-center rounded-lg icon shadow-soft-2xl">
                        <i className="top-0 z-10 text-transparent ni leading-none ni-diamond text-lg bg-gradient-to-tl from-slate-600 to-slate-300 bg-clip-text opacity-80" sidenav-card-icon="true"></i>
                    </div>
                    <div className="transition-all duration-200 ease-nav-brand">
                        <h6 className="mb-0 text-white">Need a feature?</h6>
                        <p className="mt-0 mb-4 font-semibold leading-tight text-xs">Let's make a request</p>
                        <a href="#" target="_blank" className="inline-block w-full px-8 py-2 mb-0 font-bold text-center text-black uppercase transition-all ease-in bg-white border-0 border-white rounded-lg shadow-soft-md bg-150 leading-pro text-xs hover:shadow-soft-2xl hover:scale-102">Request Feature</a>
                    </div>
                    </div>
                </div>*/}
                <a className="inline-block w-full px-6 py-3 my-4 font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-purple-700 to-pink-500 hover:shadow-soft-2xl hover:scale-102" target="_blank" href="https://manija.net">{ t('Shared.Actions.GoToManija') }</a>
            </div>
        </aside>
    );
}

function OnClickToggleSidebar() {
    let sidebarTrigger: HTMLDivElement | null = document.querySelector("#sidenav-trigger")
 
    if(sidebarTrigger) {
        sidebarTrigger.dataset.force = "close";
        sidebarTrigger.click();
    }
}

function GenerateLink(location: Location, path: string, title: string, icon: React.ReactElement) {
    let isActive = path != "/" ? location.pathname.startsWith(path) : location.pathname == path;

    return (
        <li onClick={OnClickToggleSidebar} className="mt-0.5 w-full">
            <NavLink to={path} className={isActive ? "py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap rounded-lg bg-white px-4 font-semibold text-slate-700 transition-colors" : "py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 transition-colors"}>
                <div className={ isActive? "bg-gradient-to-tl from-purple-700 to-pink-500 shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5 text-white" : "shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5" }>
                    { icon }
                </div>
                <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">{ title }</span>
            </NavLink>
        </li>
    )
}

export default React.memo(Sidebar);