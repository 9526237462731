import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { AxiosError, AxiosResponse } from 'axios';
import debounce from 'lodash.debounce';

import { useAppDispatch } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { User, UserRole } from 'types/User';
import { EPhotoVariants } from 'types/File';
import { Log } from 'types/Log';

import { AxiosClient, GetAxiosError } from 'utils/axios';
import { GetIDFromSlugID } from 'utils/string';
import { OptimizePhotoUrl } from 'utils/file';


function UserEdit() {
    const { slugId } = useParams();
    const id = GetIDFromSlugID(slugId);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const { t } = useTranslation();

    const { register, handleSubmit, control, setValue } = useForm();
    const refFilePhoto = useRef<HTMLInputElement>(null);
    const refRoles = useRef<any>(null);
    const refMainRole = useRef<any>(null);

    const [user, setUser] = useState<User|null>(null);
    const [selectedRoles, setSelectedRoles] = useState<any>([]);
    const [mainRoleOptions, setMainRoleOptions] = useState<any>([]);
    const [selectedMainRole, setSelectedMainRole] = useState<any>([]);
    const [roleFirstLoad, setRoleFirstLoad] = useState(false);
    const [recentActivity, setRecentActivity] = useState<Log[]>([]);

    const [statusText, setStatusText] = useState<any>('');
    const [photo, setPhoto] = useState<string|ArrayBuffer|null>(null);
    const [uploadedPhotoUID, setUploadedPhotoUID] = useState<string|null>(null);

    useEffect(() => { 
        if(!id) { 
            navigate('/community/users'); 
            return 
        };

        dispatch(SetLoading(true));
        AxiosClient.get(`/users/${id}`).then((res: AxiosResponse) => {
            let user: User = res.data;

            setUser(user);

            setValue('username', user.username);
            setValue('nickname', user.nickname);
            setValue('email', user.email);
            setValue('confirmed', user.confirmed);
            setValue('banLevel', user.banLevel);

            if(user.avatar) {
                // @ts-ignore
                setUploadedPhotoUID(user.avatar.uid);
                setPhoto(OptimizePhotoUrl(user.avatar, EPhotoVariants.Optimized));
            }

            // Fetch Logs
            let logParams = {
                authorId: user.id,
                sort: "createdAt:desc",
                paginationStart: 0,
                paginationLimit: 10
            };
            
            AxiosClient.get('/logs', { 
                params: logParams
            }).then((res: AxiosResponse) => {
                setRecentActivity(res.data.data);
                
                dispatch(SetLoading(false));
            }).catch((err: AxiosError) => {
                dispatch(SetLoading(false));
            })

        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
            navigate('/community/users'); 
        });
    }, [id]);
    if(!id || !user) return null;

    const onLoadRoles = debounce((inputValue: string, callback: (options: any[]) => void) => {
        AxiosClient.get('/users/roles', { params: inputValue.length > 0 ? { q: inputValue, sort: 'sortOrder:asc,id:asc' } : { sort: 'sortOrder:asc,id:asc' } }).then((res: AxiosResponse) => {
            let options = res.data.data?.map((r: UserRole) => ({ label: r.title, value: r.roleCode }));

            user.roles.forEach((r: UserRole) => {
                if(!options.find((r2: any) => r2.value == r.roleCode))
                    options.unshift({ label: r.title, value: r.roleCode })
            })

            callback(options);

            // @ts-ignore
            if(!roleFirstLoad && user.roles.length > 0) {
                let selectedRoles = user.roles.map((r: UserRole) => ({ label: r.title, value: r.roleCode }));
                setSelectedRoles(selectedRoles);
                
                setMainRoleOptions(selectedRoles);
                setSelectedMainRole(user.mainRole ? { label: user.mainRole.title, value: user.mainRole.roleCode } : null);

                setRoleFirstLoad(true);
            }
        }).catch((err: AxiosError) => {
            setStatusText(<span className='text-red-600'>{GetAxiosError(err)}</span>);
            callback([]);
        });
    }, 500);

    const onRolesChange = async (options: any) => {
        setSelectedRoles(options);
        setMainRoleOptions(options);

        if(selectedMainRole && !options.find((o: any) => o.value == selectedMainRole.value))
            setSelectedMainRole(null);
        else if(!selectedMainRole && options.length > 0) 
            setSelectedMainRole(options[0]);
    }

    const onMainRoleChange = async (options: any) => {
        setSelectedMainRole(options);
    }

    const onClickSelectPhoto = (e: any) => { refFilePhoto.current?.click(); }
    const onFilePhotoChange = (e: any) => {
        let reader = new FileReader();

        reader.onload = function() { 
            setPhoto(reader.result); 
            setUploadedPhotoUID(null);
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    const onSubmit = async (data: any) => {
        var roles = refRoles.current?.getValue();
        var mainRole = refMainRole.current?.getValue();
        if(!refFilePhoto.current || !roles || roles.length <= 0 || !mainRole || mainRole.length <= 0) return;

        dispatch(SetLoading(true));

        var photoUID = uploadedPhotoUID;
        if((refFilePhoto.current?.files?.length || 0) > 0 && !uploadedPhotoUID) {
            let formData = new FormData();
            // @ts-ignore
            formData.append('file', refFilePhoto.current?.files[0]);

            try {
                setStatusText(`${t('Shared.Text.Uploading')}...`);
                let uploadedImage = await AxiosClient.post('/files/private-upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    params: { type: 'Image' }
                });

                setUploadedPhotoUID(uploadedImage.data.uid);
                photoUID = uploadedImage.data.uid;
            } catch(e) {
                setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
                dispatch(SetLoading(false));
                return;
            }
        }

        setStatusText(`${t('Shared.Text.Updating')}...`);
        try {
            await AxiosClient.patch(`/users/${user.id}`, {
                nickname: data.nickname,
                email: data.email,
                ...(data.password ? { password: data.password } : { }),
                confirmed: data.confirmed,
                roleCodes: roles.map((r: any) => r.value),
                mainRoleCode: mainRole[0].value,
                avatarFileUId: photoUID,
                banLevel: data.banLevel
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        navigate('/community/users');
    }


    const banStatusOptions = [
        { value: 0, label: t('Shared.BanStatus.0') },
        { value: 1, label: t('Shared.BanStatus.1')  },
        { value: 2, label: t('Shared.BanStatus.2')  },
    ]

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[1024px] grid grid-cols-3 gap-4">
                    <div className='col-span-3 order-2 md:order-1 md:col-span-2'>
                        <div className="p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border mb-3 md:mb-5">
                            <h5 className='mb-5'>{ t('Shared.Fields.UserInformation') }</h5>
                            <form role="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className='grid grid-cols-6 gap-3 mb-3'>
                                    <div className='col-span-1'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.ID') }</label>
                                        <div>
                                            <input type="text" className="input input-textbox input-disabled" placeholder="ID" defaultValue={user.id} disabled={true}/>
                                        </div>
                                    </div>
                                    <div className='col-span-5'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Username') }</label>
                                        <div>
                                            <input type="text" className="input input-textbox input-disabled" placeholder="Username" { ...register("username", { required: true, minLength: 1, maxLength: 128 }) } disabled={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-2 gap-3 mb-3'>
                                    <div className='col-span-2 md:col-span-1'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Nickname') } <span className='text-red-600'>*</span></label>
                                        <div>
                                            <input type="text" className="input input-textbox" placeholder="Nickname" { ...register("nickname", { required: true, minLength: 1, maxLength: 24 }) }/>
                                        </div>
                                    </div>
                                    <div className='col-span-2 md:col-span-1'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Email') } <span className='text-red-600'>*</span></label>
                                        <div>
                                            <input type="email" className="input input-textbox" placeholder="Email" { ...register("email", { required: true, minLength: 1, maxLength: 120 }) }/>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-4 gap-3 mb-3'>
                                    <div className='col-span-4 md:col-span-2'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Password') }</label>
                                        <div>
                                            <input type="password" className="input input-textbox" placeholder="Leave empty to keep current password" { ...register("password", { maxLength: 240 }) } defaultValue={""}/>
                                        </div>
                                    </div>
                                    <div className='col-span-2 md:col-span-1'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Banned') }</label>
                                        <div>
                                            <Controller
                                                name="banLevel"
                                                defaultValue={banStatusOptions[0].value}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select isClearable={false} className='input input-select2' options={banStatusOptions} value={banStatusOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-span-2 md:col-span-1 mb-3'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Confirmed') }</label>
                                        <div className='relative flex items-center cursor-pointer'>
                                            <Controller
                                                name="confirmed"
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <>
                                                        <input type="checkbox" className="input input-checkbox sr-only peer" onChange={(value) => field.onChange(value)} checked={field.value}/>
                                                        <div onClick={() => { field.onChange(!field.value) }} className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-6 gap-3 mb-5'>
                                    <div className='col-span-6 md:col-span-4'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Roles') } <span className='text-red-600'>*</span></label>
                                        <div>
                                            <AsyncSelect isMulti ref={refRoles} className='input input-select2' cacheOptions loadOptions={onLoadRoles} defaultOptions value={selectedRoles} onChange={onRolesChange}/>
                                        </div>
                                    </div>
                                    <div className='col-span-6 md:col-span-2'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.PrimaryRole') } <span className='text-red-600'>*</span></label>
                                        <div>
                                            <Select ref={refMainRole} className='input input-select2' options={mainRoleOptions} value={selectedMainRole} onChange={onMainRoleChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col md:flex-row justify-between items-center'>
                                    <p className='mb-0'>{ statusText }</p>
                                    <button type="submit" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-pen-nib mr-2"></i>{ t('Shared.Actions.Update') }</button>
                                </div>
                            </form>
                        </div>
                        <div className="p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                            <h5 className='mb-5'>{ t('Shared.Fields.RecentActivity') }</h5>
                            <div className="p-0 overflow-x-auto mb-3">
                                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                                    <thead className="align-bottom">
                                        <tr>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Created') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Type') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Module') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Action') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Identifier') }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { recentActivity.length > 0 ? 
                                            recentActivity.map((l: Log) => 
                                                <tr key={l.id}>
                                                    <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <span className="font-semibold leading-tight text-xs text-slate-400">{ l.createdAt ? new Date(l.createdAt).toLocaleString() : '-'  }</span>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs">{ l.type }</p>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs">{ l.module }</p>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs">{ l.action }</p>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs max-w-2xl overflow-hidden">{ l.identifier }</p>
                                                    </td>
                                                </tr>
                                            )
                                        :
                                        <>
                                            <tr>
                                                <td colSpan={5}><p className='text-center mt-5'>{ t('Shared.Text.ThereAreNoItems') }</p></td>
                                            </tr>
                                        </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <p className='mb-2 text-slate-400 text-sm'><i className="fa-sharp fa-solid fa-circle-info mr-1"></i>{ t('Shared.Text.MoreDetailsCheckLogs') }</p>
                        </div>
                    </div>
                    <div className='col-span-3 order-1 md:order-2 md:col-span-1'>
                        <div onClick={onClickSelectPhoto} className={`image-select image-select-avatar ${photo ? '' : 'image-select-none'} shadow-lg hover:cursor-pointer`}>
                            { !photo && <div className='text-center'>
                                <p className='text-5xl mb-2'><i className="fa-solid fa-image-portrait"></i></p>
                                <p>{ t('Shared.Fields.PortraitPhoto')}</p>
                            </div> }
                            { photo && <img src={photo.toString()}/> }
                        </div>
                        <input ref={refFilePhoto} onChange={onFilePhotoChange} type="file" accept='image/png, image/jpeg' className="hidden" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(UserEdit);
