import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { AxiosError, AxiosResponse } from 'axios';
import debounce from 'lodash.debounce';

import { useAppDispatch } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { Category } from 'types/Category';
import { EShowItemSubType, EShowItemType, EShowItemVideoQualityType, ShowItem, ECoverType } from 'types/ShowItem';
import { EPhotoVariants } from 'types/File';
import { Person } from 'types/Person';

import { AxiosClient, GetAxiosError } from 'utils/axios';
import { NormalizeYoutubeUrl } from 'utils/regex';
import { EIMDBType, GenerateIMDBLinkFromID, GetIDFromSlugID } from 'utils/string';
import { OptimizePhotoUrl } from 'utils/file';
import { ShowItemRawFormat } from 'utils/formatter';
import { supportedLangs } from 'utils/validator';
import { LanguageMeta } from 'types/Shared';
import ContentLanguageSelector from 'components/ContentLanguageSelector';

function ShowEditSeriesMovie() {
    const { slugId } = useParams();
    const id = GetIDFromSlugID(slugId);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, control, watch, setValue, getValues } = useForm();
    const hidden = watch('hidden', false);
    const coverType = watch('coverType', false);
    
    const refFileThumbnail = useRef<HTMLInputElement>(null);
    const refCategories = useRef<any>(null);
    const refAudioLangs = useRef<any>(null);
    const refSubtitleLangs = useRef<any>(null);
    const refPeople = useRef<any>(null);

    const [show, setShow] = useState<ShowItem|null>(null);
    const [statusText, setStatusText] = useState<any>('');
    const [selectedCategories, setSelectedCategories] = useState<any>([]);
    const [selectedAudioLangs, setSelectedAudioLangs] = useState<any>([]);
    const [selectedSubtitleLangs, setSelectedSubtitleLangs] = useState<any>([]);
    const [categoryFirstLoad, setCategoryFirstLoad] = useState<boolean>(false);
    const [selectedPeople, setSelectedPeople] = useState<any>([]);
    const [peopleFirstLoad, setPeopleFirstLoad] = useState<boolean>(false);

    const [thumbnail, setThumbnail] = useState<string|ArrayBuffer|null>(null);
    const [uploadedThumbnailUID, setUploadedThumbnailUID] = useState<string|null>(null);
    const [langMeta, setLangMeta] = useState<LanguageMeta>({ editing: [], reset: 0 });

    useEffect(() => { 
        if(!id) { 
            navigate('/content/shows'); 
            return 
        };

        dispatch(SetLoading(true));
        AxiosClient.get(`/show-items/series-movie/${id}`).then((res: AxiosResponse) => {
            let show: ShowItem = ShowItemRawFormat(res.data);
            
            setShow(show);

            setValue('type', show.type);
            setValue('originalTitle', show.originalTitle);
            setValue('description', show.description);
            setValue('releaseYear', show.releaseYear);
            setValue('endYear', show.endYear);
            setValue('trailerUrl', show.trailerUrl);

            setValue('coverType', show.coverType);

            switch(show.coverType) {
                case ECoverType.StaticImage: break;
                case ECoverType.YoutubeVideo: setValue('youtubeUrl', show.coverData?.url); break;
                default: break;
            }

            setValue('hidden', !show.hidden ? 0 : (show.hiddenUntil ? 2 : 1));
            if(show.hidden && show.hiddenUntil) setValue('hiddenUntil', new Date(show.hiddenUntil));

            if(show.thumbnail) {
                // @ts-ignore
                setUploadedThumbnailUID(show.thumbnail.uid);
                setThumbnail(OptimizePhotoUrl(show.thumbnail, EPhotoVariants.Optimized));
            }

            let selectedAudioLangs = show.audioLangs.map((l: string) => ({ label: t(`Shared.Langs.${l}`), value: l }));
            setSelectedAudioLangs(selectedAudioLangs);

            let selectedSubtitleLangs = show.subtitleLangs.map((l: string) => ({ label: t(`Shared.Langs.${l}`), value: l }));
            setSelectedSubtitleLangs(selectedSubtitleLangs);

            if(show.imdbId) setValue('imdbUrl', GenerateIMDBLinkFromID(show.imdbId, EIMDBType.Title));
            if(show.videoQualityType) setValue('videoQualityType', show.videoQualityType);
            if(show.langMeta) setLangMeta({ ...show.langMeta, reset: -1, editing: [] })

            setValue('premium', show.premium);

            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
            navigate('/content/shows'); 
        });
    }, [id]);
    if(!id || !show) return null;

    const onLoadCategories = debounce((inputValue: string, callback: (options: any[]) => void) => {
        AxiosClient.get('/categories', { params: inputValue.length > 0 ? { q: inputValue, sort: 'sortOrder:asc,id:asc' } : { sort: 'sortOrder:asc,id:asc' } }).then((res: AxiosResponse) => {
            let options = res.data.data?.map((r: Category) => ({ label: r.title, value: r.id }));

            show.categories.forEach((c: Category) => {
                if(!options.find((c2: any) => c2.value == c.id))
                    options.unshift({ label: c.title, value: c.id })
            })
    
            callback(options);

            // @ts-ignore
            if(!categoryFirstLoad && show.categories.length > 0) {
                let selectedCategories = show.categories.map((c: Category) => ({ label: c.title, value: c.id }));
                setSelectedCategories(selectedCategories);
                
                setCategoryFirstLoad(true);
            }
        }).catch((err: AxiosError) => {
            setStatusText(<span className='text-red-600'>{GetAxiosError(err)}</span>);
            callback([]);
        });
    }, 500);

    const onLoadPeople = debounce((inputValue: string, callback: (options: any[]) => void) => {
        AxiosClient.get('/people', { params: inputValue.length > 0 ? { q: inputValue, sort: 'sortOrder:asc,id:asc' } : { sort: 'sortOrder:asc,id:asc' } }).then((res: AxiosResponse) => {
            let options = res.data.data?.map((r: Person) => ({ label: r.name, value: r.id }));

            show.people.forEach((c: Person) => {
                if(!options.find((c2: any) => c2.value == c.id))
                    options.unshift({ label: c.name, value: c.id })
            })
    
            callback(options);

            // @ts-ignore
            if(!peopleFirstLoad && show.people.length > 0) {
                let selectedPeople = show.people.map((p: Person) => ({ label: p.name, value: p.id }));
                setSelectedPeople(selectedPeople);
                
                setPeopleFirstLoad(true);
            }
        }).catch((err: AxiosError) => {
            setStatusText(<span className='text-red-600'>{GetAxiosError(err)}</span>);
            callback([]);
        });
    }, 500);

    const onChangeCategories = async (options: any) => { setSelectedCategories(options); }
    const onChangeAudioLangs = async (options: any) => { setSelectedAudioLangs(options); }
    const onChangeSubtitleLangs = async (options: any) => { setSelectedSubtitleLangs(options); }
    const onChangePeople = async (options: any) => { setSelectedPeople(options); }

    const onClickSelectThumbnail = (e: any) => { refFileThumbnail.current?.click(); }
    const onFileThumbnailChange = (e: any) => {
        let reader = new FileReader();

        reader.onload = function() { 
            setThumbnail(reader.result); 
            setUploadedThumbnailUID(null);
        };

        reader.readAsDataURL(e.target.files[0]);
    }

    const onSubmit = async (data: any) => {
        var selectedCategories = refCategories.current?.getValue();
        var selectedAudioLangs = refAudioLangs.current?.getValue();
        var selectedSubtitleLangs = refSubtitleLangs.current?.getValue();
        var selectedPeople = refPeople.current?.getValue();

        if(!thumbnail || !refFileThumbnail.current || !selectedCategories || selectedCategories.length <= 0) return;

        if(langMeta.editing.length > 0) {
            setLangMeta({ ...langMeta, reset: langMeta.reset + 1 });
            setTimeout(() => { (document.querySelector('#btn-update') as HTMLButtonElement).click(); }, 1);
            return;
        }

        dispatch(SetLoading(true));

        var thumbnailUId = uploadedThumbnailUID;
        if(!uploadedThumbnailUID) {
            let formData = new FormData();
            // @ts-ignore
            formData.append('file', refFileThumbnail.current?.files[0]);

            try {
                setStatusText(`${t('Shared.Text.Uploading')}...`);
                let uploadedImage = await AxiosClient.post('/files/private-upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    params: { type: 'Image' }
                });

                setUploadedThumbnailUID(uploadedImage.data.uid);
                thumbnailUId = uploadedImage.data.uid;
            } catch(e) {
                setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
                dispatch(SetLoading(false));
                return;
            }
        }

        setStatusText(`${t('Shared.Text.Updating')}...`);
        try {
            var coverData = {};
            var realHidden = false;
            var hiddenUntil = null;

            switch(coverType) {
                case ECoverType.StaticImage: coverData = { uid: thumbnailUId }; break;
                case ECoverType.YoutubeVideo: 
                    let youtubeUrl = NormalizeYoutubeUrl(data.youtubeUrl || '');
                    if(!youtubeUrl) {
                        setStatusText(<span className='text-red-600'>{ t('Shared.Errors.InvalidYoutubeUrl') }</span>);
                        dispatch(SetLoading(false));
                        return;   
                    }

                    coverData = { url: youtubeUrl }
                    break;
                default: break;
            }

            switch(hidden) {
                case 0: realHidden = false; break;
                case 1: realHidden = true; hiddenUntil = null; break;
                case 2: {
                    realHidden = true; 
                    hiddenUntil = data.hiddenUntil;

                    if(!hiddenUntil) {
                        setStatusText(<span className='text-red-600'>{ t('Shared.Errors.InvalidHiddenUntil') }</span>);
                        dispatch(SetLoading(false));
                        return;   
                    }

                    break;
                }
                default: break;
            }
    
            await AxiosClient.patch(`/show-items/series-movie/${show.id}`, {
                type: data.type,
                subType: data.type == EShowItemType.Series ? EShowItemSubType.MultiSeasonSeries : null,
                originalTitle: data.originalTitle,
                description: data.description,
                thumbnailFileUId: thumbnailUId,
                coverType,
                coverData,
                releaseYear: data.releaseYear,
                endYear: data.endYear,
                categoryIds: selectedCategories.map((c: any) => c.value),
                audioLangs: selectedAudioLangs.map((l: any) => l.value),
                subtitleLangs: selectedSubtitleLangs.map((l: any) => l.value),
                peopleIds: selectedPeople.map((p: any) => p.value),
                hidden: realHidden,
                hiddenUntil,
                imdbUrl: data.imdbUrl,
                videoQualityType: data.videoQualityType || null,
                langMeta,
                trailerUrl: data.trailerUrl,
                premium: data.premium
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        navigate('/content/shows');
    }

    const onClickDelete = async () => {
        let result = window.confirm(t('Shared.Text.DeleteConfirm') || '');
        if(result !== true) return;

        dispatch(SetLoading(true));
        setStatusText(`${t('Shared.Text.Deleting')}...`);

        try {
            await AxiosClient.delete(`/show-items/series-movie/${show.id}`);
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        navigate('/content/shows');
    }

    const hiddenOptions = [
        { value: 0, label: t('Shared.Fields.Visible') },
        { value: 1, label: t('Shared.Fields.Hidden')  },
        { value: 2, label: t('Shared.Fields.HiddenUntil')  },
    ]

    const coverTypeOptions: any[] = []; for(let item in ECoverType) coverTypeOptions.push({ value: item, label: t(`Shared.ShowItemCoverTypes.${item}`) });
    const videoQualityTypeOptions: any[] = []; for(let item in EShowItemVideoQualityType) videoQualityTypeOptions.push({ value: EShowItemVideoQualityType[item as keyof typeof EShowItemVideoQualityType], label: EShowItemVideoQualityType[item as keyof typeof EShowItemVideoQualityType] });

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[1024px] grid grid-cols-3 gap-4">
                    <div className='col-span-3 md:col-span-1'>
                        <div className='mb-5'>
                            <div onClick={onClickSelectThumbnail} className={`image-select image-select-landscape ${thumbnail ? '' : 'image-select-none'} shadow-lg hover:cursor-pointer`}>
                                { !thumbnail && <div className='text-center'>
                                    <p className='text-5xl mb-2'><i className="fa-solid fa-image-landscape"></i></p>
                                    <p>{ t('Shared.Fields.Thumbnail')} <span className='text-red-600'>*</span></p>
                                </div> }
                                { thumbnail && <img src={thumbnail.toString()}/> }
                            </div>
                            <input ref={refFileThumbnail} onChange={onFileThumbnailChange} type="file" accept='image/png, image/jpeg' className="hidden"/>
                        </div>
                        <div className="mb-5 p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                            <h5 className='mb-3'>{ t('Shared.Fields.Cover') }</h5>
                            <div className='mb-3'>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.CoverType') } <span className='text-red-600'>*</span></label>
                                <div>
                                <Controller
                                    name="coverType"
                                    defaultValue={coverTypeOptions[0].value}
                                    control={control}
                                    render={({ field }) => (
                                        <Select isClearable={false} className='input input-select2' options={coverTypeOptions} value={coverTypeOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                    )}
                                />
                                </div>
                            </div>
                            { coverType == 'YoutubeVideo' && <div className='mb-3'>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.YoutubeUrl') }</label>
                                <div>
                                    <input type="text" className="input input-textbox" placeholder="Youtube url" { ...register("youtubeUrl", { required: true, minLength: 1, maxLength: 250 }) }/>
                                </div>
                            </div> }
                        </div>
                        <div className="mb-5 p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                            <h5 className='mb-3'>{ t('Shared.Fields.Visibility') }</h5>
                            <div className='mb-0'>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Visibility') } <span className='text-red-600'>*</span></label>
                                <div>
                                <Controller
                                    name="hidden"
                                    defaultValue={hiddenOptions[0].value}
                                    control={control}
                                    render={({ field }) => (
                                        <Select isClearable={false} className='input input-select2' options={hiddenOptions} value={hiddenOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                    )}
                                />
                                </div>
                            </div>
                            { hidden == 2 && <div>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.HiddenUntil') } <span className='text-red-600'>*</span></label>
                                <div>
                                <Controller
                                    name="hiddenUntil"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            className='input input-textbox'
                                            placeholderText='Select date'
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                        />
                                    )}
                                />
                                </div>
                            </div> }
                        </div>
                        <div className="mb-1 p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <h5 className='mb-3'>{ t('Shared.Fields.ExtraInfo') }</h5>
                            <div className='mb-3'>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.VideoQualityType') }</label>
                                <div>
                                <Controller
                                    name="videoQualityType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select styles={{ menu: (base) => ({ ...base, top: -50 }) }} className='input input-select2' options={videoQualityTypeOptions} value={videoQualityTypeOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                    )}
                                />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.IMDBUrl') }</label>
                                <input type="text" className="input input-textbox col-span-8 md:col-span-9" placeholder="IMDB Url" { ...register("imdbUrl", { maxLength: 255 }) }/>
                            </div>
                            <div className='mb-3'>
                                <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.TrailerUrl') }</label>
                                <input type="text" className="input input-textbox col-span-8 md:col-span-9" placeholder="Trailer Url" { ...register("trailerUrl", { maxLength: 255 }) }/>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-3 md:col-span-2'>
                        <div className="p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                            <h5 className='mb-5'>{ t('Shared.Fields.ShowInformation') }</h5>
                            <form role="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className='mb-4 flex justify-between items-center flex-row'>
                                    <div className="flex justify-start items-center">
                                        <label className="ml-1 mr-5 font-bold text-xs text-slate-700">{ t('Shared.Fields.Type') }</label>
                                        <ul className="grid gap-3 grid-cols-2">
                                            <li>
                                                <input {...register("type") } type="radio" id="show-type-series" value="Series" className="hidden peer"/>
                                                <label htmlFor="show-type-series" className="inline-flex items-center justify-between px-4 py-1 text-slate-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-slate-600 peer-checked:text-slate-900 hover:text-slate-800 hover:bg-gray-100">                           
                                                    <div className="block">
                                                        <div className="text-md font-semibold">{ t('Shared.Fields.Series') }</div>
                                                    </div>
                                                </label>
                                            </li>
                                            <li>
                                                <input {...register("type") } type="radio" id="show-type-movie" value="Movie" className="hidden peer"/>
                                                <label htmlFor="show-type-movie" className="inline-flex items-center justify-between px-4 py-1 text-slate-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-slate-600 peer-checked:text-slate-900 hover:text-slate-800 hover:bg-gray-100">
                                                    <div className="block">
                                                        <div className="text-md font-semibold">{ t('Shared.Fields.Movie') }</div>
                                                    </div>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div className="flex items-center">
                                            <input { ...register("premium") } id="checkbox-premium-content" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            <label htmlFor="checkbox-premium-content"className="ml-1 text-sm font-medium text-yellow-700"><div className="text-md font-semibold">{ t('Shared.Fields.PremiumContent') }</div></label>
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='mb-2 flex justify-content-start items-center'>
                                        <label className="ml-1 font-bold text-xs text-slate-700 mr-3">{ t('Shared.Fields.OriginalTitle') } <span className='text-red-600'>*</span></label>
                                        <ContentLanguageSelector defaultLang={null} field='originalTitle' langMeta={langMeta} setLangMeta={setLangMeta} setValue={(value: string) => { setValue('originalTitle', value) }} getValue={getValues}/>
                                    </div>
                                    <div>
                                        <input type="text" className="input input-textbox" placeholder="Original Title" { ...register("originalTitle", { required: true, minLength: 1, maxLength: 250 }) }/>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Categories') } <span className='text-red-600'>*</span></label>
                                    <div>
                                        <AsyncSelect isMulti ref={refCategories} className='input input-select2' cacheOptions loadOptions={onLoadCategories} defaultOptions value={selectedCategories} onChange={onChangeCategories}/>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='mb-2 flex justify-content-start items-center'>
                                        <label className="ml-1 font-bold text-xs text-slate-700 mr-3">{ t('Shared.Fields.Description') }</label>
                                    </div>
                                    <ContentLanguageSelector defaultLang={'eng'} field='description' langMeta={langMeta} setLangMeta={setLangMeta} setValue={(value: string) => { setValue('description', value) }} getValue={getValues}/>
                                    <div className='mt-2'>
                                        <textarea className="input input-textbox" placeholder="Description" { ...register("description", { maxLength: 4096 }) }/>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.AudioLangs') }</label>
                                    <div>
                                        <Select isMulti ref={refAudioLangs} className='input input-select2' options={supportedLangs.map((l: string) => ({ label: t(`Shared.Langs.${l}`), value: l }))} value={selectedAudioLangs} onChange={onChangeAudioLangs}/>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.SubtitleLangs') }</label>
                                    <div>
                                        <Select isMulti ref={refSubtitleLangs} className='input input-select2' options={supportedLangs.map((l: string) => ({ label: t(`Shared.Langs.${l}`), value: l }))} value={selectedSubtitleLangs} onChange={onChangeSubtitleLangs}/>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.People') }</label>
                                    <div>
                                        <AsyncSelect isMulti ref={refPeople} className='input input-select2' cacheOptions loadOptions={onLoadPeople} defaultOptions value={selectedPeople} onChange={onChangePeople}/>
                                    </div>
                                </div>
                                <div className='grid grid-cols-2 gap-3 mb-5'>
                                    <div className='col-span-1'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.ReleaseYear') }</label>
                                        <div>
                                            <input type="number" className="input input-textbox" placeholder="2020" { ...register("releaseYear") } />
                                        </div>
                                    </div>
                                    <div className='col-span-1'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.EndYear') }</label>
                                        <div>
                                            <input type="number" className="input input-textbox" placeholder="2022" { ...register("endYear") } />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col md:flex-row justify-between items-center'>
                                    <p className='mb-0'>{ statusText }</p>
                                    <div>
                                        <button type="button" onClick={onClickDelete} className="btn btn-red btn-keep-size block mr-2"><i className="fa-solid fa-trash mr-2"></i>{ t('Shared.Actions.Delete') }</button>
                                        <button type="submit" id="btn-update" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-pen-nib mr-2"></i>{ t('Shared.Actions.Update') }</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(ShowEditSeriesMovie);
