import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ShowModalLogDetail } from 'store/log.page.slice';

import { StyleLarge } from 'utils/modal';
import { IsJsonString } from 'utils/string';

function LogDetailModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const show = useAppSelector((state) => state.pageLog.modal.logDetail.show)
    const content = useAppSelector((state) => state.pageLog.modal.logDetail.content);

    const onCloseModal = () => {
        dispatch(ShowModalLogDetail({ show: false, content: null }));
    }

    const onClickClose = (e: any) => {
        e.preventDefault();
        onCloseModal();
    }
    
    if(!content) return null;

    let newContent = IsJsonString(content) ? <pre>{JSON.stringify(JSON.parse(content), null, 2)}</pre> : content;
    
    return (
        <Modal isOpen={show} onRequestClose={onCloseModal} style={StyleLarge} contentLabel="Modal: LogDetail">
            <div className="md:px-5 md:py-4 px-3 py-4">
                <div className='flex justify-between items-center mb-5'>
                    <p className="text-left text-2xl mb-0"><i className="fa-solid fa-scroll"></i> { t('Shared.Fields.Detail') }</p>
                    <a onClick={onClickClose} href="#" className='text-white text-2xl'><h3><i className="far fa-times-circle"></i></h3></a>
                </div>
                <p className='break-all max-h-[75vh] overflow-y-auto'>{ newContent }</p>
            </div>
        </Modal>
    )
}

export default React.memo(LogDetailModal);