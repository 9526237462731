import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

import Section from './components/Section';

import CreateSectionModal from './modals/CreateSection.modal';
import EditSectionModal from './modals/EditSection.modal';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { AxiosClient } from 'utils/axios';
import { GetIDFromSlugID } from 'utils/string';
import { EPageType, Page, PageSection } from 'types/Page';
import { ShowModalCreateSection, ShowModalEditSection } from 'store/page-and-section.page.slice';
import { DragCard } from 'components/DragCard';

function PageEditSection() {
    const { slugId } = useParams();
    const id = GetIDFromSlugID(slugId);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const forceReload = useAppSelector((state) => state.pageAndSectionPage.listing.forceReload);

    const [page, setPage] = useState<Page|null>(null);
    const [sections, setSections] = useState<PageSection[]>([]);
    
    const [sortingMode, setSortingMode] = useState<boolean>(false);
    const [sortingItems, setSortingItems] = useState<PageSection[]>([]);

    useEffect(() => { 
        if(!id) { 
            navigate('/layout/pages'); 
            return 
        };

        dispatch(SetLoading(true));
        AxiosClient.get(`/pages/${id}`).then((res: AxiosResponse) => {
            let page: Page = res.data;

            setPage(page);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
            navigate('/layout/pages'); 
        });

    }, [id]);

    useEffect(() => { 
        if(!page) return 

        dispatch(SetLoading(true));
        AxiosClient.get(`/pages/${id}/sections`, { params: { paginationLimit: 100, sort: 'sortOrder:asc,id:asc', withEntity: true } }).then((res: AxiosResponse) => {
            let sections: PageSection[] = res.data.data;

            setSections(sections);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            console.log(err);
            dispatch(SetLoading(false));
            navigate('/layout/pages'); 
        });
    }, [page, forceReload]);

    const onClickAddSection = () => {
        dispatch(ShowModalCreateSection({ show: true, pageId: page?.id }));
    }

    const onClickEditSection = useCallback((section: PageSection) => {
       dispatch(ShowModalEditSection({ show: true, section }));
    }, [page]);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setSortingItems((prevItems: PageSection[]) => 
            update(prevItems, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevItems[dragIndex] as PageSection],
                ],
            }),
        )
    }, [])

    const onClickSaveSorts = async () => {
        if(!sortingMode || sortingItems.length <= 0) return;

        dispatch(SetLoading(true));

        try {
            await AxiosClient.patch(`/pages/sections/sort-orders`, {
                orders: sortingItems.map((i, index) => ({ id: i.id, sortOrder: index + 1}))
            });

            setSections(sortingItems);
            setSortingMode(false);

            dispatch(SetLoading(false));
        } catch(e) {
            dispatch(SetLoading(false));
        }
    }

    if(!id || !page || page.type != EPageType.DynamicPage) return null;

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[750px]">
                    <div className='mb-4 flex justify-between items-center'>
                        <div className="flex items-center">
                            <input id="checkbox-sorting-mode" type="checkbox" checked={sortingMode} onChange={(event) => { setSortingItems(sections); setSortingMode(event.target.checked) }} className="input input-checkbox"/>
                            <label htmlFor="checkbox-sorting-mode" className="ml-2 text-sm font-medium text-gray-900">{ t('Shared.Fields.SortingMode') }</label>
                        </div>
                        { sortingMode && <button onClick={onClickSaveSorts} className='btn btn-aurora btn-sm'><i className="fa-solid fa-floppy-disk mr-1"></i>{ t('Shared.Actions.Save') }</button>}
                    </div>
                    { sortingMode ? 
                        <DndProvider backend={HTML5Backend}>
                            { sortingItems.length > 0 ?
                                sortingItems.map((i: PageSection, index: number) =>  <DragCard key={i.id} index={index} id={i.id} text={`(#${i.id}) ${i.title}`} moveCard={moveCard}/>)
                                :
                                <p className='mb-5 text-center'>{ t('Shared.Text.ThereAreNoItems') }</p>
                            }
                        </DndProvider>
                        :
                        <>
                        { sections.length > 0 ?
                            sections.map((s: PageSection) => <Section key={s.id} data={s} onClickEditSection={onClickEditSection}/>)
                            :
                            <p className='mb-5 text-center'>{ t('Shared.Text.ThereAreNoItems') }</p>
                        }
                        <div className='text-center'>
                            <button onClick={onClickAddSection} className='btn btn-aurora'><i className="fa-solid fa-circle-plus mr-2"></i>{ t('Shared.Actions.AddSection') }</button>
                        </div>
                        </>
                    }
                </div>
            </div>
            
            <CreateSectionModal/>
            <EditSectionModal/>
        </div>
    );
}

export default React.memo(PageEditSection);
