import React from 'react';
import { useTranslation } from 'react-i18next';
import { FeaturedItem } from 'types/Featured';

interface Props {
    key: number;
    data: FeaturedItem;
    onClickEditItem: any;
}

function CompactFeaturedItem(props: Props) {
    const { t } = useTranslation();
    const { data: item } = props;

    return (
        <div className="mb-5 p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className='mb-5 flex flex-row justify-between items-center'>
                <h5 className='mb-0'>{ t('Shared.Fields.Order') }: { item.sortOrder }</h5>
                <button onClick={props.onClickEditItem.bind(null, item)} className='btn btn-aurora btn-sm'><i className="fa-solid fa-pen-to-square mr-2"></i>{ t('Shared.Actions.Edit') }</button>
            </div>
            <h6>{ t('Shared.Fields.AssignmentConfig') }</h6>
            <div className='grid grid-cols-2 gap-3 mb-3'>
                <div className="py-2 px-3 md:px-5 bg-white border-0 border-transparent border-solid ring-2 ring-gray-100 shadow-soft-xl rounded-sm bg-clip-border leading-normal">
                    <span className='text-sm uppercase tracking-widest'>{ t('Shared.Fields.TargetType') }</span>
                    <p className='text-slate-600 font-bold mb-2 leading-none'>{ t(`Shared.FeaturedItemTargetTypes.${item.targetType}`) }</p>
                </div>
                <div className="py-2 px-3 md:px-5 bg-white border-0 border-transparent border-solid ring-2 ring-gray-100 shadow-soft-xl rounded-sm bg-clip-border leading-normal">
                    <span className='text-sm uppercase tracking-widest'>{ t('Shared.Fields.CoverType') }</span>
                    <p className='text-slate-600 font-bold mb-2 leading-none'>{ t(`Shared.FeaturedItemCoverTypes.${item.coverType}`) }</p>
                </div>
            </div>
            <div className="py-2 px-3 md:px-5 bg-white border-0 border-transparent border-solid ring-2 ring-gray-100 shadow-soft-xl rounded-sm bg-clip-border leading-normal">
                <span className='text-sm uppercase tracking-widest'>{ t('Shared.Fields.TargetID') }</span>
                <p className='text-slate-600 font-bold mb-2 leading-none'>(#{ item.targetId }) { t(`Shared.ShowItemTypes.${item.targetEntity?.type}`) }: { item.targetEntity?.originalTitle }</p>
            </div>
        </div>
    )
}

export default React.memo(CompactFeaturedItem);