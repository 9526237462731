import React, { useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import { usePagination } from 'hooks/page';
import { useAppDispatch } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import Pagination from 'components/Pagination';

import { User, UserRole } from 'types/User';
import { EPhotoVariants } from 'types/File';

import { AxiosClient } from 'utils/axios';
import { OptimizePhotoUrl } from 'utils/file';

function UserListing() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const refSearchText = useRef<any>(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const [pageStart, pageLimit, pageNumber, GoToPage, ChangePageSize] = usePagination();

    const [items, setItems] = useState<User[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);

    useEffect(() => {
        dispatch(SetLoading(true));
        AxiosClient.get('/users', { 
            params: {
                ...(searchParams.has('q') ? { q: searchParams.get('q') } : {}),
                sort: "id:desc",
                paginationStart: pageStart,
                paginationLimit: pageLimit
            }
        }).then((res: AxiosResponse) => {
            setItems(res.data.data);
            setTotalItems(res.data.meta.pagination.total);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
        })
    }, [pageNumber, searchParams]);

    const onClickFilter = () => {
        if(!refSearchText?.current) return;

        let searchText = refSearchText.current.value || '';

        if(searchText.length > 0) searchParams.set('q', searchText);
        else searchParams.delete("q");
        
        setSearchParams(searchParams.toString());
    }

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6 mx-auto">
            <div className="flex flex-wrap">
                <div className="flex-none w-full max-w-full">
                    <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <div className='flex flex-col md:flex-row justify-start items-start md:justify-between md:items-center p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent'>
                            <h6 className='mb-4 md:mb-0'>{ t('Shared.Fields.Users') }</h6>
                            <div className='flex flex-row justify-start items-center'>
                                <div className='flex flex-row justify-center items-center mr-2 flex-1'>
                                    <input ref={refSearchText} type="text" className="input input-textbox" placeholder={ t('Shared.Text.SearchUserPlaceholder') || ''}/>
                                </div>
                                <button onClick={onClickFilter} className='mb-0 md:mb-0 btn btn-aurora btn-keep-size flex-1'><i className="fa-solid fa-filter"></i> { t('Shared.Actions.ApplyFilters') }</button>
                            </div>
                        </div>
                        <div className="flex-auto px-0 pt-0 pb-2">
                            <div className="p-0 overflow-x-auto">
                                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                                    <thead className="align-bottom">
                                        <tr>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.ID') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.User') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Email') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Role') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.LevelNPoint') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Confirmed') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Registered') }</th>
                                            <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items.length > 0 ? 
                                            items.map((u: User) => 
                                                <tr key={u.id}>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs text-center">{ u.id }</p>
                                                    </td>
                                                    <td className="px-6 py-2 pl-0 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <div className="flex px-2 py-1">
                                                            <div><img src={OptimizePhotoUrl(u.avatar, EPhotoVariants.Small)} className="inline-flex items-center justify-center mr-4 text-white transition-all duration-200 ease-soft-in-out text-sm h-9 w-9 object-cover rounded-xl" alt="User Picture" /></div>
                                                            <div className="flex flex-col justify-center">
                                                                <h6 className="mb-0 leading-normal text-sm">{ u.nickname }</h6>
                                                                <p className="mb-0 leading-tight text-xs text-slate-400">{ u.username }</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs">{ u.email }</p>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs">{ u.mainRole?.title ?? '-' }</p>
                                                        { u.roles.length > 0 && <p className="mb-0 leading-tight text-xs text-slate-400">{ u.roles.map((r: UserRole) => r.title).join(', ') }</p> }
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs text-center">{ t('Shared.Fields.Lv') }.{ u.level } <span className="leading-tight text-xs text-slate-400">({ t('Shared.Fields.Point') }: { u.point })</span></p>
                                                    </td>
                                                    <td className="px-6 py-2 px-10 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs text-center">{ u.confirmed ? <span className='text-green-600'><i className="fa-solid fa-badge-check"></i></span> : '-' }</p>
                                                    </td>
                                                    <td className="px-6 py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <span className="mb-0 font-semibold leading-tight text-xs text-center">{ u.createdAt ? new Date(u.createdAt).toLocaleDateString() : '-'  }</span>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <Link to={`/community/users/${u.slug}-${u.id}`} className="font-semibold leading-tight text-slate-400"><i className="fa-solid fa-pen-to-square"></i></Link>
                                                    </td>
                                                </tr>
                                            )
                                        :
                                        <>
                                            <tr>
                                                <td colSpan={8}><p className='text-center mt-5'>{ t('Shared.Text.ThereAreNoItems') }</p></td>
                                            </tr>
                                        </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Pagination page={pageNumber} size={pageLimit} total={totalItems} onGoToPage={GoToPage} onChangePageSize={ChangePageSize}/>
                </div>
            </div>
        </div>
    );
}

export default React.memo(UserListing);
