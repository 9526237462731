import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

interface Props {
    page: number;
    size: number;
    total: number;
    onGoToPage: (to: number) => void;
    onChangePageSize: (size: number) => void;
}

const pageSizeOptions = [
    { value: 10, label: 'Shared.Pagination.ItemsPerPage' },
    { value: 25, label: 'Shared.Pagination.ItemsPerPage' },
    { value: 50, label: 'Shared.Pagination.ItemsPerPage' },
    { value: 100, label: 'Shared.Pagination.ItemsPerPage' }
]

function Pagination(props: Props) {
    const { t } = useTranslation();

    const onClickChange = (next: boolean) => {
        if(!props.onGoToPage) return;
        if(next) {
            let lastPage = Math.ceil(props.total / props.size);
            if(props.page < lastPage) props.onGoToPage(props.page + 1);
        } else {
            if(props.page > 1) props.onGoToPage(props.page - 1);
        }
    }

    let realPageSizeOptions = pageSizeOptions.map((o) => {
        o.label = t(o.label, { total: o.value });
        return o;
    });

    return (
        <div className="flex flex-row justify-between items-center">
            <span className="text-sm text-gray-700 dark:text-gray-400">
                { t('Shared.Pagination.Info', { from: ((props.page - 1) * props.size) + 1, to: props.page * props.size, total: props.total }) }
            </span>
            <div className='mt-2 flex flex-row items-center'>
                <Select className='input input-select2 mr-2' options={realPageSizeOptions} onChange={(o) => props.onChangePageSize(o?.value || 10)} defaultValue={realPageSizeOptions[0]}/>
                <div className="inline-flex xs:mt-0">
                    <button onClick={onClickChange.bind(null, false)} className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
                    </button>
                    <button onClick={onClickChange.bind(null, true)} className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 border-0 border-l border-gray-700 rounded-r hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Pagination);