import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

import Season from './components/Season';
import CreateSeasonModal from './modals/CreateSeason.modal';
import EditSeasonModal from './modals/EditSeason.modal';
import CreateEpisodeModal from './modals/CreateEpisode.modal';
import EditEpisodeModal from './modals/EditEpisode.modal';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { EShowItemSubType, EShowItemType, ShowItem } from 'types/ShowItem';

import { AxiosClient } from 'utils/axios';
import { GetIDFromSlugID } from 'utils/string';
import { SetListingSeasonIds, ShowModalCreateSeason, ShowModalEditSeason } from 'store/show.page.slice';
import { DragCard } from 'components/DragCard';

function ShowEditSeasonEpisode() {
    const { slugId } = useParams();
    const id = GetIDFromSlugID(slugId);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const forceReload = useAppSelector((state) => state.pageShow.listing.forceReload);

    const [show, setShow] = useState<ShowItem|null>(null);
    const [seasons, setSeasons] = useState<ShowItem[]>([]);
    
    const [sortingMode, setSortingMode] = useState<boolean>(false);
    const [sortingItems, setSortingItems] = useState<ShowItem[]>([]);

    useEffect(() => { 
        if(!id) { 
            navigate('/content/shows'); 
            return 
        };

        dispatch(SetLoading(true));
        AxiosClient.get(`/show-items/series-movie/${id}`).then((res: AxiosResponse) => {
            let show: ShowItem = res.data;

            setShow(show);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
            navigate('/content/shows'); 
        });


    }, [id]);

    useEffect(() => { 
        if(!show) return 

        dispatch(SetLoading(true));
        AxiosClient.get(`/show-items/series-movie/${id}/seasons`, { params: { paginationLimit: 99999 } }).then((res: AxiosResponse) => {
            let seasons: ShowItem[] = res.data.data;

            setSeasons(seasons);
            dispatch(SetListingSeasonIds(seasons.map((s) => s.id)));
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
            navigate('/content/shows'); 
        });
    }, [show, forceReload]);
    

    const onClickEditSeason = useCallback((season: ShowItem) => {
        dispatch(ShowModalEditSeason({ show: true, season }));
    }, [show]);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setSortingItems((prevItems: ShowItem[]) => 
            update(prevItems, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevItems[dragIndex] as ShowItem],
                ],
            }),
        )
    }, [])

    const onClickSaveSorts = async () => {
        if(!sortingMode || sortingItems.length <= 0) return;

        dispatch(SetLoading(true));

        try {
            await AxiosClient.patch(`/show-items/seasons/sort-orders`, {
                orders: sortingItems.map((i, index) => ({ id: i.id, sortOrder: index + 1}))
            });

            setSeasons(sortingItems);
            setSortingMode(false);

            dispatch(SetLoading(false));
        } catch(e) {
            dispatch(SetLoading(false));
        }
    }

    if(!id || !show || show.type != EShowItemType.Series || show.subType != EShowItemSubType.MultiSeasonSeries) return null;

    const onClickAddSeason = () => {
        dispatch(ShowModalCreateSeason({ show: true, seriesId: show.id }));
    }

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[900px]">
                    <div className='mb-4 flex justify-between items-center'>
                        <div className="flex items-center">
                            <input id="checkbox-sorting-mode" type="checkbox" checked={sortingMode} onChange={(event) => { setSortingItems(seasons); setSortingMode(event.target.checked) }} className="input input-checkbox"/>
                            <label htmlFor="checkbox-sorting-mode" className="ml-2 text-sm font-medium text-gray-900">{ t('Shared.Fields.SortingMode') }</label>
                        </div>
                        { sortingMode && <button onClick={onClickSaveSorts} className='btn btn-aurora btn-sm'><i className="fa-solid fa-floppy-disk mr-1"></i>{ t('Shared.Actions.Save') }</button>}
                    </div>
                    { sortingMode ? 
                        <DndProvider backend={HTML5Backend}>
                            { sortingItems.length > 0 ?
                                sortingItems.map((i: ShowItem, index: number) =>  <DragCard key={i.id} index={index} id={i.id} text={`${i.originalTitle}`} moveCard={moveCard}/>)
                                :
                                <p className='mb-5 text-center'>{ t('Shared.Text.ThereAreNoItems') }</p>
                            }
                        </DndProvider>
                        :
                        <>
                        { seasons.length > 0 ?
                            seasons.map((s: ShowItem) => <Season key={s.id} data={s} onClickEditSeason={onClickEditSeason}/>)
                            :
                            <p className='mb-5 text-center'>{ t('Shared.Text.ThereAreNoItems') }</p>
                        }
                        <div className='text-center'>
                            <button onClick={onClickAddSeason} className='btn btn-aurora'><i className="fa-solid fa-circle-plus mr-2"></i>{ t('Shared.Actions.AddSeason') }</button>
                        </div>
                        </>
                    }
                </div>
            </div>
            <CreateSeasonModal/>
            <EditSeasonModal/>
            <CreateEpisodeModal/>
            <EditEpisodeModal/>
        </div>
    );
}

export default React.memo(ShowEditSeasonEpisode);
