import React from 'react';

import Sidebar from './Sidebar';
import SideSheet from './SideSheet';
import Topbar from './Topbar';

function Layout(props: any) {
    return (
        <>
            <Sidebar/>
            <main className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
                <Topbar/>
                { props.children }
            </main>
            {/* <SideSheet/> */}
        </>
    )
}

export default React.memo(Layout);