import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import Pagination from 'components/Pagination';
import ItemDetailModal from './Modals/ItemDetail.modal';

import { usePagination } from 'hooks/page';
import { useAppDispatch, useAppSelector } from 'store/hooks';import { SetLoading } from 'store/layout.slice';

import { ShowModalItemDetail } from 'store/recycleBin.page.slice';

import { Log } from 'types/Log';
import { AxiosClient } from 'utils/axios';

function RecycleBinListing() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [pageStart, pageLimit, pageNumber, GoToPage, ChangePageSize] = usePagination();
    const forceReload = useAppSelector((state) => state.pageRecycleBin.listing.forceReload);

    const [items, setItems] = useState<Log[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);

    useEffect(() => { }, [forceReload]);

    useEffect(() => {
        dispatch(SetLoading(true));

        let logParams = {
            sort: "createdAt:desc",
            paginationStart: pageStart,
            paginationLimit: pageLimit,
        };
        
        AxiosClient.get('/logs/recycle-bin', { 
            params: logParams
        }).then((res: AxiosResponse) => {
            setItems(res.data.data);
            setTotalItems(res.data.meta.pagination.total);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
        })
    }, [dispatch, forceReload, pageNumber, pageLimit, pageStart]);

    const onViewDetail = (log: Log) => {
        dispatch(SetLoading(true));

        AxiosClient.get(`/logs/${log.id}`).then((res: AxiosResponse) => {
            dispatch(ShowModalItemDetail({ show: true, content: res.data.detail, log }));
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
        })
    }

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6 mx-auto">
            <div className="flex flex-wrap">
                <div className="flex-none w-full max-w-full">
                    <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <div className='mb-3 flex flex-col md:flex-row justify-between items-center p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent'>
                            <h6 className='mb-0 md:mb-0'>{ t('Shared.Fields.RecycleBin') }</h6>
                        </div>
                        <div className="flex-auto px-0 pt-0 pb-2">
                            <div className="p-0 overflow-x-auto">
                                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                                    <thead className="align-bottom">
                                        <tr>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Created') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Module') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Identifier') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.DeletedBy') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Status') }</th>
                                            <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items.length > 0 ? 
                                            items.map((l: Log) => 
                                                <tr key={l.id}>
                                                    <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <span className="font-semibold leading-tight text-xs text-slate-400">{ l.createdAt ? new Date(l.createdAt).toLocaleString() : '-'  }</span>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs">{ l.module }</p>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs max-w-2xl overflow-hidden">{ l.identifier }</p>
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        { l.author ? <h6 className="mb-0 leading-normal text-xs">(#{l.author.id}) { l.author.nickname }</h6> : '-' }
                                                    </td>
                                                    <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <p className="mb-0 font-semibold leading-tight text-xs">{ l.restored ? <span className='badge badge-dark'>{ t('Shared.Fields.Restored') }</span> : <span className='badge badge-green'>{ t('Shared.Fields.Restorable') }</span> }</p>
                                                    </td>
                                                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                        <span onClick={onViewDetail.bind(null, l)} className="font-semibold leading-tight text-slate-400 hover:text-slate-300 hover:cursor-pointer"><i className="fa-solid fa-circle-info"></i></span>
                                                    </td>
                                                </tr>
                                            )
                                        :
                                        <>
                                            <tr>
                                                <td colSpan={7}><p className='text-center mt-5'>{ t('Shared.Text.ThereAreNoItems') }</p></td>
                                            </tr>
                                        </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Pagination page={pageNumber} size={pageLimit} total={totalItems} onGoToPage={GoToPage} onChangePageSize={ChangePageSize}/>
                </div>
            </div>
            <ItemDetailModal/>
        </div>
    );
}

export default React.memo(RecycleBinListing);
