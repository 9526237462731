import { ShowItem } from "./ShowItem";
import { User } from "./User";

export enum ECommentTargetType {
    ShowItem = 'ShowItem'
}

export enum ECommentStatus {
    Hidden = 0,
    Pending = 1,
    Visible = 2
}

export interface Comment {
    id: number;
    targetType: ECommentTargetType;
    targetId: number;
    content: string | null;
    childrenCount: number;
    hiddenAt: Date | null;
    status: ECommentStatus;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
    author?: User | null;
    targetEntity?: ShowItem | null;
}