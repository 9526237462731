import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from 'components/Layout';

import HomePage from './Home/Index';

import LayoutSection from './Layout/Index';
import PageAndSectionPage from './Layout/PageAndSection/Index';
import PageListing from './Layout/PageAndSection/PageListing';
import PageAdd from './Layout/PageAndSection/PageAdd';
import PageEdit from './Layout/PageAndSection/PageEdit';
import PageEditSection from './Layout/PageAndSection/PageEditSection';
import FeaturedPage from './Layout/Featured/Index';
import FeaturedListListing from './Layout/Featured/FeaturedListListing';
import FeaturedListAdd from './Layout/Featured/FeaturedListAdd';
import FeaturedListEdit from './Layout/Featured/FeaturedListEdit';
import FeaturedListEditItem from './Layout/Featured/FeaturedListEditItem';

import ContentSection from './Content/Index';
import PeoplePage from './Content/People/Index';
import PeopleListing from './Content/People/Listing';
import PeopleAdd from './Content/People/Add';
import PeopleEdit from './Content/People/Edit';
import CategoryPage from './Content/Category/Index';
import CategoryListing from './Content/Category/Listing';
import CategoryAdd from './Content/Category/Add';
import CategoryEdit from './Content/Category/Edit';
import ShowPage from './Content/Show/Index';
import ShowListingSeriesMovie from './Content/Show/ListingSeriesMovie';
import ShowAddSeriesMovie from './Content/Show/AddSeriesMovie';
import ShowEditSeriesMovie from './Content/Show/EditSeriesMovie';
import ShowEditSeasonEpisode from './Content/Show/EditSeasonEpisode';
import ShowListTrack from './Content/Show/ListTrack';

import CommunitySection from './Community/Index';
import UserPage from './Community/User/Index';
import UserListing from './Community/User/Listing';
import UserEdit from './Community/User/Edit';
import CommentPage from './Community/Comment/Index';
import CommentListing from './Community/Comment/Listing';

import SystemSection from './System/Index';
import LogPage from './System/Log/Index';
import LogListing from './System/Log/Listing';
import RecycleBinPage from './System/RecycleBin/Index';
import RecycleBinListing from './System/RecycleBin/Listing';

function Container() {
    return (
        <Layout>
            <Routes>
                <Route index element={<Navigate to="/home"/>} />
                <Route path="home" element={<HomePage/>} />
                <Route path="layout" element={<LayoutSection/>}>
                    <Route path="pages" element={<PageAndSectionPage/>}>
                        <Route index element={<PageListing/>}/>
                        <Route path="add" element={<PageAdd/>}/>
                        <Route path=":slugId" element={<PageEdit/>}/>
                        <Route path="edit-sections/:slugId" element={<PageEditSection/>}/>
                    </Route>
                    <Route path="featured" element={<FeaturedPage/>}>
                        <Route index element={<FeaturedListListing/>}/>
                        <Route path="add" element={<FeaturedListAdd/>}/>
                        <Route path=":id" element={<FeaturedListEdit/>}/>
                        <Route path="edit-items/:id" element={<FeaturedListEditItem/>}/>
                    </Route>
                </Route>
                <Route path="content" element={<ContentSection/>}>
                    <Route path="people" element={<PeoplePage/>}>
                        <Route index element={<PeopleListing/>}/>
                        <Route path=":slugId" element={<PeopleEdit/>}/>
                        <Route path="add" element={<PeopleAdd/>}/>
                    </Route>
                    <Route path="categories" element={<CategoryPage/>}>
                        <Route index element={<CategoryListing/>}/>
                        <Route path=":slugId" element={<CategoryEdit/>}/>
                        <Route path="add" element={<CategoryAdd/>}/>
                    </Route>
                    <Route path="shows" element={<ShowPage/>}>
                        <Route index element={<ShowListingSeriesMovie/>}/>
                        <Route path="series-movie/:slugId" element={<ShowEditSeriesMovie/>}/>
                        <Route path="series-movie/add" element={<ShowAddSeriesMovie/>}/>
                        <Route path="season-episode/:slugId" element={<ShowEditSeasonEpisode/>}/>
                        <Route path="tracks/:id" element={<ShowListTrack/>}/>
                    </Route>
                </Route>
                <Route path="community" element={<CommunitySection/>}>
                    <Route path="users" element={<UserPage/>}>
                        <Route index element={<UserListing/>}/>
                        <Route path=":slugId" element={<UserEdit/>}/>
                    </Route>
                    <Route path="comments" element={<CommentPage/>}>
                        <Route index element={<CommentListing/>}/>
                    </Route>
                </Route>
                <Route path="system" element={<SystemSection/>}>
                    <Route path="logs" element={<LogPage/>}>
                        <Route index element={<LogListing/>}/>
                    </Route>
                    <Route path="recycle-bin" element={<RecycleBinPage/>}>
                        <Route index element={<RecycleBinListing/>}/>
                    </Route>
                </Route>
            </Routes>
        </Layout>
    );
}

export default React.memo(Container);
