import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import { AxiosError, AxiosResponse } from 'axios';
import ReactDatePicker from 'react-datepicker';

import ContentLanguageSelector from 'components/ContentLanguageSelector';

import { EPageType, Page } from 'types/Page';
import { LanguageMeta } from 'types/Shared';

import { useAppDispatch } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { GetIDFromSlugID } from 'utils/string';
import { AxiosClient, GetAxiosError } from 'utils/axios';

function PageEdit() {
    const { slugId } = useParams();
    const id = GetIDFromSlugID(slugId);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, control, watch, setValue, getValues } = useForm();
    const hidden = watch('hidden', false);
    
    const [page, setPage] = useState<Page|null>(null);
    const [statusText, setStatusText] = useState<any>('');
    const [langMeta, setLangMeta] = useState<LanguageMeta>({ editing: [], reset: 0 });
   
    useEffect(() => { 
        if(!id) { 
            navigate('/layout/pages'); 
            return 
        };

        dispatch(SetLoading(true));
        AxiosClient.get(`/pages/${id}`).then((res: AxiosResponse) => {
            let page: Page = res.data;
            
            setPage(page);

            setValue('type', page.type);
            setValue('title', page.title);
            setValue('description', page.description);
            setValue('slug', page.slug);
            setValue('sortOrder', page.sortOrder);
            setValue('hidden', !page.hidden ? 0 : (page.hiddenUntil ? 2 : 1));
            if(page.hidden && page.hiddenUntil) setValue('hiddenUntil', new Date(page.hiddenUntil));
            if(page.langMeta) setLangMeta({ ...page.langMeta, reset: -1, editing: [] })
          
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            dispatch(SetLoading(false));
            navigate('/layout/pages'); 
        });
    }, [id]);

    if(!id || !page) return null;

    const onSubmit = async (data: any) => {
        if(langMeta.editing.length > 0) {
            setLangMeta({ ...langMeta, reset: langMeta.reset + 1 });
            setTimeout(() => { (document.querySelector('#btn-update') as HTMLButtonElement).click(); }, 1);
            return;
        }

        dispatch(SetLoading(true));

        setStatusText(`${t('Shared.Text.Updating')}...`);
        try {
            var realHidden = false;
            var hiddenUntil = null;

            switch(hidden) {
                case 0: realHidden = false; break;
                case 1: realHidden = true; hiddenUntil = null; break;
                case 2: {
                    realHidden = true; 
                    hiddenUntil = data.hiddenUntil;

                    if(!hiddenUntil) {
                        setStatusText(<span className='text-red-600'>{ t('Shared.Errors.InvalidHiddenUntil') }</span>);
                        dispatch(SetLoading(false));
                        return;   
                    }

                    break;
                }
                default: break;
            }

            await AxiosClient.patch(`/pages/${page.id}`, {
                title: data.title,
                slug: data.slug,
                description: data.description,
                sortOrder: Number(data.sortOrder || 0),
                hidden: realHidden,
                hiddenUntil,
                langMeta
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        navigate('/layout/pages');
    }

    const onClickDelete = async () => {
        let result = window.confirm(t('Shared.Text.DeleteConfirm') || '');
        if(result !== true) return;

        dispatch(SetLoading(true));
        setStatusText(`${t('Shared.Text.Deleting')}...`);

        try {
            await AxiosClient.delete(`/pages/${page.id}`);
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        navigate('/layout/pages');
    }

    const pageTypeOptions: any[] = []; for(let item in EPageType) pageTypeOptions.push({ value: item, label: t(`Shared.PageTypes.${item}`) });

    const hiddenOptions = [
        { value: 0, label: t('Shared.Fields.Visible') },
        { value: 1, label: t('Shared.Fields.Hidden')  },
        { value: 2, label: t('Shared.Fields.HiddenUntil')  },
    ]
    
    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[720px]">
                    <div className="p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <h5 className='mb-5'>{ t('Shared.Fields.PageInformation') }</h5>
                        <form role="form" onSubmit={handleSubmit(onSubmit)}>
                            <div className='grid grid-cols-2 gap-3 mb-3'>
                                <div className='col-span-1'>
                                    <div className='mb-2 flex justify-content-start items-center'>
                                        <label className="ml-1 font-bold text-xs text-slate-700 mr-3">{ t('Shared.Fields.Title') } <span className='text-red-600'>*</span></label>
                                        <ContentLanguageSelector defaultLang={'eng'} field='title' langMeta={langMeta} setLangMeta={setLangMeta} setValue={(value: string) => { setValue('title', value) }} getValue={getValues}/>
                                    </div>
                                    <div>
                                        <input type="text" className="input input-textbox" placeholder="Title" { ...register("title", { required: true, minLength: 1, maxLength: 64 }) }/>
                                    </div>
                                </div>
                                <div className='col-span-1'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Slug') } <span className='text-red-600'>*{ t('Shared.Fields.Unique') }</span></label>
                                    <div>
                                        <input type="text" className="input input-textbox" placeholder="Slug" { ...register("slug", { required: true, minLength: 1, maxLength: 64 }) }/>
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-3 mb-3'>
                                <div className='col-span-1'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Type') }</label>
                                    <div>
                                        <Controller
                                            name="type"
                                            control={control}
                                            render={({ field }) => (
                                                <Select isClearable={false} className='input input-select2' isDisabled={true} options={pageTypeOptions} value={pageTypeOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }} defaultValue={pageTypeOptions[0]}/>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='col-span-1'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.SortOrder') }</label>
                                    <div>
                                        <input type="number" className="input input-textbox" placeholder="Sort Order" { ...register("sortOrder", { min: 0 }) } defaultValue={0}/>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-3'>
                                <div className='mb-2 flex justify-content-start items-center'>
                                    <label className="ml-1 font-bold text-xs text-slate-700 mr-3">{ t('Shared.Fields.Description') }</label>
                                    <ContentLanguageSelector defaultLang={'eng'} field='description' langMeta={langMeta} setLangMeta={setLangMeta} setValue={(value: string) => { setValue('description', value) }} getValue={getValues}/>
                                </div>
                                <div>
                                    <textarea className="input input-textbox" placeholder="Description" { ...register("description", { maxLength: 1024 }) }/>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-3 mb-10'>
                                <div className='col-span-2 md:col-span-1'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Visibility') } <span className='text-red-600'>*</span></label>
                                    <div>
                                        <Controller
                                            name="hidden"
                                            defaultValue={hiddenOptions[0].value}
                                            control={control}
                                            render={({ field }) => (
                                                <Select styles={{ menu: (base) => ({ ...base, top: -50 }) }} isClearable={false} className='input input-select2' options={hiddenOptions} value={hiddenOptions.find(o => o.value == field.value)} onChange={(o) => { field.onChange(o?.value); }}/>
                                            )}
                                        />
                                    </div>
                                </div>
                                { hidden == 2 && <div className='col-span-2 md:col-span-1'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.HiddenUntil') } <span className='text-red-600'>*</span></label>
                                    <div>
                                    <Controller
                                        name="hiddenUntil"
                                        control={control}
                                        render={({ field }) => (
                                            <ReactDatePicker
                                                popperPlacement='auto'
                                                className='input input-textbox'
                                                placeholderText='Select date'
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                            />
                                        )}
                                    />
                                    </div>
                                </div> }
                            </div>
                            <div className='flex flex-col md:flex-row justify-between items-center'>
                                <p className='mb-0'>{ statusText }</p>
                                <div>
                                    <button type="button" onClick={onClickDelete} className="btn btn-red btn-keep-size block mr-2"><i className="fa-solid fa-trash mr-2"></i>{ t('Shared.Actions.Delete') }</button>
                                    <button type="submit" id="btn-update" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-pen-nib mr-2"></i>{ t('Shared.Actions.Update') }</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(PageEdit);
