
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ShowItem, ShowItemTrack } from "types/ShowItem";

export interface IShowModalCreateSeasonPayload {
  show: boolean;
  seriesId?: number;
}

export interface IShowModalEditSeasonPayload {
  show: boolean;
  season?: ShowItem;
}

export interface IShowModalCreateEpisodePayload {
  show: boolean;
  seasonId?: number;
}

export interface IShowModalEditEpisodePayload {
  show: boolean;
  episode?: ShowItem;
  seasonId?: number;
}

export interface IShowModalCreateTrackPayload {
  show: boolean;
  showItemId?: number;
}

export interface IShowModalEditTrackPayload {
  show: boolean;
  track?: ShowItemTrack;
}

export interface ShowPageState {
  listing: {
    forceReload: number;
    forceReloadSeasons: { [key: number]: number };
    forceReloadTracks: number;
  },
  modal: {
    createSeason: {
      show: boolean;
      seriesId: number;
    },
    editSeason: {
      show: boolean;
      season: ShowItem | null;
    },
    createEpisode: {
      show: boolean;
      seasonId: number;
    },
    editEpisode: {
      show: boolean;
      episode: ShowItem | null;
      seasonId: number;
    },
    createTrack: {
      show: boolean;
      showItemId: number;
    },
    editTrack: {
      show: boolean;
      track: ShowItemTrack | null;
    }
  }
}

const initialState: ShowPageState = {
  listing: {
    forceReload: 0,
    forceReloadSeasons: {},
    forceReloadTracks: 0
  },
  modal: {
    createSeason: {
      show: false,
      seriesId: 0
    },
    editSeason: {
      show: false,
      season: null
    },
    createEpisode: {
      show: false,
      seasonId: 0
    },
    editEpisode: {
      show: false,
      episode: null,
      seasonId: 0
    },
    createTrack: {
      show: false,
      showItemId: 0
    },
    editTrack: {
      show: false,
      track: null
    }
  }
};

export const ShowPageSlice = createSlice({
  name: "pageShow",
  initialState,
  reducers: {
    SetListingSeasonIds(state, action: PayloadAction<number[]>) {
      action.payload.forEach((id: number) => {
        if(!state.listing.forceReloadSeasons.hasOwnProperty(id))
          state.listing.forceReloadSeasons[id] = 0;
      });
    },
    ListingForceReload(state) {
      state.listing.forceReload++;
    },
    ListingForceReloadSeasons(state, action: PayloadAction<number>) {
      if(state.listing.forceReloadSeasons.hasOwnProperty(action.payload)) state.listing.forceReloadSeasons[action.payload]++;
      else state.listing.forceReloadSeasons[action.payload] = 0;
    },
    ListingForceReloadTracks(state) {
      state.listing.forceReloadTracks++;
    },
    ShowModalCreateSeason(state, action: PayloadAction<IShowModalCreateSeasonPayload>) {
      state.modal.createSeason.show = action.payload.show;
      state.modal.createSeason.seriesId = action.payload.seriesId || 0;
    },
    ShowModalEditSeason(state, action: PayloadAction<IShowModalEditSeasonPayload>) {
      state.modal.editSeason.show = action.payload.show;
      state.modal.editSeason.season = action.payload.season || null;
    },
    ShowModalCreateEpisode(state, action: PayloadAction<IShowModalCreateEpisodePayload>) {
      state.modal.createEpisode.show = action.payload.show;
      state.modal.createEpisode.seasonId = action.payload.seasonId || 0;
    },
    ShowModalEditEpisode(state, action: PayloadAction<IShowModalEditEpisodePayload>) {
      state.modal.editEpisode.show = action.payload.show;
      state.modal.editEpisode.episode = action.payload.episode || null;
      state.modal.editEpisode.seasonId = action.payload.seasonId || 0;
    },
    ShowModalCreateTrack(state, action: PayloadAction<IShowModalCreateTrackPayload>) {
      state.modal.createTrack.show = action.payload.show;
      state.modal.createTrack.showItemId = action.payload.showItemId || 0;
    },
    ShowModalEditTrack(state, action: PayloadAction<IShowModalEditTrackPayload>) {
      state.modal.editTrack.show = action.payload.show;
      state.modal.editTrack.track = action.payload.track || null;
    },
  }
});

export const { 
  SetListingSeasonIds, ListingForceReload, ListingForceReloadSeasons, ListingForceReloadTracks,
  ShowModalCreateSeason, ShowModalEditSeason, ShowModalCreateEpisode, ShowModalEditEpisode,
  ShowModalCreateTrack, ShowModalEditTrack
} = ShowPageSlice.actions;

export default ShowPageSlice.reducer;