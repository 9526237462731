import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { AxiosError, AxiosResponse } from 'axios';
import debounce from 'lodash.debounce';
import axios from 'axios';

import { AxiosClient, GetAxiosError } from 'utils/axios';
import { GenerateLabelCode } from 'utils/string';

import { useAppDispatch } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { PersonRole } from 'types/Person';

function PeopleAdd() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { register, handleSubmit, control, setValue, getValues } = useForm();
    const refFilePhoto = useRef<HTMLInputElement>(null);
    const refRole = useRef<any>(null);

    const [roleKey, setRoleKey] = useState('');
    const [statusText, setStatusText] = useState<any>('');
    const [photo, setPhoto] = useState<string|ArrayBuffer|null>(null);
    const [uploadedPhotoUID, setUploadedPhotoUID] = useState<string|null>(null);
    const [fetchedRole, setFetchedRole] = useState<string>('');

    useEffect(() => {
        setPhoto(null);
        setUploadedPhotoUID(null);
    }, []);

    const onLoadRoles = debounce((inputValue: string, callback: (options: any[]) => void) => {
        AxiosClient.get('/people/roles', { params: inputValue.length > 0 ? { q: inputValue, sort: 'id:desc' } : { sort: 'id:asc' } }).then((res: AxiosResponse) => {
            let options = res.data.data?.map((r: PersonRole) => ({ label: r.title, value: r.roleCode }));

            if(fetchedRole.length > 0 && !options.find((r: any) => r.value == fetchedRole))
                options.unshift({ label: fetchedRole, value: fetchedRole });
            
            callback(options);
        }).catch((err: AxiosError) => {
            setStatusText(<span className='text-red-600'>{GetAxiosError(err)}</span>);
            callback([]);
        });
    }, 500);

    const onCreateRole = async (label: string) => {
        dispatch(SetLoading(true));
        try {
            let roleCode = GenerateLabelCode(label);
            await AxiosClient.post('/people/roles', { title: label, roleCode });
            setRoleKey(JSON.stringify({ label, roleCode }));
            
            dispatch(SetLoading(false));
            return { label, value: roleCode };
        } catch(err: any) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(err)}</span>);
            dispatch(SetLoading(false));
        }
    }

    const onClickSelectPhoto = (e: any) => { refFilePhoto.current?.click(); }
    const onFilePhotoChange = (e: any) => {
        let reader = new FileReader();

        reader.onload = function() { setPhoto(reader.result); };
        reader.readAsDataURL(e.target.files[0]);
    }

    const onSubmit = async (data: any) => {
        let selectedRole = refRole.current?.getValue();
        if(!photo || !refFilePhoto.current || !selectedRole || selectedRole.length <= 0) return;

        dispatch(SetLoading(true));

        var photoUID = uploadedPhotoUID;
        if(!uploadedPhotoUID) {
            let formData = new FormData();
            // @ts-ignore
            formData.append('file', refFilePhoto.current?.files[0]);

            try {
                setStatusText(`${t('Shared.Text.Uploading')}...`);
                let uploadedImage = await AxiosClient.post('/files/private-upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    params: { type: 'Image' }
                });

                setUploadedPhotoUID(uploadedImage.data.uid);
                photoUID = uploadedImage.data.uid;
            } catch(e) {
                setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
                dispatch(SetLoading(false));
                return;
            }
        }

        setStatusText(`${t('Shared.Text.Creating')}...`);
        try {
            await AxiosClient.post('/people', {
                name: data.name,
                biography: data.biography,
                birthName: data.birthName,
                birthDate: data.birthDate,
                birthPlace: data.birthPlace,
                deathDate: data.deathDate,
                mainRoleCode: selectedRole[0].value,
                imdbUrl: data.imdbUrl,
                thumbnailFileId: photoUID
            });
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        dispatch(SetLoading(false));
        navigate('/content/people');
    }

    const onClickImport = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        
        var url = getValues('imdbUrl')
        if(!url) return;

        dispatch(SetLoading(true));
        setStatusText(`${t('Shared.Text.Fetching')}...`);

        try {
            var { data } = await AxiosClient.get('/people/fetch-imdb', { params: { url } });

            setValue('name', data.name);
            setValue('biography', data.biography);
            setValue('birthPlace', data.birthPlace);
            if(data.birthDate) setValue('birthDate', new Date(data.birthDate));
            if(data.deathDate) setValue('deathDate', new Date(data.deathDate));
            if(data.thumbnailUrl) {
                axios.get(data.thumbnailUrl, { responseType: 'blob' }).then(response => {
                    let blob = response.data;
                    let reader = new FileReader();

                    reader.onload = function(e) { 
                        setPhoto(reader.result); 
                        setUploadedPhotoUID(null);
                    };
                    reader.readAsDataURL(blob);

                    let file = new File([blob], "image.jpg",{ type:"image/jpeg", lastModified:new Date().getTime()});
                    let container = new DataTransfer();
                    container.items.add(file);

                    if(refFilePhoto.current) refFilePhoto.current.files = container.files;

                }).catch(() => {})
            }

            if(data.roles.length > 0) {
                setRoleKey(JSON.stringify({ label: data.roles[0], value: data.roles[0] }));
                setFetchedRole(data.roles[0]);
            }
        } catch(e) {
            setStatusText(<span className='text-red-600'>{GetAxiosError(e)}</span>);
            dispatch(SetLoading(false));
            return;
        }

        setStatusText('');
        dispatch(SetLoading(false));

    }

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[1024px] grid grid-cols-3 gap-4">
                    <div className='col-span-3 md:col-span-1'>
                        <div onClick={onClickSelectPhoto} className={`image-select image-select-portrait ${photo ? '' : 'image-select-none'} shadow-lg hover:cursor-pointer`}>
                            { !photo && <div className='text-center'>
                                <p className='text-5xl mb-2'><i className="fa-solid fa-image-portrait"></i></p>
                                <p>{ t('Shared.Fields.PortraitPhoto')} <span className='text-red-600'>*</span></p>
                            </div> }
                            { photo && <img src={photo.toString()}/> }
                        </div>
                        <input ref={refFilePhoto} onChange={onFilePhotoChange} type="file" accept='image/png, image/jpeg' className="hidden"/>
                    </div>
                    <div className='col-span-3 md:col-span-2'>
                        <div className="mb-4 p-6 bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                            <h5 className='mb-5'>{ t('Shared.Fields.PersonInformation') }</h5>
                            <form role="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className='grid grid-cols-2 gap-3 mb-3'>
                                    <div className='col-span-2 md:col-span-1'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Name') } <span className='text-red-600'>*</span></label>
                                        <div>
                                            <input type="text" className="input input-textbox" placeholder="Name" { ...register("name", { required: true, minLength: 1, maxLength: 128 }) }/>
                                        </div>
                                    </div>
                                    <div className='col-span-2 md:col-span-1'>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Role') } <span className='text-red-600'>*</span></label>
                                        <div>
                                            <AsyncCreatableSelect ref={refRole} key={roleKey} className='input input-select2' cacheOptions loadOptions={onLoadRoles} defaultOptions onCreateOption={onCreateRole} defaultValue={roleKey ? JSON.parse(roleKey) : null}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Biography') }</label>
                                    <div>
                                        <textarea className="input input-textbox" placeholder="Biography" { ...register("biography", { maxLength: 8192 }) }/>
                                    </div>
                                </div>
                                <div className='grid grid-cols-2 gap-3 mb-3'>
                                    <div>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.BirthName') }</label>
                                        <div>
                                            <input type="text" className="input input-textbox" placeholder="Birth Name" { ...register("birthName", {  maxLength: 255 }) }/>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.BirthPlace') }</label>
                                        <div>
                                            <input type="text" className="input input-textbox" placeholder="Birth Place" { ...register("birthPlace", { maxLength: 255 }) }/>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-2 gap-3 mb-3'>
                                    <div>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.BirthDate') }</label>
                                        <div>
                                        <Controller
                                            name="birthDate"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    className='input input-textbox'
                                                    placeholderText='Select date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                />
                                            )}
                                        />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.DeathDate') }</label>
                                        <div>
                                        <Controller
                                            name="deathDate"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    className='input input-textbox'
                                                    placeholderText='Select date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                />
                                            )}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-5'>
                                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.IMDBUrl') }</label>
                                    <div className='grid grid-cols-12 gap-3'>
                                        <input type="text" className="input input-textbox col-span-8 md:col-span-9" placeholder="IMDB Url" { ...register("imdbUrl", { maxLength: 255 }) }/>
                                        <button onClick={onClickImport} className="btn btn-aurora block col-span-4 md:col-span-3"><i className="fa-solid fa-file-import mr-2"></i>{ t('Shared.Actions.Import') }</button>
                                    </div>
                                </div>
                                <div className='flex flex-col md:flex-row justify-between items-center'>
                                    <p className='mb-0'>{ statusText }</p>
                                    <button type="submit" className="btn btn-aurora btn-keep-size block"><i className="fa-solid fa-circle-plus mr-2"></i>{ t('Shared.Actions.Create') }</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(PeopleAdd);
